import { useFormikContext } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "../../../styles/button";
import { errorToast } from "../../../utils/toasts";

interface ISubmitFormProps {
  disabled?: boolean;
  text?: string;
  className?: string;
}

const SubmitForm: FC<ISubmitFormProps> = ({ disabled, text, className }) => {
  const { isSubmitting, validateForm, submitForm } = useFormikContext();
  const { t } = useTranslation();

  const handleButtonClicked = async () => {
    const errors = await validateForm();
    if (Object.values(errors).length !== 0) {
      errorToast(t("errors.formInvalidValues"));
    }
    submitForm();
  };

  return (
    <FormButton
      className={className}
      type="button"
      disabled={disabled || isSubmitting}
      onClick={handleButtonClicked}
    >
      {t(text ?? "common.save")}
    </FormButton>
  );
};
export default SubmitForm;
