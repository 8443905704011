import { addDays, format, isBefore } from "date-fns";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import clientApi from "../../api/client";
import { IActionItem } from "../../models/calendar";
import { Button } from "../../styles/button";
import { DATE_TIME_FORMAT, TIME_FORMAT } from "../../utils/consts";
import { errorToast } from "../../utils/toasts";
import CustomModal from "../common/modal/CustomModal";
import { ModalYesNoFunction } from "../common/modal/ModalFunctions";

export interface IProps {
  isOpen: boolean;
  data: IActionItem | null;
  close(reload: boolean): void;
}

const CalendarDetailModal: FC<IProps> = ({ isOpen, data, close }) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const canDelete = data && isBefore(new Date(), addDays(data.from, -1));

  const handleDelete = async () => {
    const res = await ModalYesNoFunction(
      t("calendar.detailModal.deleteTitle"),
      t("calendar.detailModal.deleteText")
    );
    if (!res) {
      return;
    }

    setIsDeleting(true);
    try {
      await clientApi.deleteClientAction(data!.id);
      close(true);
    } catch (err) {
      errorToast();
    }
    setIsDeleting(false);
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <CustomModal
      title={t("calendar.detailModal.title")}
      isOpen={isOpen}
      close={handleClose}
      actions={
        <>
          <Button ver="secondary" disabled={isDeleting} onClick={handleClose}>
            {t("common.close")}
          </Button>
          <Button disabled={!canDelete || isDeleting} onClick={handleDelete}>
            {t("calendar.detailModal.deleteButton")}
          </Button>
        </>
      }
    >
      {data && (
        <>
          <h2>
            {format(data.from, DATE_TIME_FORMAT)} -{" "}
            {format(data.to, TIME_FORMAT)}
          </h2>
          <p>
            {t("calendar.detailModal.type")}: {data.type.name}
          </p>
          <p>
            {t("calendar.detailModal.description")}: {data.description}
          </p>
          <p>
            {t("calendar.detailModal.userAssignee")}:{" "}
            {data.userAssignee?.name ?? t("calendar.detailModal.unassigned")}
          </p>
        </>
      )}
    </CustomModal>
  );
};

export default CalendarDetailModal;
