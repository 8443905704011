import { createAction } from "@reduxjs/toolkit";
import { AppThunkActionAsync } from ".";

export const STORAGE_RESET = "STORAGE_RESET";

enum StorageResetTypes {
  STORAGE_RESET_TYPE = "STORAGE_RESET",
}

const storageResetAction = createAction<
  void,
  StorageResetTypes.STORAGE_RESET_TYPE
>(StorageResetTypes.STORAGE_RESET_TYPE);

export function storageReset(): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(storageResetAction());
  };
}
