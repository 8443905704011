import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const SmallPage = styled.main`
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarPageContainer = styled.main`
  display: grid;

  grid-template-columns: 1fr;

  @media screen and (min-width: ${SIZES.laptop}) {
    grid-template-columns: auto 1fr;
  }
`;

export const PageContentWrapper = styled.div`
  width: 100%;
  max-width: ${SIZES.desktop};

  margin: 0 auto;
  padding: 1.5rem;

  @media screen and (min-width: ${SIZES.tablet}) {
    padding: 2rem;
  }
`;
