import { createAction } from "@reduxjs/toolkit";
import { IClientContactPerson } from "../../models/client";
import { ClientContactPersonTypes } from "./types";

export const clientContactPersonFetchRequest = createAction<
  void,
  ClientContactPersonTypes.CLIENT_CONTACT_PERSON_FETCH_REQUEST
>(ClientContactPersonTypes.CLIENT_CONTACT_PERSON_FETCH_REQUEST);

export const clientContactPersonFetchSuccess = createAction<
  IClientContactPerson,
  ClientContactPersonTypes.CLIENT_CONTACT_PERSON_FETCH_SUCCESS
>(ClientContactPersonTypes.CLIENT_CONTACT_PERSON_FETCH_SUCCESS);

export const clientContactPersonFetchError = createAction<
  void,
  ClientContactPersonTypes.CLIENT_CONTACT_PERSON_FETCH_ERROR
>(ClientContactPersonTypes.CLIENT_CONTACT_PERSON_FETCH_ERROR);
