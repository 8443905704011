import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IAssistanceAddressFilters } from "../../models/assistance";
import { StoreState } from "../storeState";

export const selectAssistanceAddressFiltersState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.assistanceAddressFilters.state;

export const selectAssistanceAddressFilters: ReselectModule.Selector<
  IApplicationState,
  IAssistanceAddressFilters | null
> = (state) => state.assistanceAddressFilters.data;
