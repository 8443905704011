import { createAction } from "@reduxjs/toolkit";
import { IClientDocumentItem } from "../../models/client";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientDocumentListTypes, IClientDocumentListFilterType } from "./types";

export const clientDocumentListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_FETCH_REQUEST
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_FETCH_REQUEST);

export const clientDocumentListFetchSuccess = createAction<
  {
    data: IClientDocumentItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_FETCH_SUCCESS
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_FETCH_SUCCESS);

export const clientDocumentListFetchError = createAction<
  boolean,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_FETCH_ERROR
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_FETCH_ERROR);

export const clientDocumentListCountRequest = createAction<
  void,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_COUNT_REQUEST
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_COUNT_REQUEST);

export const clientDocumentListCountSuccess = createAction<
  number,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_COUNT_SUCCESS
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_COUNT_SUCCESS);

export const clientDocumentListCountError = createAction<
  boolean,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_COUNT_ERROR
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_COUNT_ERROR);

export const clientDocumentListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_CHANGE_ORDER
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_CHANGE_ORDER);

export const clientDocumentListChangePage = createAction<
  number,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_CHANGE_PAGE
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_CHANGE_PAGE);

export const clientDocumentListChangeFilter = createAction<
IClientDocumentListFilterType,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_CHANGE_FILTER
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_CHANGE_FILTER);

export const clientDocumentListReset = createAction<
  void,
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_RESET
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_RESET);

export const clientDocumentListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_LOAD
>(ClientDocumentListTypes.CLIENT_DOCUMENT_LIST_LOAD);
