import { IDemandItem } from "../../models/demand";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientDemandListTypes {
  CLIENT_DEMAND_LIST_FETCH_REQUEST = "@@clientDemandList/FETCH_REQUEST",
  CLIENT_DEMAND_LIST_FETCH_SUCCESS = "@@clientDemandList/FETCH_SUCCESS",
  CLIENT_DEMAND_LIST_FETCH_ERROR = "@@clientDemandList/FETCH_ERROR",
  CLIENT_DEMAND_LIST_COUNT_REQUEST = "@@clientDemandList/COUNT_REQUEST",
  CLIENT_DEMAND_LIST_COUNT_SUCCESS = "@@clientDemandList/COUNT_SUCCESS",
  CLIENT_DEMAND_LIST_COUNT_ERROR = "@@clientDemandList/COUNT_ERROR",
  CLIENT_DEMAND_LIST_CHANGE_ORDER = "@@clientDemandList/CHANGE_ORDER",
  CLIENT_DEMAND_LIST_CHANGE_PAGE = "@@clientDemandList/CHANGE_PAGE",
  CLIENT_DEMAND_LIST_CHANGE_FILTER = "@@clientDemandList/CHANGE_FILTER",
  CLIENT_DEMAND_LIST_RESET = "@@clientDemandList/RESET",
  CLIENT_DEMAND_LIST_LOAD = "@@clientDemandList/LOAD",
}

export interface IClientDemandListState
  extends IListType<IDemandItem, IFilterType> {}
