import { IFolderItem } from "../../models/document";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientFolderListTypes {
  CLIENT_FOLDER_LIST_FETCH_REQUEST = "@@clientFolderList/FETCH_REQUEST",
  CLIENT_FOLDER_LIST_FETCH_SUCCESS = "@@clientFolderList/FETCH_SUCCESS",
  CLIENT_FOLDER_LIST_FETCH_ERROR = "@@clientFolderList/FETCH_ERROR",
  CLIENT_FOLDER_LIST_COUNT_REQUEST = "@@clientFolderList/COUNT_REQUEST",
  CLIENT_FOLDER_LIST_COUNT_SUCCESS = "@@clientFolderList/COUNT_SUCCESS",
  CLIENT_FOLDER_LIST_COUNT_ERROR = "@@clientFolderList/COUNT_ERROR",
  CLIENT_FOLDER_LIST_CHANGE_ORDER = "@@clientFolderList/CHANGE_ORDER",
  CLIENT_FOLDER_LIST_CHANGE_PAGE = "@@clientFolderList/CHANGE_PAGE",
  CLIENT_FOLDER_LIST_CHANGE_FILTER = "@@clientFolderList/CHANGE_FILTER",
  CLIENT_FOLDER_LIST_RESET = "@@clientFolderList/RESET",
  CLIENT_FOLDER_LIST_LOAD = "@@clientFolderList/LOAD",
}

export interface IClientFolderListFilter extends IFilterType {
  idFolder?: number | null;
}

export interface IClientFolderListState
  extends IListType<IFolderItem, IClientFolderListFilter> {}
