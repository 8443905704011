import { selectPasswordStrengthState } from ".";
import { AppThunkActionAsync } from "..";
import { StoreState } from "../storeState";
import * as actions from "./actions";
import basicApi from "../../api/basic";

export function getPasswordStrength(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectPasswordStrengthState(getState());
    if (state !== StoreState.None) {
      return;
    }

    dispatch(actions.passwordStrengthFetchRequest());
    try {
      const passwordStrength = await basicApi.getPasswordStrength();
      dispatch(actions.passwordStrengthFetchSuccess(passwordStrength.data));
    } catch {
      dispatch(actions.passwordStrengthFetchError());
    }
  };
}
