import { IAssistanceAddressFilters } from "../../models/assistance";
import { StoreState } from "../storeState";

export enum AssistanceAddressFiltersTypes {
  ASSISTANCE_ADDRESS_FILTERS_FETCH_REQUEST = "@@assistanceAddressFilters/FETCH_REQUEST",
  ASSISTANCE_ADDRESS_FILTERS_FETCH_SUCCESS = "@@assistanceAddressFilters/FETCH_SUCCESS",
  ASSISTANCE_ADDRESS_FILTERS_FETCH_ERROR = "@@assistanceAddressFilters/FETCH_ERROR",
}

export interface IAssistanceAddressFiltersState {
  state: StoreState;
  data: IAssistanceAddressFilters | null;
}
