import { FC } from "react";
import { useTranslation } from "react-i18next";
import ModalTwoButtons from "./ModalTwoButtons";

interface IProps {
  title: string;
  text: string;
  isOpen: boolean;
  isFunction?: boolean;
  close(): void;
  confirm: () => Promise<void>;
}

const ModalYesNo: FC<IProps> = ({
  title,
  text,
  isOpen,
  isFunction,
  close,
  confirm,
}) => {
  const { t } = useTranslation();

  return (
    <ModalTwoButtons
      title={title}
      text={text}
      closeText={t("common.no")}
      confirmText={t("common.yes")}
      isOpen={isOpen}
      isFunction={isFunction}
      close={close}
      confirm={confirm}
    />
  );
};

export default ModalYesNo;
