import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IActionItem } from "../models/calendar";
import {
  IClient,
  IClientCare,
  IClientCareItem,
  IClientContactPerson,
  IClientDocumentItem,
  IClientHouse,
} from "../models/client";
import { IItemIdName } from "../models/common";
import { IFolderItem } from "../models/document";
import api from "../utils/api";

const getClient = async (): Promise<AxiosResponse<IClient>> =>
  api.get(`/api/Client/Me`);

const updateClient = async (client: IClient): Promise<AxiosResponse<IClient>> =>
  api.put(`/api/Client/Me`, client);

const getClientHouse = async (): Promise<AxiosResponse<IClientHouse>> =>
  api.get(`/api/Client/Me/HousingSituation`);

const getClientCareList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientCareItem[]>> =>
  api.get(`/api/Client/Me/Cares`, config);

const getClientCareCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/Me/Cares/Count`, config);

const getClientCare = async (
  clientCareId: number
): Promise<AxiosResponse<IClientCare>> =>
  api.get(`/api/Client/Me/Care/${clientCareId}`);

const getClientFolderList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IFolderItem[]>> =>
  api.get(`/api/Clients/Me/Documents/Folders`, config);

const getClientFolderCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Clients/Me/Documents/Folders/Count`, config);

const getClientFolder = async (
  folderId: string | undefined
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Clients/Me/Documents/Folder/${folderId ?? "0"}/Path`);

const getClientDocumentList = async (
  folderId: string | undefined,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientDocumentItem[]>> =>
  api.get(
    `/api/Clients/Me/Documents/Folder/${folderId ?? "0"}/Documents`,
    config
  );

const getClientDocumentCount = async (
  folderId: string | undefined,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(
    `/api/Clients/Me/Documents/Folder/${folderId ?? "0"}/Documents/Count`,
    config
  );

const getClientDocumentDownloadUrl = async (
  documentId: number
): Promise<AxiosResponse<string>> => {
  return api.get(`/api/Clients/Me/Document/${documentId}/Download`);
};

const getClientContactPersonList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientContactPerson[]>> =>
  api.get(`/api/Client/Me/ContactPerson`, config);

const getClientContactPersonCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/Me/ContactPerson/Count`, config);

const getClientContactPerson = async (
  clientContactPersonId: number
): Promise<AxiosResponse<IClientContactPerson>> =>
  api.get(`/api/Client/Me/ContactPerson/${clientContactPersonId}`);

const updateClientContactPerson = async (
  data: IClientContactPerson
): Promise<AxiosResponse<IClientContactPerson>> =>
  api.put(`/api/Client/Me/ContactPerson/${data.id}`, data);

const createClientContactPerson = async (
  data: IClientContactPerson
): Promise<AxiosResponse<IClientContactPerson>> =>
  api.post(`/api/Client/Me/ContactPerson`, data);

const getClientActionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IActionItem[]>> =>
  api.get(`/api/Client/Me/Actions`, config);

const getClientActionCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/Me/Actions/Count`, config);

const deleteClientAction = async (
  actionId: number
): Promise<AxiosResponse<boolean>> =>
  api.get(`/api/Client/Me/Action/${actionId}/Cancel`);

const clientApi = {
  getClient,
  updateClient,
  getClientHouse,
  getClientCareList,
  getClientCareCount,
  getClientCare,
  getClientFolderList,
  getClientFolderCount,
  getClientFolder,
  getClientDocumentList,
  getClientDocumentCount,
  getClientDocumentDownloadUrl,
  getClientContactPersonList,
  getClientContactPersonCount,
  getClientContactPerson,
  updateClientContactPerson,
  createClientContactPerson,
  getClientActionList,
  getClientActionCount,
  deleteClientAction,
};

export default clientApi;
