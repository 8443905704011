import { IClientDocumentItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientDocumentListTypes {
  CLIENT_DOCUMENT_LIST_FETCH_REQUEST = "@@clientDocumentList/FETCH_REQUEST",
  CLIENT_DOCUMENT_LIST_FETCH_SUCCESS = "@@clientDocumentList/FETCH_SUCCESS",
  CLIENT_DOCUMENT_LIST_FETCH_ERROR = "@@clientDocumentList/FETCH_ERROR",
  CLIENT_DOCUMENT_LIST_COUNT_REQUEST = "@@clientDocumentList/COUNT_REQUEST",
  CLIENT_DOCUMENT_LIST_COUNT_SUCCESS = "@@clientDocumentList/COUNT_SUCCESS",
  CLIENT_DOCUMENT_LIST_COUNT_ERROR = "@@clientDocumentList/COUNT_ERROR",
  CLIENT_DOCUMENT_LIST_CHANGE_ORDER = "@@clientDocumentList/CHANGE_ORDER",
  CLIENT_DOCUMENT_LIST_CHANGE_PAGE = "@@clientDocumentList/CHANGE_PAGE",
  CLIENT_DOCUMENT_LIST_CHANGE_FILTER = "@@clientDocumentList/CHANGE_FILTER",
  CLIENT_DOCUMENT_LIST_RESET = "@@clientDocumentList/RESET",
  CLIENT_DOCUMENT_LIST_LOAD = "@@clientDocumentList/LOAD",
}

export interface IClientDocumentListFilterType extends IFilterType {
  idFolder?: number | null;
}

export interface IClientDocumentListState
  extends IListType<IClientDocumentItem, IClientDocumentListFilterType> {}
