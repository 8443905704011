import { IDemand } from "../../models/demand";
import { StoreState } from "../storeState";

export enum DemandTypes {
  DEMAND_FETCH_REQUEST = "@@demand/FETCH_REQUEST",
  DEMAND_FETCH_SUCCESS = "@@demand/FETCH_SUCCESS",
  DEMAND_FETCH_ERROR = "@@demand/FETCH_ERROR",
}

export interface IDemandState {
  state: StoreState;
  data: IDemand | null;
}
