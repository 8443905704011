import styled, { css } from "styled-components";
import { Field } from "formik";
import { textInput } from "../../../styles/form";
import { SIZES } from "../../../styles/sizes";

export const InputField = styled(Field)<{
  $error?: boolean;
  width?: string;
  height?: string;
}>`
  ${(props) => textInput(props)}
`;

export const InputIcon = styled.div<{ $error?: boolean; hasHover?: boolean }>`
  width: min-content !important;
  position: absolute;
  right: ${({ $error }) => ($error ? "3.5rem" : "0.75rem")};
  top: 50%;
  transform: translateY(-50%);

  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        cursor: pointer;

        svg {
          color: var(--form-icon-color-hover);
        }
      }
    `}
`;

export const InputTextAfter = styled.div`
  margin-left: 1rem;
  flex-shrink: 0;
`;

export const InputLabel = styled.label`
  display: flex;
  @media screen and (min-width: ${SIZES.mobile}) {
    justify-content: flex-end;
    text-align: right;
  }
`;
