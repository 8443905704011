import { createAction } from "@reduxjs/toolkit";
import { IPasswordStrength } from "../../models/basic";
import { PasswordStrengthTypes } from "./types";

export const passwordStrengthFetchRequest = createAction<
  void,
  PasswordStrengthTypes.PASSWORD_STRENGTH_FETCH_REQUEST
>(PasswordStrengthTypes.PASSWORD_STRENGTH_FETCH_REQUEST);

export const passwordStrengthFetchSuccess = createAction<
  IPasswordStrength,
  PasswordStrengthTypes.PASSWORD_STRENGTH_FETCH_SUCCESS
>(PasswordStrengthTypes.PASSWORD_STRENGTH_FETCH_SUCCESS);

export const passwordStrengthFetchError = createAction<
  void,
  PasswordStrengthTypes.PASSWORD_STRENGTH_FETCH_ERROR
>(PasswordStrengthTypes.PASSWORD_STRENGTH_FETCH_ERROR);
