import { createAction } from "@reduxjs/toolkit";
import { IClientHouse } from "../../models/client";
import { ClientHouseTypes } from "./types";

export const clientHouseFetchRequest = createAction<
  void,
  ClientHouseTypes.CLIENT_HOUSE_FETCH_REQUEST
>(ClientHouseTypes.CLIENT_HOUSE_FETCH_REQUEST);

export const clientHouseFetchSuccess = createAction<
  IClientHouse,
  ClientHouseTypes.CLIENT_HOUSE_FETCH_SUCCESS
>(ClientHouseTypes.CLIENT_HOUSE_FETCH_SUCCESS);

export const clientHouseFetchError = createAction<
  void,
  ClientHouseTypes.CLIENT_HOUSE_FETCH_ERROR
>(ClientHouseTypes.CLIENT_HOUSE_FETCH_ERROR);

export const clientHouseReset = createAction<
  void,
  ClientHouseTypes.CLIENT_HOUSE_RESET
>(ClientHouseTypes.CLIENT_HOUSE_RESET);
