import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { IClientDocumentListFilterType, selectClientDocumentList } from ".";
import { CancelToken } from "axios";
import {
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getClientDocumentList(
  folderId: string,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientDocumentList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientDocumentListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);

      const documents = await clientApi.getClientDocumentList(folderId, {
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, documents);

      dispatch(
        actions.clientDocumentListFetchSuccess({
          data: documents.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientDocumentListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientDocumentListCount(
  folderId: string,
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientDocumentList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientDocumentListCountRequest());
    try {
      const count = await clientApi.getClientDocumentCount(folderId, {
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientDocumentListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientDocumentListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientDocumentListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDocumentListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientDocumentListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDocumentListChangePage(page));
  };
}

export function changeClientDocumentListFilter(
  filter: IClientDocumentListFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientDocumentListChangeFilter(filter));
  };
}

export function clientDocumentListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientDocumentList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientDocumentListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientDocumentListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
