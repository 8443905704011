import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import {
  IClientFolderListFilter,
  IClientFolderListState,
  selectClientFolderList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getClientFolderList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const storeState = getState();
    const state = selectClientFolderList(storeState);
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientFolderListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      addClientFolderParams(params, state);

      const folders = await clientApi.getClientFolderList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, folders);

      dispatch(
        actions.clientFolderListFetchSuccess({
          data: folders.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientFolderListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientFolderListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const storeState = getState();
    const state = selectClientFolderList(storeState);
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientFolderListCountRequest());
    try {
      const params = addClientFolderParams(new URLSearchParams(), state);
      const count = await clientApi.getClientFolderCount({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientFolderListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientFolderListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientFolderListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientFolderListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientFolderListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientFolderListChangePage(page));
  };
}

export function changeClientFolderListFilter(
  filter: IClientFolderListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientFolderListChangeFilter(filter));
  };
}

export function clientFolderListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientFolderList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientFolderListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientFolderListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addClientFolderParams(
  params: URLSearchParams,
  state: IClientFolderListState
) {
  if (state.filter!.idFolder) {
    params.append("idFolder", state.filter!.idFolder.toString());
  }
}
