import { AxiosResponse } from "axios";
import { IPasswordStrength } from "../models/basic";
import api from "../utils/api";

const getPasswordStrength = async (): Promise<
  AxiosResponse<IPasswordStrength>
> => api.get(`/api/BasicSettings/PasswordStrength`);

const basicApi = {
  getPasswordStrength,
};

export default basicApi;
