import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { ISidebarLink } from "../../models/common";
import { COLORS } from "../../styles/colors";
import {
  SidebarContainer,
  SidebarHeader,
  SidebarLink,
  SidebarLinks,
} from "./SidebarStyles";

interface IProps {
  title: string;
  showTitle: boolean;
  links: ISidebarLink[];
}

const Sidebar: FC<IProps> = ({ title, showTitle, links }) => {
  return (
    <SidebarContainer>
      {showTitle && <SidebarHeader>{title}</SidebarHeader>}
      <SidebarLinks>
        {links.map((x) => (
          <li key={x.key}>
            <SidebarLink to={x.to}>
              {x.text}
              <FontAwesomeIcon icon={x.icon} color={COLORS.sidebarColor} />
            </SidebarLink>
          </li>
        ))}
      </SidebarLinks>
    </SidebarContainer>
  );
};

export default Sidebar;
