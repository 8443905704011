import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DEBOUNCE_TIMEOUT_MS } from "../../../utils/consts";
import {
  SearchContainer,
  SearchIconWrapper,
  SearchInput,
} from "./SearchStyles";

interface IProps {
  name: string;
  defaultValue?: string;
  onChanged: (value: string) => void;
}

const Search: FC<IProps> = ({ name, defaultValue, onChanged }) => {
  const { t } = useTranslation();

  return (
    <SearchContainer>
      <SearchInput
        placeholder={t("common.searchPlaceholder")}
        type="text"
        id={name}
        name={name}
        defaultValue={defaultValue}
        onChange={debounce(
          (event) => onChanged(event.target.value as string),
          DEBOUNCE_TIMEOUT_MS
        )}
        autoComplete="off"
      />
      <SearchIconWrapper>
        <FontAwesomeIcon icon={faSearch} />
      </SearchIconWrapper>
    </SearchContainer>
  );
};

export default Search;
