import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IApplicationState } from "../../store";
import Grid from "../common/grid/Grid";
import { TableCol } from "../common/grid/TableCol";
import {
  changeClientFolderListOrder,
  changeClientFolderListPage,
  clientFolderListLoad,
  getClientFolderList,
  getClientFolderListCount,
  IClientFolderListState,
  selectClientFolderList,
} from "../../store/clientFolderList";
import { IFolderItem } from "../../models/document";

interface IProps {
  prov: IClientFolderListState;
  getClientFolderList(cancelToken: CancelToken): void;
  getClientFolderListCount(cancelToken: CancelToken): void;
  changeClientFolderListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientFolderListPage(page: number): void;
  clientFolderListLoad(reload: boolean): void;
}

const ClientDocumentFolder: FC<IProps> = ({
  prov,
  getClientFolderList,
  getClientFolderListCount,
  changeClientFolderListOrder,
  changeClientFolderListPage,
  clientFolderListLoad,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    clientFolderListLoad(false);
  }, [clientFolderListLoad]);

  const handleRenderData = (item: IFolderItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
      </>
    );
  };

  const handleRowClick = (item: IFolderItem) => {
    navigate(`/document/${item.id}`);
  };

  return (
    <Grid<IFolderItem>
      headers={[{ captionStr: "module.document.folder.name" }]}
      renderData={handleRenderData}
      getData={getClientFolderList}
      getCount={getClientFolderListCount}
      changeOrder={changeClientFolderListOrder}
      changePage={changeClientFolderListPage}
      onRowClick={handleRowClick}
      prov={prov}
      buttons={false}
    />
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientFolderList(state),
  };
};

const mapDispachToProps = {
  getClientFolderList,
  getClientFolderListCount,
  changeClientFolderListOrder,
  changeClientFolderListPage,
  clientFolderListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ClientDocumentFolder);
