import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IClientContactPerson } from "../../models/client";
import { StoreState } from "../storeState";

export const selectClientContactPersonState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.clientContactPerson.state;

export const selectClientContactPerson: ReselectModule.Selector<
  IApplicationState,
  IClientContactPerson | null
> = (state) => state.clientContactPerson.data;
