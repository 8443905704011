import { createReducer } from "@reduxjs/toolkit";
import { IClientContactPersonState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  data: null,
  state: StoreState.None,
} as IClientContactPersonState;

export const clientContactPersonReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.clientContactPersonFetchRequest, (state) => ({
        ...state,
        state: StoreState.Loading,
      }))
      .addCase(actions.clientContactPersonFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        data: action.payload,
      }))
      .addCase(actions.clientContactPersonFetchError, (state) => ({
        ...state,
        state: StoreState.Error,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
