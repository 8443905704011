import { useFormikContext } from "formik";
import { FC } from "react";
import { InputGroupContainer, InputRow } from "../../../styles/form";
import { InputLabel } from "./InputStyles";
import RequiredMark from "./RequiredMark";

interface IProps {
  label?: string;
  names: string[];
  gridTemplateColumns: string;
}

const FormGroupRow: FC<IProps> = ({
  children,
  label,
  names,
  gridTemplateColumns,
}) => {
  const { errors } = useFormikContext<any>();

  const isError = () => {
    for (const name of names) {
      if (errors[name]) return true;
    }
    return false;
  };

  return (
    <InputRow>
      {label && (
        <InputLabel htmlFor={names[0]}>
          {label}
          {isError() && <RequiredMark />}
        </InputLabel>
      )}
      <InputGroupContainer gridTemplateColumns={gridTemplateColumns}>
        {children}
      </InputGroupContainer>
    </InputRow>
  );
};

export default FormGroupRow;
