import styled from "styled-components";

export const AssistanceAddressOpeningTitle = styled.td`
  padding-right: 1rem;
`;

export const AssistanceAddressMapContainer = styled.div`
  margin-top: 1rem;
`;

export const AssistanceAddressCareTypeLi = styled.li`
  cursor: pointer;
`;

export const AssistanceAddressTitle = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;

export const AssistanceModalContainer = styled.div`
  a {
    text-decoration: none;
  }
`;
