import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IDemand } from "../../models/demand";
import { StoreState } from "../storeState";

export const selectDemandState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.demand.state;

export const selectDemand: ReselectModule.Selector<
  IApplicationState,
  IDemand | null
> = (state) => state.demand.data;
