import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IPasswordStrength } from "../../models/basic";
import { StoreState } from "../storeState";

export const selectPasswordStrengthState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.passwordStrength.state;

export const selectPasswordStrength: ReselectModule.Selector<
  IApplicationState,
  IPasswordStrength | null
> = (state) => state.passwordStrength.data;
