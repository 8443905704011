import { createAction } from "@reduxjs/toolkit";
import { IDemand } from "../../models/demand";
import { DemandTypes } from "./types";

export const demandFetchRequest = createAction<
  void,
  DemandTypes.DEMAND_FETCH_REQUEST
>(DemandTypes.DEMAND_FETCH_REQUEST);

export const demandFetchSuccess = createAction<
  IDemand,
  DemandTypes.DEMAND_FETCH_SUCCESS
>(DemandTypes.DEMAND_FETCH_SUCCESS);

export const demandFetchError = createAction<
  void,
  DemandTypes.DEMAND_FETCH_ERROR
>(DemandTypes.DEMAND_FETCH_ERROR);
