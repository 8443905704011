import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientDocumentItem } from "../../models/client";
import { IApplicationState } from "../../store";
import {
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  getClientDocumentList,
  getClientDocumentListCount,
  IClientDocumentListState,
  selectClientDocumentList,
  clientDocumentListLoad,
} from "../../store/clientDocumentList";
import Grid from "../common/grid/Grid";
import { GridIconDownload } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import clientApi from "../../api/client";
import { promiseToast } from "../../utils/toasts";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { useParams } from "react-router";

interface IProps {
  prov: IClientDocumentListState;
  getClientDocumentList(folderId: string, cancelToken: CancelToken): void;
  getClientDocumentListCount(folderId: string, cancelToken: CancelToken): void;
  changeClientDocumentListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDocumentListPage(page: number): void;
  clientDocumentListLoad(reload: boolean): void;
}

const ClientDocumentDocument: FC<IProps> = ({
  prov,
  getClientDocumentList,
  getClientDocumentListCount,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  clientDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const { folderId } = useParams();

  useEffect(() => {
    clientDocumentListLoad(false);
  }, [clientDocumentListLoad]);

  const handleDownload = async (item: IClientDocumentItem) => {
    try {
      await promiseToast(
        async () => {
          const url = await clientApi.getClientDocumentDownloadUrl(item.id);

          const link = document.createElement("a");
          link.href = url.data;
          link.download = item.name;
          link.target = "_blank";
          link.click();
        },
        t("pending.download"),
        t("success.download"),
        t("errors.unknown")
      );
    } catch {
      //Nothing.
    }
  };

  const handleRenderData = (item: IClientDocumentItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{format(item.createdAt, DATE_TIME_FORMAT)}</TableCol>
        <TableCol>{item.author.name}</TableCol>
        <TableCol>{format(item.lastEditedAt, DATE_TIME_FORMAT)}</TableCol>
        <TableCol>{item.lastEditor.name}</TableCol>
        <TableCol>
          <GridIconDownload onClick={() => handleDownload(item)} />
        </TableCol>
      </>
    );
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientDocumentList(folderId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientDocumentListCount(folderId!, cancelToken);

  return (
    <Grid<IClientDocumentItem>
      headers={[
        {
          captionStr: "document.name",
          orderName: "Name",
        },
        {
          captionStr: "document.createdAt",
          orderName: "CreatedAt",
        },
        {
          captionStr: "document.author",
          orderName: "Author",
        },
        {
          captionStr: "document.lastEditedAt",
          orderName: "LastEditedAt",
        },
        {
          captionStr: "document.lastEditor",
          orderName: "LastEditor",
        },
        {},
      ]}
      renderData={handleRenderData}
      getData={handleGetData}
      getCount={handleGetCount}
      changeOrder={changeClientDocumentListOrder}
      changePage={changeClientDocumentListPage}
      prov={prov}
    />
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDocumentList(state),
  };
};

const mapDispachToProps = {
  getClientDocumentList,
  getClientDocumentListCount,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  clientDocumentListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ClientDocumentDocument);
