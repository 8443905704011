import { IPasswordStrength } from "../../models/basic";
import { StoreState } from "../storeState";

export enum PasswordStrengthTypes {
  PASSWORD_STRENGTH_FETCH_REQUEST = "@@passwordStrength/FETCH_REQUEST",
  PASSWORD_STRENGTH_FETCH_SUCCESS = "@@passwordStrength/FETCH_SUCCESS",
  PASSWORD_STRENGTH_FETCH_ERROR = "@@passwordStrength/FETCH_ERROR",
}

export interface IPasswordStrengthState {
  state: StoreState;
  data: IPasswordStrength | null;
}
