import { FC, useState } from "react";
import { IAssistanceAddressCareType } from "../../models/assistance";
import { AssistanceAddressCareTypeLi } from "./AssistanceAddressStyles";

export interface IProps {
  data: IAssistanceAddressCareType;
}

const AssistanceAddressCareType: FC<IProps> = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (isExpanded) {
    return (
      <li>
        {data.name}
        <br />
        {data.description}
      </li>
    );
  }

  return (
    <AssistanceAddressCareTypeLi onClick={() => setIsExpanded(true)}>
      {data.name}
    </AssistanceAddressCareTypeLi>
  );
};

export default AssistanceAddressCareType;
