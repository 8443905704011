import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authApi from "../../api/auth";
import { IResetPasswordData, LoginResponseStatusType } from "../../models/auth";
import storageApi from "../../utils/storage";
import * as Yup from "yup";
import { connect } from "react-redux";
import { LoginButton, LoginCard, LoginContainer } from "./styles";
import { storageReset } from "../../store/storeReset";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import { useNavigate, useParams } from "react-router";
import { SmallPage } from "../layout/LayoutStyles";
import validations from "../../utils/validations";
import { promiseToast } from "../../utils/toasts";
import Loader from "../common/Loader";
import {
  getPasswordStrength,
  selectPasswordStrength,
  selectPasswordStrengthState,
} from "../../store/passwordStrength";
import { StoreState } from "../../store/storeState";
import { IPasswordStrength } from "../../models/basic";
import { IApplicationState } from "../../store";

const initialValues: IResetPasswordData = {
  pass: "",
  passRepeat: "",
};

interface IProps {
  passwordStrengthState: StoreState;
  passwordStrength: IPasswordStrength | null;
  storageReset(): void;
  getPasswordStrength(): void;
}

const ResetPassword: FC<IProps> = ({
  passwordStrengthState,
  passwordStrength,
  storageReset,
  getPasswordStrength,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [loginResponseStatus, setLoginResponseStatus] =
    useState<LoginResponseStatusType>(LoginResponseStatusType.None);

  const handleSubmit = async (data: IResetPasswordData) => {
    try {
      await promiseToast(
        async () => {
          setLoginResponseStatus(LoginResponseStatusType.None);
          try {
            storageApi.setToken(token!);
            await authApi.resetPassword(data);
          } catch {
            setLoginResponseStatus(LoginResponseStatusType.Error);
            throw new Error();
          } finally {
            storageApi.setToken("");
          }

          navigate("/login");
        },
        t("forgottenPassword.resetPending"),
        t("forgottenPassword.resetSuccess"),
        t("errors.unknown")
      );
    } catch {
      //Nothing.
    }
  };

  useEffect(() => {
    (async () => {
      storageReset();
      localStorage.clear();
      getPasswordStrength();
    })();
    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  if (passwordStrengthState === StoreState.Loading) {
    return <Loader />;
  }

  return (
    <SmallPage>
      <LoginContainer>
        <h1>{t("forgottenPassword.createNewPasswordTitle")}</h1>
        <LoginCard>
          {passwordStrengthState !== StoreState.Loaded ? (
            <ApiError>{t("forgottenPassword.checkError")}</ApiError>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                pass: validations.passwordStrength(
                  t,
                  passwordStrength!.passwordMinLength,
                  passwordStrength!.passwordMinDigitsCount,
                  passwordStrength!.passwordMinCapitalLetters,
                  passwordStrength!.passwordMinLowerCaseLetters
                ),
                passRepeat: validations
                  .stringRequired(t)
                  .oneOf(
                    [Yup.ref("pass")],
                    t("forgottenPassword.passMismatch")
                  ),
              })}
              validateOnMount={true}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <FormGroup labelWidth="13rem">
                    <Input
                      type="password"
                      name="pass"
                      label={t("forgottenPassword.newPass")}
                      error={touched.pass && !!errors.pass}
                      autoComplete="new-password"
                    />
                    <Input
                      type="password"
                      name="passRepeat"
                      label={t("forgottenPassword.newPassRepeat")}
                      error={touched.passRepeat && !!errors.passRepeat}
                      autoComplete="new-password"
                    />
                    {errors.pass && (
                      <ApiError>
                        {t("changePassword.passwordRequirements", {
                          passwordMinLength:
                            passwordStrength!.passwordMinLength,
                          passwordMinDigitsCount:
                            passwordStrength!.passwordMinDigitsCount,
                          passwordMinCapitalLetters:
                            passwordStrength!.passwordMinCapitalLetters,
                          passwordMinLowerCaseLetters:
                            passwordStrength!.passwordMinLowerCaseLetters,
                        })}
                      </ApiError>
                    )}
                    {loginResponseStatus ===
                      LoginResponseStatusType.BadLoginOrPass && (
                      <ApiError>{t("forgottenPassword.badSecret")}</ApiError>
                    )}
                    {loginResponseStatus ===
                      LoginResponseStatusType.TooMuchAttempts && (
                      <ApiError>
                        {t("forgottenPassword.tooMuchAttemptsSecret")}
                      </ApiError>
                    )}
                    {loginResponseStatus === LoginResponseStatusType.Error && (
                      <ApiError>{t("errors.unknown")}</ApiError>
                    )}
                  </FormGroup>
                  <LoginButton type="submit" disabled={isSubmitting}>
                    {t("common.save")}
                  </LoginButton>
                </Form>
              )}
            </Formik>
          )}
        </LoginCard>
      </LoginContainer>
    </SmallPage>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    passwordStrengthState: selectPasswordStrengthState(state),
    passwordStrength: selectPasswordStrength(state),
  };
};

const mapDispachToProps = {
  storageReset,
  getPasswordStrength,
};

export default connect(mapStateToProps, mapDispachToProps)(ResetPassword);
