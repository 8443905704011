import styled from "styled-components";
import { Button } from "../../styles/button";

export const LoginContainer = styled.section`
  width: 100%;
  max-width: 30rem;
`;

export const LoginCard = styled.div`
  margin-top: 1rem;
  padding: 1.5rem;

  background-color: var(--panel-bg);
  border-radius: 8px;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

export const LoginCardActionsRight = styled.div`
  margin-top: 1rem;
  text-align: right;
`;

export const LoginCardActionsLeft = styled.div`
  margin-top: 1rem;
`;

export const ForgottenPasswordInfo = styled.div`
  padding-bottom: 0.5rem;
`;
