import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IAssistanceAddressItem } from "../../models/assistance";
import { IApplicationState } from "../../store";
import {
  changeAssistanceAddressListFilter,
  changeAssistanceAddressListOrder,
  changeAssistanceAddressListPage,
  getAssistanceAddressList,
  getAssistanceAddressListCount,
  IAssistanceAddressListState,
  selectAssistanceAddressList,
  assistanceAddressListLoad,
  IAssistanceAddressListFilter,
} from "../../store/assistanceAddressList";
import Grid from "../common/grid/Grid";
import { TableCol } from "../common/grid/TableCol";
import Page from "../layout/Page";
import AssistanceAddressDetailModal from "./AssistanceAddressDetailModal";
import { H1WithMargin } from "../../styles/text";
import AssistanceAddressFilterPanel from "./AssistanceAddressFilterPanel";
import { getAssistanceAddressFilters } from "../../store/assistanceAddressFilters";
import { GridIconDetail } from "../common/grid/GridIcons";
import AssistanceAddressMap from "./AssistanceAddressMap";
import { assistanceAddressMapLoad } from "../../store/assistanceAddressMap";

interface IProps {
  prov: IAssistanceAddressListState;
  getAssistanceAddressFilters(): void;
  getAssistanceAddressList(cancelToken: CancelToken): void;
  getAssistanceAddressListCount(cancelToken: CancelToken): void;
  changeAssistanceAddressListOrder(orderBy: string, orderDesc: boolean): void;
  changeAssistanceAddressListPage(page: number): void;
  changeAssistanceAddressListFilter(filter: IAssistanceAddressListFilter): void;
  assistanceAddressListLoad(reload: boolean): void;
  assistanceAddressMapLoad(reload: boolean): void;
}

const AssistanceAddress: FC<IProps> = ({
  prov,
  getAssistanceAddressFilters,
  getAssistanceAddressList,
  getAssistanceAddressListCount,
  changeAssistanceAddressListOrder,
  changeAssistanceAddressListPage,
  changeAssistanceAddressListFilter,
  assistanceAddressListLoad,
  assistanceAddressMapLoad,
}) => {
  const { t } = useTranslation();
  const [assistanceAddressDetailId, setAssistanceAddressDetailId] = useState(0);
  const [assistanceAddressDetailIsOpen, setAssistanceAddressDetailIsOpen] =
    useState(false);

  useEffect(() => {
    getAssistanceAddressFilters();
    assistanceAddressListLoad(false);
    assistanceAddressMapLoad(false);
  }, [
    getAssistanceAddressFilters,
    assistanceAddressListLoad,
    assistanceAddressMapLoad,
  ]);

  const handleRenderData = (item: IAssistanceAddressItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.address}</TableCol>
        <TableCol>{item.careTypes}</TableCol>
        <TableCol>
          <GridIconDetail />
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IAssistanceAddressItem) => {
    setAssistanceAddressDetailId(item.id);
    setAssistanceAddressDetailIsOpen(true);
  };

  const handleMarkerClick = (id: number) => {
    setAssistanceAddressDetailId(id);
    setAssistanceAddressDetailIsOpen(true);
  };

  const handleCloseAssistanceAddressDetail = () => {
    setAssistanceAddressDetailIsOpen(false);
  };

  return (
    <>
      <AssistanceAddressDetailModal
        assistanceAddressId={assistanceAddressDetailId}
        isOpen={assistanceAddressDetailIsOpen}
        close={handleCloseAssistanceAddressDetail}
      />

      <Page>
        <H1WithMargin>{t("assistance.pageTitle")}</H1WithMargin>
        <AssistanceAddressFilterPanel
          name="assistanceAddress"
          filter={prov.filter!}
          changeFilter={changeAssistanceAddressListFilter}
        />
        <Grid<IAssistanceAddressItem>
          headers={[
            { captionStr: "assistance.name", orderName: "Name" },
            { captionStr: "assistance.address", orderName: "Street" },
            { captionStr: "assistance.careTypes" },
            {},
          ]}
          renderData={handleRenderData}
          getData={getAssistanceAddressList}
          getCount={getAssistanceAddressListCount}
          changeOrder={changeAssistanceAddressListOrder}
          changePage={changeAssistanceAddressListPage}
          onRowClick={handleRowClick}
          prov={prov}
        />
        <AssistanceAddressMap markerClick={handleMarkerClick} />
      </Page>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectAssistanceAddressList(state),
  };
};

const mapDispachToProps = {
  getAssistanceAddressFilters,
  getAssistanceAddressList,
  getAssistanceAddressListCount,
  changeAssistanceAddressListOrder,
  changeAssistanceAddressListPage,
  changeAssistanceAddressListFilter,
  assistanceAddressListLoad,
  assistanceAddressMapLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AssistanceAddress);
