import { toast } from "react-toastify";
import { TOAST_AUTOCLOSE_LONGER } from "./consts";
import i18n from "./i18n";

export const promiseToast = async <T>(
  fun: () => Promise<T>,
  pending: string,
  success: string,
  error: string
): Promise<T> => {
  return await toast.promise(
    async () => {
      return await fun();
    },
    {
      pending,
      success,
      error: {
        autoClose: TOAST_AUTOCLOSE_LONGER,
        render(err: any) {
          if (err?.data?.response?.data?.isCaseman) {
            if (err.data.response.data.messages.length === 1) {
              return err.data.response.data.messages[0];
            }
            return err.data.response.data.messages
              .map((m: string) => `● ${m}`)
              .join("\n");
          }
          return error;
        },
      },
    }
  );
};

export const promiseToastSave = async <T>(fun: () => Promise<T>): Promise<T> =>
  await promiseToast(
    fun,
    i18n.t("pending.save"),
    i18n.t("success.save"),
    i18n.t("errors.unknown")
  );

export const promiseToastDelete = async <T>(
  fun: () => Promise<T>
): Promise<T> =>
  await promiseToast(
    fun,
    i18n.t("pending.delete"),
    i18n.t("success.delete"),
    i18n.t("errors.unknown")
  );

export const promiseToastRemove = async <T>(
  fun: () => Promise<T>
): Promise<T> =>
  await promiseToast(
    fun,
    i18n.t("pending.remove"),
    i18n.t("success.remove"),
    i18n.t("errors.unknown")
  );

export const promiseToastSaveNoException = async <T>(
  fun: () => Promise<T>
): Promise<void> => {
  try {
    await promiseToastSave(fun);
  } catch {
    // nothing
  }
};

export const promiseToastDeleteNoException = async <T>(
  fun: () => Promise<T>
): Promise<void> => {
  try {
    await promiseToastDelete(fun);
  } catch {
    // nothing
  }
};

export const errorToast = (message?: string): void => {
  toast.error(message ? message : i18n.t("errors.unknown"), {
    autoClose: TOAST_AUTOCLOSE_LONGER,
  });
};
