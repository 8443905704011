import { createAction } from "@reduxjs/toolkit";
import { IItemIdName } from "../../models/common";
import { ClientFolderTypes } from "./types";

export const clientFolderFetchRequest = createAction<
  void,
  ClientFolderTypes.CLIENT_FOLDER_FETCH_REQUEST
>(ClientFolderTypes.CLIENT_FOLDER_FETCH_REQUEST);

export const clientFolderFetchSuccess = createAction<
  IItemIdName[],
  ClientFolderTypes.CLIENT_FOLDER_FETCH_SUCCESS
>(ClientFolderTypes.CLIENT_FOLDER_FETCH_SUCCESS);

export const clientFolderFetchError = createAction<
  void,
  ClientFolderTypes.CLIENT_FOLDER_FETCH_ERROR
>(ClientFolderTypes.CLIENT_FOLDER_FETCH_ERROR);
