import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  DemandPriorityType,
  DemandStateType,
  IDemand,
} from "../../models/demand";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import { getDemand, selectDemand, selectDemandState } from "../../store/demand";
import { FormButton } from "../../styles/button";
import { FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadEmptySuggestions } from "../../utils/suggestions";
import { H1WithMargin } from "../../styles/text";

const fields: Array<{ name: keyof IDemand; maxLen?: number }> = [
  { name: "name", maxLen: 100 },
  { name: "description", maxLen: 512 },
];

interface IProps {
  demandState: StoreState;
  demand: IDemand | null;
  getDemand(demandId: number): void;
}

const ClientDemandDetail: FC<IProps> = ({ demandState, demand, getDemand }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { demandId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDemand(parseInt(demandId!));
    setLoading(false);
  }, [demandId, getDemand]);

  const handleSubmit = () => {};

  const handleCancel = () => {
    navigate(`/demand`);
  };

  if (loading || demandState === StoreState.Loading) {
    return <Loader />;
  }

  if (demandState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IDemand>
      initialValues={{
        ...demand!,
        name: demand!.name ?? "",
        description: demand!.description ?? "",
        assignedUserId: demand!.assignedUserId ?? 0,
        assignedUserName: demand!.assignedUserName ?? "",
        assignedGroupId: demand!.assignedGroupId ?? 0,
        assignedGroupName: demand!.assignedGroupName ?? "",
      }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      <Form>
        {(demand!.state === DemandStateType.New && !!demand!.assignedUserId && (
          <H1WithMargin>{t("demand.detailTitleNew")}</H1WithMargin>
        )) ||
          (demand!.state === DemandStateType.New && !demand!.assignedUserId && (
            <H1WithMargin>{t("demand.detailTitleUnassigned")}</H1WithMargin>
          )) ||
          (demand!.state === DemandStateType.Finished && (
            <H1WithMargin>{t("demand.detailTitleFinished")}</H1WithMargin>
          )) ||
          (demand!.state === DemandStateType.Canceled && (
            <H1WithMargin>{t("demand.detailTitleCanceled")}</H1WithMargin>
          ))}
        <FormGroup>
          {fields.map((f) => (
            <Input
              key={f.name}
              name={f.name}
              label={t("demand." + f.name)}
              maxLength={f.maxLen}
              disabled={true}
            />
          ))}
          <Input
            key="priority"
            name="priority"
            component="select"
            label={t("demand.priority")}
            disabled={true}
          >
            {(
              Object.keys(DemandPriorityType) as Array<
                keyof typeof DemandPriorityType
              >
            ).map((key) => (
              <option key={key} value={DemandPriorityType[key]}>
                {t("demand.priorities." + DemandPriorityType[key])}
              </option>
            ))}
          </Input>
          <SuggestionFormik
            nameId="assignedUserId"
            nameText="assignedUserName"
            label={t("demand.assignedUser")}
            placeholder={t("common.suggestionNoSelected")}
            disabled={true}
            loadSuggestions={handleLoadEmptySuggestions}
          />
          <SuggestionFormik
            nameId="assignedGroupId"
            nameText="assignedGroupName"
            label={t("demand.assignedGroup")}
            placeholder={t("common.suggestionNoSelected")}
            disabled={true}
            loadSuggestions={handleLoadEmptySuggestions}
          />
        </FormGroup>
        <FormButton ver="secondary" type="button" onClick={handleCancel}>
          {t("common.back")}
        </FormButton>
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    demandState: selectDemandState(state),
    demand: selectDemand(state),
  };
};

const mapDispachToProps = {
  getDemand,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDemandDetail);
