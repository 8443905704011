import { createReducer } from "@reduxjs/toolkit";
import { IAssistanceAddressFiltersState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  data: null,
  state: StoreState.None,
} as IAssistanceAddressFiltersState;

export const assistanceAddressFiltersReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.assistanceAddressFiltersFetchRequest, (state) => ({
        ...state,
        state: StoreState.Loading,
      }))
      .addCase(
        actions.assistanceAddressFiltersFetchSuccess,
        (state, action) => ({
          ...state,
          state: StoreState.Loaded,
          data: action.payload,
        })
      )
      .addCase(actions.assistanceAddressFiltersFetchError, (state) => ({
        ...state,
        state: StoreState.Error,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
