import { FC } from "react";
import IdleTimer from "react-idle-timer";
import { useNavigate } from "react-router";
import storageApi from "../../utils/storage";

const IdleHandler: FC = () => {
  const navigate = useNavigate();

  const getTimeout = (): number => {
    const timeout = storageApi.getIdleMinutes();
    //Default must be defined even if not user logged.
    return timeout ? timeout * 60 * 1000 : 86400000;
  };

  const handleIdle = () => {
    const token = storageApi.getToken();
    if (token) {
      navigate("/login");
    }
  };

  return <IdleTimer timeout={getTimeout()} onIdle={handleIdle} />;
};

export default IdleHandler;
