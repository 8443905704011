import styled from "styled-components";

export const CalendarContainer = styled.div<{
  $loading: boolean;
}>`
  .fc-icon-caseman-calendar-left:before {
    content: "🡄";
  }

  .fc-icon-caseman-calendar-right:before {
    content: "🡆";
  }

  .fc-view-harness {
    ${({ $loading }) => ($loading ? "opacity: 0.2;" : "")}
  }
`;
