import styled from "styled-components";
import { GridHeaderContainer } from "./GridStyles";

// everything inside <td> should be wrapped around by a <div> to prevent collapsing

export const Table = styled.table<{
  checkbox?: boolean;
  buttons?: boolean;
}>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.65rem;

  tr {
    transition: background-color 0.2s ease-in-out;
  }

  tr .min-content {
    width: 1%;
  }

  tbody {
    tr {
      height: 2.5rem;
      td:nth-child(${({ checkbox }) => (checkbox ? "n + 2" : "n + 1")})${({
          buttons,
        }) => (buttons !== false ? ":not(:last-child)" : "")} {
        background-color: var(--table-body-bg);

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
          box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.55);
        }
      }

      &:hover {
        td:nth-child(${({ checkbox }) => (checkbox ? "n + 2" : "n + 1")})${({
            buttons,
          }) => buttons !== false && ":not(:last-child)"} {
          background-color: var(--table-body-bg-hover);
        }
      }
    }
  }

  th {
    font-weight: bold;
    white-space: pre;
  }

  td {
    position: relative;
  }

  td,
  th {
    padding: 0.35rem 1rem;
    svg {
      width: 1rem;
    }

    :last-child {
      ${({ buttons }) => buttons !== false && "width: 1%;"}
      ${({ buttons }) => buttons !== false && "padding-right: 0;"}
      ${GridHeaderContainer} {
        ${({ buttons }) => buttons !== false && "justify-content: flex-end;"}
      }
    }
    :first-child {
      ${({ checkbox }) => checkbox !== false && "width: 1%;"}
      ${({ checkbox }) => checkbox !== false && "padding-left: 0;"}
    }
  }

  .width100 {
    width: 100%;
  }

  .center {
    margin: 0 auto;
  }

  .nowrap {
    white-space: nowrap;
  }

  .extra-text {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .error {
    color: var(--error-color);
  }

  .warning {
    color: var(--warning-color);
  }

  .pointer {
    cursor: pointer;
  }
`;

export const TableMessage = styled.div`
  display: flex;
  height: 4rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--table-body-bg-hover);
`;
