import { FC, useRef } from "react";
import { Modal } from "react-responsive-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalMain,
} from "./CustomModalStyles";

interface IProps {
  isOpen: boolean;
  close(): void;
  title: string;
  actions?: JSX.Element;
  isBig?: boolean;
}

const CustomModal: FC<IProps> = ({
  isOpen,
  close,
  title,
  actions,
  children,
  isBig,
}) => {
  const modalMainEl = useRef<HTMLDivElement>(null);

  return (
    <>
      <ModalMain ref={modalMainEl} isBig={isBig} />
      <Modal
        open={isOpen}
        onClose={() => close()}
        showCloseIcon={false}
        container={modalMainEl.current}
        center
      >
        <ModalHeader>
          {title}
          <ModalCloseButton icon={faTimes} onClick={() => close()} />
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
        {actions && <ModalActions isBig={isBig}>{actions}</ModalActions>}
      </Modal>
    </>
  );
};

export default CustomModal;
