import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { StoreStateDontLoad } from "../../../store/storeState";
import { PAGE_SIZE } from "../../../utils/consts";
import { ReactPaginateContainer } from "./PaginationStyles";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../../styles/colors";

interface IProps {
  state: StoreStateDontLoad;
  count: number;
  page: number;
  changePage(page: number): void;
}

const Pagination: FC<IProps> = ({ state, count, page, changePage }) => {
  const { t } = useTranslation();

  if (
    state === StoreStateDontLoad.DontLoad ||
    state === StoreStateDontLoad.None
  ) {
    return null;
  }

  if (
    state === StoreStateDontLoad.Loading ||
    state === StoreStateDontLoad.Cancel
  ) {
    return (
      <ReactPaginateContainer>
        <TailSpin color={COLORS.loaderColor} width={32} height={32} />
      </ReactPaginateContainer>
    );
  }

  if (state === StoreStateDontLoad.Error) {
    return (
      <ReactPaginateContainer>{t("errors.unknown")}</ReactPaginateContainer>
    );
  }

  if (count <= PAGE_SIZE) {
    return null;
  }

  const pageCount = Math.ceil(count / PAGE_SIZE);

  return (
    <ReactPaginateContainer>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={page}
        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
        onPageChange={(selectedItem) => {
          const selectedPage = selectedItem.selected;
          changePage(selectedPage);
        }}
      />
    </ReactPaginateContainer>
  );
};

export default Pagination;
