import { AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";

export function getClientCare(clientCareId: number): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.clientCareFetchRequest());
    try {
      const clientCare = await clientApi.getClientCare(clientCareId);
      dispatch(actions.clientCareFetchSuccess(clientCare.data));
    } catch {
      dispatch(actions.clientCareFetchError());
    }
  };
}
