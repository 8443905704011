import { FC, Fragment } from "react";
import { BreadCrumbSeparator } from "./BreadCrumbStyles";
import { NavLink } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export interface IBreadCrumbItem {
  label: string;
  to?: string;
}

interface IProps {
  items: IBreadCrumbItem[];
}

const BreadCrumb: FC<IProps> = ({ items }) => {
  return (
    <>
      {items.map((item, i) => (
        <Fragment key={i}>
          {i !== items.length - 1 && item.to && (
            <NavLink to={item.to}>{item.label}</NavLink>
          )}
          {i === items.length - 1 && item.to && <b>{item.label}</b>}
          {!item.to && item.label}
          {i !== items.length - 1 && (
            <BreadCrumbSeparator icon={faChevronRight} />
          )}
        </Fragment>
      ))}
    </>
  );
};

export default BreadCrumb;
