import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { InputRow } from "../../../styles/form";
import { COLORS } from "../../../styles/colors";
import FormError from "./FormError";
import {
  DatePickerIconWrapper,
  DatePickerWrapper,
  StyledDatePicker,
} from "./DatePickerStyles";
import cs from "date-fns/locale/cs";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_FORMAT } from "../../../utils/consts";
import { startOfDay } from "date-fns";
import RequiredMark from "./RequiredMark";
import { InputLabel } from "./InputStyles";

const DatePicker: FC<{
  name: string;
  label?: string;
  inputWidth?: string;
  disabled?: boolean;
  tooltipLeft?: boolean;
}> = ({ name, label, inputWidth, disabled, tooltipLeft }) => {
  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    validateForm,
  } = useFormikContext<any>();

  const error = touched[name] && !!errors[name];

  const handleOnChange = (date: Date | null) => {
    setFieldValue(name, date ? startOfDay(date) : null, true);
    validateForm({ ...values, [name]: date });
  };

  const handleOnBlur = () => {
    setFieldTouched(name, true, true);
  };

  return (
    <InputRow>
      {label && (
        <InputLabel htmlFor={name}>
          {label}
          {errors[name] && <RequiredMark />}
        </InputLabel>
      )}
      <DatePickerWrapper>
        <StyledDatePicker
          id={name}
          name={name}
          $error={error}
          width={inputWidth}
          disabled={disabled}
          locale={cs}
          dateFormat={DATE_FORMAT}
          selected={values[name]}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          autoComplete="off"
        />
        <DatePickerIconWrapper>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            color={COLORS.buttonPrimaryBg}
          />
        </DatePickerIconWrapper>
        {error && <FormError name={name} tooltipLeft={tooltipLeft} />}
      </DatePickerWrapper>
    </InputRow>
  );
};

export default DatePicker;
