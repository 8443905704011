import { createAction } from "@reduxjs/toolkit";
import { IDemandItem } from "../../models/demand";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientDemandListTypes } from "./types";

export const clientDemandListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_FETCH_REQUEST
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_FETCH_REQUEST);

export const clientDemandListFetchSuccess = createAction<
  {
    data: IDemandItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientDemandListTypes.CLIENT_DEMAND_LIST_FETCH_SUCCESS
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_FETCH_SUCCESS);

export const clientDemandListFetchError = createAction<
  boolean,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_FETCH_ERROR
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_FETCH_ERROR);

export const clientDemandListCountRequest = createAction<
  void,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_COUNT_REQUEST
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_COUNT_REQUEST);

export const clientDemandListCountSuccess = createAction<
  number,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_COUNT_SUCCESS
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_COUNT_SUCCESS);

export const clientDemandListCountError = createAction<
  boolean,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_COUNT_ERROR
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_COUNT_ERROR);

export const clientDemandListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientDemandListTypes.CLIENT_DEMAND_LIST_CHANGE_ORDER
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_CHANGE_ORDER);

export const clientDemandListChangePage = createAction<
  number,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_CHANGE_PAGE
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_CHANGE_PAGE);

export const clientDemandListChangeFilter = createAction<
  IFilterType,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_CHANGE_FILTER
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_CHANGE_FILTER);

export const clientDemandListReset = createAction<
  void,
  ClientDemandListTypes.CLIENT_DEMAND_LIST_RESET
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_RESET);

export const clientDemandListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientDemandListTypes.CLIENT_DEMAND_LIST_LOAD
>(ClientDemandListTypes.CLIENT_DEMAND_LIST_LOAD);
