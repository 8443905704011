export enum StoreState {
  None = 2,
  Loading = 3,
  Loaded = 4,
  Error = 5,
  Cancel = 6,
}

export enum StoreStateDontLoad {
  DontLoad = 1,
  None = 2,
  Loading = 3,
  Loaded = 4,
  Error = 5,
  Cancel = 6,
}
