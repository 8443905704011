import { createAction } from "@reduxjs/toolkit";
import { IAssistanceAddressFilters } from "../../models/assistance";
import { AssistanceAddressFiltersTypes } from "./types";

export const assistanceAddressFiltersFetchRequest = createAction<
  void,
  AssistanceAddressFiltersTypes.ASSISTANCE_ADDRESS_FILTERS_FETCH_REQUEST
>(AssistanceAddressFiltersTypes.ASSISTANCE_ADDRESS_FILTERS_FETCH_REQUEST);

export const assistanceAddressFiltersFetchSuccess = createAction<
  IAssistanceAddressFilters,
  AssistanceAddressFiltersTypes.ASSISTANCE_ADDRESS_FILTERS_FETCH_SUCCESS
>(AssistanceAddressFiltersTypes.ASSISTANCE_ADDRESS_FILTERS_FETCH_SUCCESS);

export const assistanceAddressFiltersFetchError = createAction<
  void,
  AssistanceAddressFiltersTypes.ASSISTANCE_ADDRESS_FILTERS_FETCH_ERROR
>(AssistanceAddressFiltersTypes.ASSISTANCE_ADDRESS_FILTERS_FETCH_ERROR);
