import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import authApi from "../../api/auth";
import { IForgottenPasswordData } from "../../models/auth";
import * as Yup from "yup";
import {
  ForgottenPasswordInfo,
  LoginButton,
  LoginCard,
  LoginCardActionsLeft,
  LoginContainer,
} from "./styles";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { SmallPage } from "../layout/LayoutStyles";
import validations from "../../utils/validations";
import { promiseToast } from "../../utils/toasts";
import { errorSet } from "../../utils/error";

const initialValues: IForgottenPasswordData = {
  email: "",
};

const ForgottenPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: IForgottenPasswordData) => {
    setError(null);
    try {
      await promiseToast(
        async () => {
          await authApi.sendPasswordResetLink(data);
          navigate("/login");
        },
        t("forgottenPassword.pending"),
        t("forgottenPassword.success"),
        t("errors.unknown")
      );
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <SmallPage>
      <LoginContainer>
        <h1>{t("forgottenPassword.title")}</h1>
        <LoginCard>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: validations.stringRequired(t),
            })}
            validateOnMount={true}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <FormGroup labelWidth="7rem">
                  <ForgottenPasswordInfo>
                    {t("forgottenPassword.info")}
                  </ForgottenPasswordInfo>
                  <Input
                    name="email"
                    label={t("forgottenPassword.email")}
                    error={touched.email && !!errors.email}
                    autoComplete="username"
                  />
                  {error && <ApiError>{error}</ApiError>}
                </FormGroup>
                <LoginButton type="submit" disabled={isSubmitting}>
                  {t("common.send")}
                </LoginButton>
              </Form>
            )}
          </Formik>
          <LoginCardActionsLeft>
            <Link to="/login">{t("forgottenPassword.backToLogin")}</Link>
          </LoginCardActionsLeft>
        </LoginCard>
      </LoginContainer>
    </SmallPage>
  );
};

export default ForgottenPassword;
