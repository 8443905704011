import { IClientCareItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientCareListTypes {
  CLIENT_CARE_LIST_FETCH_REQUEST = "@@clientCareList/FETCH_REQUEST",
  CLIENT_CARE_LIST_FETCH_SUCCESS = "@@clientCareList/FETCH_SUCCESS",
  CLIENT_CARE_LIST_FETCH_ERROR = "@@clientCareList/FETCH_ERROR",
  CLIENT_CARE_LIST_COUNT_REQUEST = "@@clientCareList/COUNT_REQUEST",
  CLIENT_CARE_LIST_COUNT_SUCCESS = "@@clientCareList/COUNT_SUCCESS",
  CLIENT_CARE_LIST_COUNT_ERROR = "@@clientCareList/COUNT_ERROR",
  CLIENT_CARE_LIST_CHANGE_ORDER = "@@clientCareList/CHANGE_ORDER",
  CLIENT_CARE_LIST_CHANGE_PAGE = "@@clientCareList/CHANGE_PAGE",
  CLIENT_CARE_LIST_CHANGE_FILTER = "@@clientCareList/CHANGE_FILTER",
  CLIENT_CARE_LIST_RESET = "@@clientCareList/RESET",
  CLIENT_CARE_LIST_LOAD = "@@clientCareList/LOAD",
}

export interface IClientCareListState
  extends IListType<IClientCareItem, IFilterType> {}
