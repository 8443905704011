import {
  faAt,
  faInbox,
  faLocationDot,
  faMobile,
  faPhone,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { connect } from "react-redux";
import { IAssistanceAddress } from "../../models/assistance";
import { IApplicationState } from "../../store";
import {
  getAssistanceAddress,
  selectAssistanceAddress,
  selectAssistanceAddressState,
} from "../../store/assistanceAddress";
import { StoreState } from "../../store/storeState";
import { Button } from "../../styles/button";
import { COLORS } from "../../styles/colors";
import { ApiError } from "../../styles/form";
import MapWrapper from "../common/map/MapWrapper";
import CustomModal from "../common/modal/CustomModal";
import AssistanceAddressCareType from "./AssistanceAddressCareType";
import {
  AssistanceAddressOpeningTitle,
  AssistanceAddressTitle,
  AssistanceModalContainer,
} from "./AssistanceAddressStyles";

export interface IProps {
  assistanceAddressId: number;
  isOpen: boolean;
  assistanceAddressState: StoreState;
  assistanceAddress: IAssistanceAddress | null;
  close(): void;
  getAssistanceAddress(id: number): void;
}

const AssistanceAddressDetailModal: FC<IProps> = ({
  assistanceAddressId,
  isOpen,
  assistanceAddressState,
  assistanceAddress,
  close,
  getAssistanceAddress,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    getAssistanceAddress(assistanceAddressId);
    setIsLoading(false);
  }, [isOpen, assistanceAddressId, getAssistanceAddress]);

  const content = useMemo(() => {
    if (isLoading || assistanceAddressState === StoreState.Loading) {
      return <TailSpin color={COLORS.loaderColor} width={48} height={48} />;
    }

    if (assistanceAddressState === StoreState.Error) {
      return <ApiError>{t("errors.unknown")}</ApiError>;
    }

    return (
      <AssistanceModalContainer>
        <AssistanceAddressTitle>
          {t("assistance.addressModal.titleAssistance")}
        </AssistanceAddressTitle>
        <p>
          <strong>{assistanceAddress!.name}</strong>
        </p>
        <p>
          <FontAwesomeIcon icon={faLocationDot} color={COLORS.topPanelBg} />{" "}
          {assistanceAddress!.address}
        </p>
        {assistanceAddress!.mobilePhone && (
          <p>
            <a href={"tel:" + assistanceAddress!.mobilePhone}>
              <FontAwesomeIcon icon={faMobile} color={COLORS.topPanelBg} />{" "}
              {assistanceAddress!.mobilePhone}
            </a>
          </p>
        )}
        {assistanceAddress!.phone && (
          <p>
            <a href={"tel:" + assistanceAddress!.phone}>
              <FontAwesomeIcon icon={faPhone} color={COLORS.topPanelBg} />{" "}
              {assistanceAddress!.phone}
            </a>
          </p>
        )}
        {assistanceAddress!.email && (
          <p>
            <a href={"mailto:" + assistanceAddress!.email}>
              <FontAwesomeIcon icon={faAt} color={COLORS.topPanelBg} />{" "}
              {assistanceAddress!.email}
            </a>
          </p>
        )}
        {assistanceAddress!.dataBox && (
          <p>
            <FontAwesomeIcon icon={faInbox} color={COLORS.topPanelBg} />{" "}
            {assistanceAddress!.dataBox}
          </p>
        )}
        {assistanceAddress!.webLink && (
          <p>
            <a
              href={assistanceAddress!.webLink}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                color={COLORS.topPanelBg}
              />{" "}
              {assistanceAddress!.webLink}
            </a>
          </p>
        )}

        <p>&nbsp;</p>

        <AssistanceAddressTitle>
          {t("assistance.addressModal.titleAddress")}
        </AssistanceAddressTitle>
        <p>
          <strong>{assistanceAddress!.addressName}</strong>
        </p>
        <p>
          <FontAwesomeIcon icon={faLocationDot} color={COLORS.topPanelBg} />{" "}
          {assistanceAddress!.addressAddress}
        </p>
        {assistanceAddress!.addressMobilePhone && (
          <p>
            <a href={"tel:" + assistanceAddress!.addressMobilePhone}>
              <FontAwesomeIcon icon={faMobile} color={COLORS.topPanelBg} />{" "}
              {assistanceAddress!.addressMobilePhone}
            </a>
          </p>
        )}
        {assistanceAddress!.addressPhone && (
          <p>
            <a href={"tel:" + assistanceAddress!.addressPhone}>
              <FontAwesomeIcon icon={faPhone} color={COLORS.topPanelBg} />{" "}
              {assistanceAddress!.addressPhone}
            </a>
          </p>
        )}
        {assistanceAddress!.addressEmail && (
          <p>
            <a href={"mailto:" + assistanceAddress!.addressEmail}>
              <FontAwesomeIcon icon={faAt} color={COLORS.topPanelBg} />{" "}
              {assistanceAddress!.addressEmail}
            </a>
          </p>
        )}
        {assistanceAddress!.addressWebLink && (
          <p>
            <a
              href={assistanceAddress!.addressWebLink}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                color={COLORS.topPanelBg}
              />{" "}
              {assistanceAddress!.addressWebLink}
            </a>
          </p>
        )}

        <p>&nbsp;</p>

        <p>
          <strong>{t("assistance.addressModal.careTypes")}</strong>
        </p>
        <ul>
          {assistanceAddress!.addressCareTypes.map((x) => (
            <AssistanceAddressCareType key={x.id} data={x} />
          ))}
        </ul>

        <p>&nbsp;</p>

        <p>
          <strong>{t("assistance.addressModal.targetGroups")}</strong>
        </p>
        <ul>
          {assistanceAddress!.addressTargetGroups.map((x) => (
            <li key={x.id}>{x.name}</li>
          ))}
        </ul>

        <p>&nbsp;</p>

        <p>
          <strong>{t("assistance.addressModal.openingHours")}</strong>
        </p>
        <table>
          <tbody>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openMonday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenMonday}</td>
            </tr>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openTuesday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenTuesday}</td>
            </tr>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openWednesday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenWednesday}</td>
            </tr>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openThursday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenThursday}</td>
            </tr>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openFriday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenFriday}</td>
            </tr>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openSaturday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenSaturday}</td>
            </tr>
            <tr>
              <AssistanceAddressOpeningTitle>
                {t("assistance.addressModal.openSunday")}
              </AssistanceAddressOpeningTitle>
              <td>{assistanceAddress!.addressOpenSunday}</td>
            </tr>
          </tbody>
        </table>

        <p>&nbsp;</p>

        <MapWrapper
          loading={false}
          error={false}
          points={[
            {
              id: 0,
              name: assistanceAddress!.addressName,
              lat: assistanceAddress!.lat,
              lng: assistanceAddress!.lng,
            },
          ]}
          markerClick={() => undefined}
        />
      </AssistanceModalContainer>
    );
  }, [isLoading, assistanceAddressState, assistanceAddress, t]);

  return (
    <CustomModal
      title={t("assistance.addressModal.title")}
      isOpen={isOpen}
      close={close}
      actions={
        <>
          <Button ver="secondary" onClick={close}>
            {t("common.close")}
          </Button>
        </>
      }
    >
      {content}
    </CustomModal>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceAddressState: selectAssistanceAddressState(state),
    assistanceAddress: selectAssistanceAddress(state),
  };
};

const mapDispachToProps = {
  getAssistanceAddress,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AssistanceAddressDetailModal);
