import { createAction } from "@reduxjs/toolkit";
import { IAssistanceAddress } from "../../models/assistance";
import { AssistanceAddressTypes } from "./types";

export const assistanceAddressFetchRequest = createAction<
  void,
  AssistanceAddressTypes.ASSISTANCE_ADDRESS_FETCH_REQUEST
>(AssistanceAddressTypes.ASSISTANCE_ADDRESS_FETCH_REQUEST);

export const assistanceAddressFetchSuccess = createAction<
  IAssistanceAddress,
  AssistanceAddressTypes.ASSISTANCE_ADDRESS_FETCH_SUCCESS
>(AssistanceAddressTypes.ASSISTANCE_ADDRESS_FETCH_SUCCESS);

export const assistanceAddressFetchError = createAction<
  void,
  AssistanceAddressTypes.ASSISTANCE_ADDRESS_FETCH_ERROR
>(AssistanceAddressTypes.ASSISTANCE_ADDRESS_FETCH_ERROR);
