import axios, { CancelToken, CancelTokenSource } from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IMapPoint } from "../../models/map";
import { IApplicationState } from "../../store";
import {
  getAssistanceAddressMap,
  selectAssistanceAddressMapData,
  selectAssistanceAddressMapState,
} from "../../store/assistanceAddressMap";
import { StoreState } from "../../store/storeState";
import MapWrapper from "../common/map/MapWrapper";
import { AssistanceAddressMapContainer } from "./AssistanceAddressStyles";

interface IProps {
  state: StoreState;
  data: IMapPoint[];
  markerClick(id: number): void;
  getAssistanceAddressMap(cancelToken: CancelToken): void;
}

const AssistanceAddressMap: FC<IProps> = ({
  state,
  data,
  markerClick,
  getAssistanceAddressMap,
}) => {
  const dataCancelToken = useRef<CancelTokenSource | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dataCancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state !== StoreState.None) {
      if (loading) {
        setLoading(false);
      }
      return;
    }

    dataCancelToken.current?.cancel();
    dataCancelToken.current = axios.CancelToken.source();

    getAssistanceAddressMap(dataCancelToken.current.token);

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleMarkerClick = (point: IMapPoint) => {
    markerClick(point.id);
  };

  return (
    <AssistanceAddressMapContainer>
      <MapWrapper
        loading={loading || state === StoreState.Loading}
        error={state === StoreState.Error}
        points={data}
        markerClick={handleMarkerClick}
      />
    </AssistanceAddressMapContainer>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    state: selectAssistanceAddressMapState(state),
    data: selectAssistanceAddressMapData(state),
  };
};

const mapDispachToProps = {
  getAssistanceAddressMap,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AssistanceAddressMap);
