import { createGlobalStyle } from "styled-components";
import { COLORS } from "./colors";

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 16px;

    // Colors
    --background: ${COLORS.background};

    --header-bg: ${COLORS.headerBg};
    --header-bg-hover: ${COLORS.headerBgHover};
    --header-bg-active: ${COLORS.headerBgActive};
    --header-color: ${COLORS.headerColor};
    --header-color-hover: ${COLORS.headerColorHover};
    --header-color-active: ${COLORS.headerColorActive};

    --menu-bg: ${COLORS.menuBg};
    --menu-bg-hover: ${COLORS.menuBgHover};
    --menu-bg-active: ${COLORS.menuBgActive};
    --menu-shadow: ${COLORS.menuShadow};
    --menu-color: ${COLORS.menuColor};
    --menu-color-hover: ${COLORS.menuColorHover};
    --menu-color-active: ${COLORS.menuColorActive};

    --sidebar-bg: ${COLORS.sidebarBg};
    --sidebar-bg-hover: ${COLORS.sidebarBgHover};
    --sidebar-bg-active: ${COLORS.sidebarBgActive};
    --sidebar-color: ${COLORS.sidebarColor};
    --sidebar-color-hover: ${COLORS.sidebarColorHover};
    --sidebar-color-active: ${COLORS.sidebarColorActive};

    --top-panel-bg: ${COLORS.topPanelBg};
    --top-panel-border: ${COLORS.topPanelBorder};

    --table-header-bg: ${COLORS.tableHeaderBg};
    --table-header-color: ${COLORS.tableHeaderColor};
    --table-header-color-hober: ${COLORS.tableHeaderColorHover};
    --table-body-bg: ${COLORS.tableBodyBg};
    --table-body-bg-hover: ${COLORS.tableBodyBgHover};
    --table-body-border: ${COLORS.tableBodyBorder};
    --table-body-color-hover: ${COLORS.tableBodyColorHover};
    --table-pg-bg-active: ${COLORS.tablePgBgActive};
    --table-pg-bg-hover: ${COLORS.tablePgBgHover};
    --table-pg-color-active: ${COLORS.tablePgColorActive};
    --table-order-color: ${COLORS.tableOrderColor};
    --table-order-color-hover: ${COLORS.tableOrderColorHover};
    --table-order-color-active: ${COLORS.tableOrderColorActive};

    --title-color: ${COLORS.titleColor};
    --text-color: ${COLORS.textColor};
    --loader-color: ${COLORS.loaderColor};
    --error-color: ${COLORS.errorColor};
    --warning-color: ${COLORS.warningColor};
    --success-color: ${COLORS.successColor};
    --selected-color: ${COLORS.selectedColor};
    --disabled-color: ${COLORS.disabledColor};

    --border: ${COLORS.border};
    --border-focus: ${COLORS.borderFocus};
    --border-focus-shadow: ${COLORS.borderFocusShadow};
    --border-error: ${COLORS.borderError};

    --panel-bg: ${COLORS.panelBg};
    --selected-bg: ${COLORS.selectedBg};
    --disabled-bg: ${COLORS.disabledBg};

    --form-icon-color: ${COLORS.formIconColor};
    --form-icon-color-hover: ${COLORS.formIconColorHover};
    --error-icon-color: ${COLORS.errorIconColor};
    --success-icon-color: ${COLORS.successIconColor};

    --button-primary-bg: ${COLORS.buttonPrimaryBg};
    --button-primary-bg-hover: ${COLORS.buttonPrimaryBgHover};
    --button-primary-border: ${COLORS.buttonPrimaryBorder};
    --button-primary-border-hover: ${COLORS.buttonPrimaryBorderHover};
    --button-primary-color: ${COLORS.buttonPrimaryColor};
    --button-primary-color-hover: ${COLORS.buttonPrimaryColorHover};
    --button-secondary-bg: ${COLORS.buttonSecondaryBg};
    --button-secondary-bg-hover: ${COLORS.buttonSecondaryBgHover};
    --button-secondary-border: ${COLORS.buttonSecondaryBorder};
    --button-secondary-border-hover: ${COLORS.buttonSecondaryBorderHover};
    --button-secondary-color: ${COLORS.buttonSecondaryColor};
    --button-secondary-color-hover: ${COLORS.buttonSecondaryColorHover};
    --button-disabled-bg: ${COLORS.buttonDisabledBg};
    --button-disabled-border: ${COLORS.buttonDisabledBorder};
    --button-disabled-color: ${COLORS.buttonDisabledColor};

    --calendar-border: ${COLORS.calendarBorder};
    --calendar-available-color: ${COLORS.calendarAvailableColor};
    --calendar-available-bg: ${COLORS.calendarAvailableBg};
    --calendar-available-hover: ${COLORS.calendarAvailableHover};
    --calendar-unavailable-color: ${COLORS.calendarUnavailableColor};
    --calendar-unavailable-bg: ${COLORS.calendarUnavailableBg};
    --calendar-unavailable-hover: ${COLORS.calendarUnavailableHover};
    --calendar-actual-day-bg: ${COLORS.calendarActualDayBg};
    --calendar-buttons-color: ${COLORS.calendarButtonsColor};
    --calendar-buttons-bg: ${COLORS.calendarButtonsBg};
    --calendar-date-border: ${COLORS.calendarDateBorder};

    --suggestion-item-bg: ${COLORS.suggestionItemBg};
    --suggestion-item-bg-hover: ${COLORS.suggestionItemBgHover};

    --toast-success-bg: ${COLORS.toastSuccessBg};
    --toast-success-color: ${COLORS.toastSuccessColor};
    --toast-warning-bg: ${COLORS.toastWarningBg};
    --toast-warning-color: ${COLORS.toastWarningColor};
    --toast-error-bg: ${COLORS.toastErrorBg};
    --toast-error-color: ${COLORS.toastErrorColor};

    --suggestion-highlighted-bg: ${COLORS.suggestionHighlightedBg};

    --hr-color: ${COLORS.hrColor};

    --transparent: ${COLORS.transparent};
  }

  *, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }

  /* Font awesome in grid. */
  svg {
    box-sizing: border-box !important;
  }

  body {
    color: var(--text-color);
    background-color: var(--background);
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    line-height: 1.2;
  }

  h1, h2, h3, h4, h5 {
    color: var(--title-color);
  }

  h1 {
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 0.4rem;
  }

  a {
    color: var(--text-color);
    text-decoration: underline;

    &:hover {
      color: var(--text-color);
      cursor: pointer;
    }
  }

  button {
    -webkit-tap-highlight-color: var(--transparent);
  }

  textarea {
    resize: none;
  }

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid var(--hr-color);
  }

  // modal
  .react-responsive-modal-modal {
    padding: 0;
  }

  // toast
  .Toastify__toast.Toastify__toast--info {
    background-color: var(--toast-success-bg);
    color: var(--toast-success-color);
  }
  .Toastify__toast.Toastify__toast--success {
    background-color: var(--toast-success-bg);
    color: var(--toast-success-color);
  }
  .Toastify__toast.Toastify__toast--warning {
    background-color: var(--toast-warning-bg);
    color: var(--toast-warning-color);
  }
  .Toastify__toast.Toastify__toast--error {
    background-color: var(--toast-error-bg);
    color: var(--toast-error-color);
  }
  .Toastify__toast-body {
    white-space: pre-line;
  }
`;

export default GlobalStyles;
