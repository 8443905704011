import * as ReselectModule from "reselect";
import { IAssistanceAddressMapState } from ".";
import { IApplicationState } from "..";
import { IMapPoint } from "../../models/map";
import { StoreState } from "../storeState";

export const selectAssistanceAddressMap: ReselectModule.Selector<
  IApplicationState,
  IAssistanceAddressMapState
> = (state) => state.assistanceAddressMap;

export const selectAssistanceAddressMapState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.assistanceAddressMap.state;

export const selectAssistanceAddressMapData: ReselectModule.Selector<
  IApplicationState,
  IMapPoint[]
> = (state) => state.assistanceAddressMap.data;
