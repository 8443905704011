import styled from "styled-components";
import { SIZES } from "../../../styles/sizes";

export const ReactPaginateContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
    list-style-type: none;
    user-select: none;
  }

  li:not(.previous):not(.next) {
    display: flex;
    border: 1px solid var(--table-body-border);
    border-right: 0;

    &:nth-child(2) {
      border-radius: 3px 0px 0px 3px;
    }

    &:nth-last-child(2) {
      border-radius: 0px 3px 3px 0px;
      border: 1px solid var(--table-body-border);
    }
  }

  a {
    display: block;
    text-decoration: none;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    outline: none;
    color: var(--text-color);
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: var(--table-pg-bg-hover);
    }
  }

  .previous {
    border: 1px solid var(--table-body-border);
    border-radius: 3px;
    margin-right: 0.25rem;

    @media screen and (min-width: ${SIZES.mobile}) {
      margin-right: 1rem;
    }

    svg {
      vertical-align: -0.2rem;
    }
  }

  .selected {
    border: none;

    a {
      background-color: var(--table-pg-bg-active);
      color: var(--table-pg-color-active);
    }
  }

  .next {
    border: 1px solid var(--table-body-border);
    border-radius: 3px;
    margin-left: 0.25rem;

    @media screen and (min-width: ${SIZES.mobile}) {
      margin-left: 1rem;
    }

    svg {
      vertical-align: -0.2rem;
    }
  }

  .disabled {
    a {
      color: var(--disabled-color);
    }
  }
`;
