export enum LoginResponseStatusType {
  None = "none",
  BadLoginOrPass = "badLoginOrPass",
  TooMuchAttempts = "tooMuchAttempts",
  Ok = "ok",
  Expired = "expired",
  Error = "error",
  TwoFactor = "twoFactor",
}

export interface ILoginData {
  login: string;
  pass: string;
}

export interface ILoginResponse {
  status: LoginResponseStatusType;
  token: string | null;
  tokenValidMinutes: number | null;
  userMaxIdleMinutes: number | null;
}

export interface ITwoFactorData {
  secret: string;
}

export interface IForgottenPasswordData {
  email: string;
}

export interface IResetPasswordData {
  secret?: string | null;
  pass: string;
  passRepeat: string;
}

export interface IChangePasswordData {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface IChangePasswordResult {
  token: string;
  tokenValidMinutes: number;
  userMaxIdleMinutes: number;
}
