import { createReducer } from "@reduxjs/toolkit";
import { IAssistanceAddressListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "Name",
  orderDesc: false,
  filter: {
    search: undefined,
    isOpen: false,
    categoryId: 0,
    careTypeId: 0,
    targetGroupId: 0,
  },
} as IAssistanceAddressListState;

export const assistanceAddressListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.assistanceAddressListFetchRequest, (state, action) => ({
        ...state,
        state: StoreState.Loading,
        countState: action.payload,
      }))
      .addCase(actions.assistanceAddressListFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload.data,
        countState: action.payload.newCountState,
        count: action.payload.newCount,
      }))
      .addCase(actions.assistanceAddressListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.assistanceAddressListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(actions.assistanceAddressListCountSuccess, (state, action) => ({
        ...state,
        countState: StoreStateDontLoad.Loaded,
        count: action.payload,
      }))
      .addCase(actions.assistanceAddressListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.assistanceAddressListChangeOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
      .addCase(actions.assistanceAddressListChangePage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(actions.assistanceAddressListChangeFilter, (state, action) => ({
        ...state,
        state: StoreState.None,
        countState: StoreStateDontLoad.DontLoad,
        filter: action.payload,
        page: 0,
      }))
      .addCase(actions.assistanceAddressListReset, (state) => ({
        ...initialState,
        filter: state.filter,
      }))
      .addCase(actions.assistanceAddressListLoadAction, (state, action) => ({
        ...state,
        state: action.payload.newState,
        countState: action.payload.newCountState,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
