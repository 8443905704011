import { createAction } from "@reduxjs/toolkit";
import { IClientCare } from "../../models/client";
import { ClientCareTypes } from "./types";

export const clientCareFetchRequest = createAction<
  void,
  ClientCareTypes.CLIENT_CARE_FETCH_REQUEST
>(ClientCareTypes.CLIENT_CARE_FETCH_REQUEST);

export const clientCareFetchSuccess = createAction<
  IClientCare,
  ClientCareTypes.CLIENT_CARE_FETCH_SUCCESS
>(ClientCareTypes.CLIENT_CARE_FETCH_SUCCESS);

export const clientCareFetchError = createAction<
  void,
  ClientCareTypes.CLIENT_CARE_FETCH_ERROR
>(ClientCareTypes.CLIENT_CARE_FETCH_ERROR);
