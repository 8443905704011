import { IMapPoint } from "../../models/map";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum AssistanceAddressMapTypes {
  ASSISTANCE_ADDRESS_MAP_FETCH_REQUEST = "@@assistanceAddressMap/FETCH_REQUEST",
  ASSISTANCE_ADDRESS_MAP_FETCH_SUCCESS = "@@assistanceAddressMap/FETCH_SUCCESS",
  ASSISTANCE_ADDRESS_MAP_FETCH_ERROR = "@@assistanceAddressMap/FETCH_ERROR",
  ASSISTANCE_ADDRESS_MAP_CHANGE_FILTER = "@@assistanceAddressMap/CHANGE_FILTER",
  ASSISTANCE_ADDRESS_MAP_RESET = "@@assistanceAddressMap/RESET",
  ASSISTANCE_ADDRESS_MAP_LOAD = "@@assistanceAddressMap/LOAD",
}

export interface IAssistanceAddressMapState
  extends IListType<IMapPoint, IFilterType> {}
