import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import AssistanceAddress from "./components/assistance/AssistanceAddress";
import ForgottenPassword from "./components/auth/ForgottenPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Routes2 from "./Routes2";

export const Routes1: FC = () => (
  <Routes>
    <Route path="forgotten-password" element={<ForgottenPassword />} />
    <Route path="forgotten-password/:token" element={<ResetPassword />} />
    <Route path="assistance" element={<AssistanceAddress />} />
    <Route path="*" element={<Routes2 />} />
  </Routes>
);
