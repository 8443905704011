import { TFunction } from "i18next";
import * as Yup from "yup";
import libphonenumber from "google-libphonenumber";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const stringRequired = (t: TFunction) =>
  Yup.string().required(t("errors.required"));

export const stringMin = (min: number, t: TFunction) =>
  Yup.string()
    .required()
    .min(min, t("errors.requiredMin").replaceAll("{0}", min.toString()));

export const idRequired = (t: TFunction) =>
  Yup.number().required(t("errors.required")).moreThan(0, t("errors.required"));

export const intOptional = (t: TFunction) =>
  Yup.number().integer(t("errors.int"));

export const intRequired = (t: TFunction) =>
  intOptional(t).required(t("errors.required"));

export const intMoreThanZero = (t: TFunction) =>
  intRequired(t).moreThan(0, t("errors.required"));

export const intMoreThanEqualZero = (t: TFunction) =>
  intRequired(t).moreThan(-1, t("errors.required"));

export const intOptionalMinMax = (min: number, max: number, t: TFunction) =>
  intOptional(t)
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

export const intRequiredMinMax = (min: number, max: number, t: TFunction) =>
  intRequired(t)
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

export const numberOptional = () => Yup.number();

export const numberRequired = (t: TFunction) =>
  numberOptional().required(t("errors.required"));

export const numberMoreThanZero = (t: TFunction) =>
  numberRequired(t).moreThan(0, t("errors.required"));

export const numberOptionalMinMax = (min: number, max: number, t: TFunction) =>
  numberOptional()
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

export const numberRequiredMinMax = (min: number, max: number, t: TFunction) =>
  numberRequired(t)
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

export const dateRequired = (t: TFunction) =>
  Yup.date().required(t("errors.required")).nullable();

export const arrayMoreThanZero = (t: TFunction) =>
  Yup.array().required(t("errors.required")).min(1, t("errors.required"));

export const phoneOptional = (t: TFunction) =>
  Yup.string().test("phone", t("errors.phone"), (value) => {
    if (!value) {
      return true;
    }

    try {
      const number = phoneUtil.parse(value);
      if (!phoneUtil.isValidNumber(number)) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  });

export const phoneRequired = (t: TFunction) =>
  phoneOptional(t).required(t("errors.required"));

export const emailOptional = (t: TFunction) =>
  Yup.string().email(t("errors.email"));

export const emailRequired = (t: TFunction) =>
  emailOptional(t).required(t("errors.required"));

export const urlOptional = (t: TFunction) => Yup.string().url(t("errors.url"));

export const urlRequired = (t: TFunction) =>
  urlOptional(t).required(t("errors.required"));

export const postCodeOptional = (t: TFunction) =>
  numberOptional()
    .moreThan(9999, t("errors.postCode"))
    .lessThan(100000, t("errors.postCode"));

export const postCodeRequired = (t: TFunction) =>
  postCodeOptional(t).required(t("errors.required"));

export const identificationNumberOptional = (t: TFunction) =>
  Yup.string()
    .test("identificationNumber", t("errors.identificationNumber"), (value) => {
      if (!value) {
        return true;
      }
      if (!/^[0-9]{6}\/[0-9]{3,4}$/.test(value)) {
        return false;
      }
      return validateIdentificationNumber(value.slice(0, 6) + value.slice(7));
    })
    .nullable();

export const companyNoOptional = (t: TFunction) =>
  Yup.string().test("companyNo", t("errors.companyNo"), (value) => {
    if (!value) {
      return true;
    }

    if (!/^[0-9]{8}$/.test(value)) {
      return false;
    }

    return validateRegNumber(value);
  });

export const companyNoRequired = (t: TFunction) =>
  companyNoOptional(t).required(t("errors.required"));

export const companyVatNoOptional = (t: TFunction) =>
  Yup.string().test("companyVatNo", t("errors.companyVatNo"), (value) => {
    if (!value) {
      return true;
    }

    if (
      /^[A-Za-z]{2}(?=.{8}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/.test(value)
    ) {
      return validateRegNumber(value.substring(2));
    } else if (
      /^[A-Za-z]{2}(?=.{9,10}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/.test(value)
    ) {
      return validateIdentificationNumber(value.substring(2));
    }

    return false;
  });

export const companyVatNoRequired = (t: TFunction) =>
  companyVatNoOptional(t).required(t("errors.required"));

const validateRegNumber = (value: string): boolean => {
  try {
    const divisor = 11;

    var sum = 0;
    var number = value.trim();
    var factor = number.length;
    var numberBase = number.slice(0, -1);
    var lastDigit = parseInt(number[number.length - 1]);

    for (var i = 0; i < numberBase.length; i++) {
      var parsedDigit = parseInt(numberBase[i]);
      sum += parsedDigit * factor;
      factor--;
    }

    var residue = sum % divisor;

    if (residue === 0 && lastDigit === 1) {
      return true;
    }

    if (residue === 1 && lastDigit === 0) {
      return true;
    }

    if (lastDigit === divisor - residue) {
      return true;
    }
  } catch (err) {}

  return false;
};

const validateIdentificationNumber = (value: string): boolean => {
  try {
    const number = value.trim();

    if (number.length !== 9 && number.length !== 10) return false;

    const day = parseInt(number.slice(4, 6));
    const ending = number.slice(6);
    if (day < 1 || day > 31) return false;
    if (ending === "000") return false;

    if (number.length === 9 && parseInt(number.slice(0, 2)) >= 54) {
      return false;
    }

    if (number.length === 10) {
      const numberBase = parseInt(number.slice(0, -1));
      const lastDigit = parseInt(number[number.length - 1]);
      const residue = numberBase % 11;

      if (residue === 10) {
        return lastDigit === 0;
      }
      return numberBase % 11 === lastDigit;
    }
    return true;
  } catch (err) {}
  return false;
};

export const passwordStrength = (
  t: TFunction,
  minLength: number,
  minDigitsCount: number,
  minCapitalLetters: number,
  minLowerCaseLetters: number
) =>
  stringRequired(t).test(
    "passwordStrength",
    t("errors.passNotStrong"),
    (value) => {
      if (!value) {
        return false;
      }

      try {
        if (value.length < minLength) {
          return false;
        }

        if (value.replace(/\D/g, "").length < minDigitsCount) {
          return false;
        }

        if (value.replace(/[^A-Z]/g, "").length < minCapitalLetters) {
          return false;
        }

        if (value.replace(/[^a-z]/g, "").length < minLowerCaseLetters) {
          return false;
        }

        return true;
      } catch (err) {
        return false;
      }
    }
  );

const valueFromTo = (min: number, max: number, t: TFunction) =>
  t("errors.valueFromTo")
    .replaceAll("{0}", min.toString())
    .replaceAll("{1}", max.toString());

const validations = {
  stringRequired,
  stringMin,
  idRequired,
  intOptional,
  intRequired,
  intMoreThanZero,
  intMoreThanEqualZero,
  intOptionalMinMax,
  intRequiredMinMax,
  numberOptional,
  numberRequired,
  numberMoreThanZero,
  numberOptionalMinMax,
  numberRequiredMinMax,
  dateRequired,
  arrayMoreThanZero,
  phoneOptional,
  phoneRequired,
  emailOptional,
  emailRequired,
  urlOptional,
  urlRequired,
  postCodeOptional,
  postCodeRequired,
  identificationNumberOptional,
  companyNoOptional,
  companyNoRequired,
  companyVatNoOptional,
  companyVatNoRequired,
  passwordStrength,
};

export default validations;
