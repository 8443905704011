import styled, { css } from "styled-components";
import { fadeIn } from "../../../styles/effects";
import { SIZES } from "../../../styles/sizes";

export const TooltipContent = styled.div<{ left?: boolean }>`
  display: none;

  position: absolute;
  top: 0;
  right: 100%;

  width: max-content;
  min-height: 2rem;
  max-width: 18rem;
  padding: 0.5rem 0.8rem;

  background: var(--panel-bg);
  color: var(--text-color);
  font-weight: normal;
  font-size: 0.8rem;

  overflow: hidden;
  pointer-events: none;

  animation: ${fadeIn} 0.15s ease-in-out;

  ${({ left }) =>
    !left &&
    css`
      @media screen and (min-width: ${SIZES.desktop}) {
        left: 2rem;
        right: auto;
      }
    `}
`;

export const FormErrorContainer = styled.div`
  width: 2rem !important;
  height: 2rem !important;
  margin-left: 1rem;

  position: relative;
  top: 0;
  right: 0;
  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  background-color: var(--error-color);
  color: var(--text-color);
  border-radius: 0.25rem 0 0 0.25rem;
  font-size: 0.8rem;

  &:hover {
    ${TooltipContent} {
      display: block;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: -0.4rem;
    transform: rotate(45deg);

    width: 0.8rem;
    height: 0.8rem;

    background-color: var(--error-color);
  }
`;
