import styled from "styled-components";

export const TopPanel = styled.div`
  color: white;
  width: 100%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  background-color: var(--top-panel-bg);
  border-bottom: 5px solid;
  border-color: var(--top-panel-border);
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
`;

export const TopPanelClient = styled.div`
  flex: 1;
`;

export const TopPanelContactPerson = styled.div`
  margin-right: 1rem;
  font-size: 1rem;
`;
