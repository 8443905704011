import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";
import { selectAssistanceAddressMap } from ".";
import { CancelToken } from "axios";
import {
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsGetCheck,
} from "../listEffect";
import {
  IAssistanceAddressListFilter,
  selectAssistanceAddressListFilter,
} from "../assistanceAddressList";

export function getAssistanceAddressMap(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const storeState = getState();
    const state = selectAssistanceAddressMap(storeState);
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    dispatch(actions.assistanceAddressMapFetchRequest());
    try {
      const filter = selectAssistanceAddressListFilter(storeState);

      const params = new URLSearchParams();
      listEffectGetFilter(params, filter);
      addAssistanceAddressParams(params, filter);

      const addresses = await assistanceApi.getAssistanceAddressMap({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.assistanceAddressMapFetchSuccess(addresses.data));
    } catch {
      dispatch(
        actions.assistanceAddressMapFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function assistanceAddressMapLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectAssistanceAddressMap(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.assistanceAddressMapReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(actions.assistanceAddressMapLoadAction(newState.newState));
    }
  };
}

export function addAssistanceAddressParams(
  params: URLSearchParams,
  filter: IAssistanceAddressListFilter
) {
  if (filter.categoryId > 0) {
    params.append("categoryId", filter.categoryId.toString());
  }

  if (filter.careTypeId > 0) {
    params.append("careTypeId", filter.careTypeId.toString());
  }

  if (filter.targetGroupId > 0) {
    params.append("targetGroupId", filter.targetGroupId.toString());
  }
}
