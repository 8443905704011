import { createAction } from "@reduxjs/toolkit";
import { IMapPoint } from "../../models/map";
import { StoreState } from "../storeState";
import { AssistanceAddressMapTypes } from "./types";

export const assistanceAddressMapFetchRequest = createAction<
  void,
  AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_FETCH_REQUEST
>(AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_FETCH_REQUEST);

export const assistanceAddressMapFetchSuccess = createAction<
  IMapPoint[],
  AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_FETCH_SUCCESS
>(AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_FETCH_SUCCESS);

export const assistanceAddressMapFetchError = createAction<
  boolean,
  AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_FETCH_ERROR
>(AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_FETCH_ERROR);

export const assistanceAddressMapChangeFilter = createAction<
  void,
  AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_CHANGE_FILTER
>(AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_CHANGE_FILTER);

export const assistanceAddressMapReset = createAction<
  void,
  AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_RESET
>(AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_RESET);

export const assistanceAddressMapLoadAction = createAction<
  StoreState,
  AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_LOAD
>(AssistanceAddressMapTypes.ASSISTANCE_ADDRESS_MAP_LOAD);
