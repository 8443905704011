import { FC, useEffect, useRef, useState } from "react";
import { DEBOUNCE_TIMEOUT_MS } from "../../../utils/consts";

interface IProps {
  values: any;
  isValid: boolean;
  onChange(): void;
}

const FilterPanelExtended: FC<IProps> = ({ values, isValid, onChange }) => {
  const [firstTime, setFirstTime] = useState(true);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (!isValid) {
      return;
    }

    timeout.current = setTimeout(() => onChange(), DEBOUNCE_TIMEOUT_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid]);

  return null;
};

export default FilterPanelExtended;
