import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  IAssistanceAddress,
  IAssistanceAddressFilters,
  IAssistanceAddressItem,
} from "../models/assistance";
import { IMapPoint } from "../models/map";
import api from "../utils/api";

const getAssistanceAddressFilters = async (): Promise<
  AxiosResponse<IAssistanceAddressFilters>
> => api.get(`/api/Public/AssistanceAddress/Filters`);

const getAssistanceAddressMap = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IMapPoint[]>> =>
  api.get(`/api/Public/AssistanceAddress/Map`, config);

const getAssistanceAddressList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IAssistanceAddressItem[]>> =>
  api.get(`/api/Public/AssistanceAddress`, config);

const getAssistanceAddressCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Public/AssistanceAddress/Count`, config);

const getAssistanceAddress = async (
  id: number
): Promise<AxiosResponse<IAssistanceAddress>> =>
  api.get(`/api/Public/AssistanceAddress/${id}`);

const assistanceApi = {
  getAssistanceAddressFilters,
  getAssistanceAddressMap,
  getAssistanceAddressList,
  getAssistanceAddressCount,
  getAssistanceAddress,
};

export default assistanceApi;
