export enum DemandStateType {
  New = "new",
  Finished = "finished",
  Canceled = "canceled",
}

export enum DemandPriorityType {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export interface IDemandItem {
  id: number;
  name?: string | null;
  state: DemandStateType;
  priority: DemandPriorityType;
  assignedName?: string | null;
}

export interface IDemand {
  id: number;
  name?: string | null;
  state: DemandStateType;
  priority: DemandPriorityType;
  description?: string | null;

  assignedUserId?: number | null;
  assignedUserName?: string | null;

  assignedGroupId?: number | null;
  assignedGroupName?: string | null;
}
