import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { selectClientCareList } from ".";
import { IFilterType } from "../filterType";
import { CancelToken } from "axios";
import {
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getClientCareList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientCareList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientCareListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);

      const cares = await clientApi.getClientCareList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, cares);

      dispatch(
        actions.clientCareListFetchSuccess({
          data: cares.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.clientCareListFetchError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function getClientCareListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientCareList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientCareListCountRequest());
    try {
      const count = await clientApi.getClientCareCount({
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientCareListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.clientCareListCountError(cancelToken.reason !== undefined)
      );
    }
  };
}

export function changeClientCareListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientCareListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientCareListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientCareListChangePage(page));
  };
}

export function changeClientCareListFilter(
  filter: IFilterType
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientCareListChangeFilter(filter));
  };
}

export function clientCareListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientCareList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientCareListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientCareListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}
