import { FC } from "react";
import { ErrorMessage } from "formik";
import { FormErrorContainer, TooltipContent } from "./FormErrorStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../styles/colors";

const FormError: FC<{ name: string; tooltipLeft?: boolean }> = ({
  name,
  tooltipLeft,
}) => {
  return (
    <FormErrorContainer>
      <FontAwesomeIcon icon={faTimes} color={COLORS.errorIconColor} />
      <TooltipContent left={tooltipLeft}>
        <ErrorMessage name={name} />
      </TooltipContent>
    </FormErrorContainer>
  );
};

export default FormError;
