import { AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";
import { StoreState } from "../storeState";
import { selectAssistanceAddressFiltersState } from ".";

export function getAssistanceAddressFilters(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistanceAddressFiltersState(getState());
    if (state !== StoreState.None) {
      return;
    }

    dispatch(actions.assistanceAddressFiltersFetchRequest());
    try {
      const filters = await assistanceApi.getAssistanceAddressFilters();
      dispatch(actions.assistanceAddressFiltersFetchSuccess(filters.data));
    } catch {
      dispatch(actions.assistanceAddressFiltersFetchError());
    }
  };
}
