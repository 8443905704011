import { addMinutes } from "date-fns";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import authApi from "../../api/auth";
import { ILoginData, LoginResponseStatusType } from "../../models/auth";
import storageApi from "../../utils/storage";
import * as Yup from "yup";
import { connect } from "react-redux";
import { getClient } from "../../store/client";
import { LoginButton, LoginCardActionsRight } from "./styles";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import validations from "../../utils/validations";
import { promiseToast } from "../../utils/toasts";
import { SetState } from "../../utils/types";

const initialValues: ILoginData = {
  login: "",
  pass: "",
};

interface IProps {
  setTwoFactorToken: SetState<string>;
  getClient(): void;
}

const LoginFirst: FC<IProps> = ({ setTwoFactorToken, getClient }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginResponseStatus, setLoginResponseStatus] =
    useState<LoginResponseStatusType>(LoginResponseStatusType.None);

  const handleSubmit = async (data: ILoginData) => {
    try {
      await promiseToast(
        async () => {
          setLoginResponseStatus(LoginResponseStatusType.None);
          let response;
          try {
            response = await authApi.login(data);
          } catch {
            setLoginResponseStatus(LoginResponseStatusType.Error);
            throw new Error();
          }

          if (response.data.status === LoginResponseStatusType.TwoFactor) {
            setTwoFactorToken(response.data.token ?? "");
            return;
          }

          if (response.data.status !== LoginResponseStatusType.Ok) {
            setLoginResponseStatus(response.data.status);
            throw new Error();
          }

          storageApi.setToken(response.data.token ?? "");
          storageApi.setIdleMinutes(response.data.userMaxIdleMinutes ?? 0);
          storageApi.setTokenRefreshTimeout(
            addMinutes(new Date(), response.data.userMaxIdleMinutes ?? 0)
          );
          storageApi.setTokenTimeout(
            addMinutes(new Date(), response.data.tokenValidMinutes ?? 0)
          );

          getClient();

          if (location.pathname === "/login") {
            navigate("/");
          }
        },
        t("pending.login"),
        t("success.login"),
        t("errors.login")
      );
    } catch {
      //Nothing.
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          login: validations.stringRequired(t),
          pass: validations.stringRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <FormGroup labelWidth="7rem">
              <Input
                name="login"
                label={t("login.login")}
                error={touched.login && !!errors.login}
                autoComplete="username"
              />
              <Input
                type="password"
                name="pass"
                label={t("login.pass")}
                error={touched.pass && !!errors.pass}
                autoComplete="current-password"
              />
              {loginResponseStatus ===
                LoginResponseStatusType.BadLoginOrPass && (
                <ApiError>{t("login.badLoginOrPass")}</ApiError>
              )}
              {loginResponseStatus ===
                LoginResponseStatusType.TooMuchAttempts && (
                <ApiError>{t("login.tooMuchAttempts")}</ApiError>
              )}
              {loginResponseStatus === LoginResponseStatusType.Expired && (
                <ApiError>{t("login.expired")}</ApiError>
              )}
              {loginResponseStatus === LoginResponseStatusType.Error && (
                <ApiError>{t("errors.unknown")}</ApiError>
              )}
            </FormGroup>
            <LoginButton type="submit" disabled={isSubmitting}>
              {t("login.submit")}
            </LoginButton>
          </Form>
        )}
      </Formik>
      <LoginCardActionsRight>
        <Link to="/forgotten-password">{t("login.forgottenPassword")}</Link>
      </LoginCardActionsRight>
    </>
  );
};

const mapDispachToProps = {
  getClient,
};

export default connect(null, mapDispachToProps)(LoginFirst);
