import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const ModalMain = styled.div<{
  isBig?: boolean;
}>`
  .react-responsive-modal-container {
    overflow-x: auto;
  }

  .react-responsive-modal-modal {
    overflow-y: visible;

    ${({ isBig }) =>
      isBig && "min-height: 80vh; min-width: 80vw; max-width: none;"}
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 3rem 1rem 1rem;

  background-color: var(--header-bg);
  color: var(--background);
  font-size: 1.2rem;
`;

export const ModalCloseButton = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: var(--background);
  cursor: pointer;
`;

export const ModalContent = styled.div`
  position: relative;
  z-index: 0;
  padding: 2rem;
  background-color: var(--background);
`;

export const ModalActions = styled.div<{
  isBig?: boolean;
}>`
  width: 100%;

  padding: 0 1rem 1rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  ${({ isBig }) => isBig && "position: absolute; bottom: 0;"}
`;
