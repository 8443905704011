import { AxiosResponse, CancelToken } from "axios";
import listingApi from "../api/listing";
import { ISuggestionValue } from "../components/common/suggestion/Suggestion";
import { IItemIdName } from "../models/common";
import { SUGGESTION_SIZE } from "./consts";

const getSuggestionParams = (search?: string | null) => {
  const params = new URLSearchParams();
  params.append("count", SUGGESTION_SIZE.toString());

  if (search) {
    params.append("search", search);
  }

  return params;
};

const responseMap = (response: AxiosResponse<IItemIdName[]>) => {
  return response.data.map((x) => ({
    id: x.id,
    text: x.name,
  }));
};

export const handleLoadClientContactPersonListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await listingApi.getSuggestionClientContactPersonList({
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadEmptySuggestions = async (): Promise<
  ISuggestionValue[]
> => {
  return [];
};
