export const COLORS = {
  background: "#ffffff",

  headerBg: "#324ad9",
  headerBgHover: "#324ad9",
  headerBgActive: "#324ad9",
  headerColor: "#e5e5e5",
  headerColorHover: "#13aff0",
  headerColorActive: "#13aff0",

  menuBg: "#06132b",
  menuBgHover: "#446777",
  menuBgActive: "#446777",
  menuShadow: "#3b3b3b",
  menuColor: "#e5e5e5",
  menuColorHover: "#929292",
  menuColorActive: "#929292",

  sidebarBg: "#324ad9",
  sidebarBgHover: "#2235a1",
  sidebarBgActive: "#2235a1",
  sidebarColor: "#e5e5e5",
  sidebarColorHover: "#e5e5e5",
  sidebarColorActive: "#e5e5e5",

  topPanelBg: "#0e2a5e",
  topPanelBorder: "#4eadff",

  tableHeaderBg: "#a8a8a8",
  tableHeaderColor: "#ffffff",
  tableHeaderColorHover: "#686868",
  tableBodyBg: "#ffffff",
  tableBodyBgHover: "#fbf5ee",
  tableBodyBorder: "#a8a8a8",
  tableBodyColorHover: "#3b3b3b",
  tablePgBgActive: "#007bff",
  tablePgBgHover: "#f5f5f5",
  tablePgColorActive: "#ffffff",
  tableOrderColor: "#000000",
  tableOrderColorHover: "#4cc2f1",
  tableOrderColorActive: "#4cc2f1",

  titleColor: "#3b3b3b",
  textColor: "#3b3b3b",
  loaderColor: "#2362a2",
  errorColor: "#e3444c",
  warningColor: "#ecae1a",
  successColor: "#61bd5b",
  selectedColor: "#ffffff",
  disabledColor: "#688cb1",

  border: "#9f9f9f",
  borderFocus: "#4cc2f1",
  borderFocusShadow: "#4cc2f1",
  borderError: "#e3444c",

  panelBg: "#f5f5f5",
  selectedBg: "#3077b7",
  disabledBg: "#f2f5f9",

  formIconColor: "#686868",
  formIconColorHover: "#3b3b3b",
  errorIconColor: "#ffffff",
  successIconColor: "#61bd5b",

  buttonPrimaryBg: "#06132b",
  buttonPrimaryBgHover: "#446777",
  buttonPrimaryBorder: "#06132b",
  buttonPrimaryBorderHover: "#446777",
  buttonPrimaryColor: "#ffffff",
  buttonPrimaryColorHover: "#ffffff",
  buttonSecondaryBg: "#ffffff",
  buttonSecondaryBgHover: "#92a6b0",
  buttonSecondaryBorder: "#06132b",
  buttonSecondaryBorderHover: "#92a6b0",
  buttonSecondaryColor: "#06132b",
  buttonSecondaryColorHover: "#ffffff",
  buttonDisabledBg: "#ffffff",
  buttonDisabledBorder: "#cdcfd3",
  buttonDisabledColor: "#cdcfd3",

  calendarBorder: "#a8a8a8",
  calendarAvailableColor: "#ffffff",
  calendarAvailableBg: "#3077b7",
  calendarAvailableHover: "#254e80",
  calendarUnavailableColor: "#ffffff",
  calendarUnavailableBg: "#ed5959",
  calendarUnavailableHover: "#254e80",
  calendarActualDayBg: "#3077b7",
  calendarButtonsColor: "#ffffff",
  calendarButtonsBg: "#3077b7",
  calendarDateBorder: "#b0b0b0",

  suggestionItemBg: "#0e2a5e",
  suggestionItemBgHover: "#4cc2f1",

  toastSuccessBg: "#61bd5b",
  toastSuccessColor: "#ffffff",
  toastWarningBg: "#ecae1a",
  toastWarningColor: "#ffffff",
  toastErrorBg: "#e3444c",
  toastErrorColor: "#ffffff",

  suggestionHighlightedBg: "#d3dfec",

  hrColor: "#cdcfd3",

  transparent: "transparent",
};
