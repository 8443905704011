import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { IClient } from "../../models/client";
import { StoreState } from "../storeState";
import { selectClientState } from ".";

export function getClient(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientState(getState());
    if (state !== StoreState.None) {
      return;
    }

    dispatch(actions.clientFetchRequest());
    try {
      const client = await clientApi.getClient();
      dispatch(actions.clientFetchSuccess(client.data));
    } catch {
      dispatch(actions.clientFetchError());
    }
  };
}

export function updateClient(newData: IClient): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientFetchSuccess(newData));
  };
}
