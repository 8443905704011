import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IClientCare } from "../../models/client";
import { StoreState } from "../storeState";

export const selectClientCareState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.clientCare.state;

export const selectClientCare: ReselectModule.Selector<
  IApplicationState,
  IClientCare | null
> = (state) => state.clientCare.data;
