import styled from "styled-components";

const RequiredMarkStyle = styled.span`
  color: var(--error-color);
  margin-left: 0.3rem;
  font-size: 1.3rem;
  max-height: 1rem;
`;
const RequiredMark = () => <RequiredMarkStyle>*</RequiredMarkStyle>;

export default RequiredMark;
