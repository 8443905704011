import { createReducer } from "@reduxjs/toolkit";
import {
  IClientDocumentListFilterType,
  IClientDocumentListState,
} from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "Name",
  orderDesc: false,
  filter: { search: undefined, isOpen: false } as IClientDocumentListFilterType,
} as IClientDocumentListState;

export const clientDocumentListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.clientDocumentListFetchRequest, (state, action) => ({
        ...state,
        state: StoreState.Loading,
        countState: action.payload,
      }))
      .addCase(actions.clientDocumentListFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload.data,
        countState: action.payload.newCountState,
        count: action.payload.newCount,
      }))
      .addCase(actions.clientDocumentListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.clientDocumentListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(actions.clientDocumentListCountSuccess, (state, action) => ({
        ...state,
        countState: StoreStateDontLoad.Loaded,
        count: action.payload,
      }))
      .addCase(actions.clientDocumentListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.clientDocumentListChangeOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
      .addCase(actions.clientDocumentListChangePage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(actions.clientDocumentListChangeFilter, (state, action) => ({
        ...state,
        state: StoreState.None,
        countState: StoreStateDontLoad.DontLoad,
        filter: action.payload,
        page: 0,
      }))
      .addCase(actions.clientDocumentListReset, (state) => ({
        ...initialState,
        filter: state.filter,
      }))
      .addCase(actions.clientDocumentListLoadAction, (state, action) => ({
        ...state,
        state: action.payload.newState,
        countState: action.payload.newCountState,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
