import { createReducer } from "@reduxjs/toolkit";
import { IPasswordStrengthState } from "./types";
import * as actions from "./actions";
import { StoreState } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  data: null,
  state: StoreState.None,
} as IPasswordStrengthState;

export const passwordStrengthReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.passwordStrengthFetchRequest, (state) => ({
      ...state,
      state: StoreState.Loading,
    }))
    .addCase(actions.passwordStrengthFetchSuccess, (state, action) => ({
      ...state,
      state: StoreState.Loaded,
      data: action.payload,
    }))
    .addCase(actions.passwordStrengthFetchError, (state) => ({
      ...state,
      state: StoreState.Error,
    }))
    .addCase(STORAGE_RESET, () => initialState)
);
