import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { IClientContactPerson } from "../../models/client";
import { IApplicationState } from "../../store";
import {
  changeClientContactPersonListOrder,
  changeClientContactPersonListPage,
  getClientContactPersonList,
  getClientContactPersonListCount,
  IClientContactPersonListState,
  selectClientContactPersonList,
  clientContactPersonListLoad,
} from "../../store/clientContactPersonList";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import formatters from "../../utils/formatters";
import { useTranslation } from "react-i18next";

interface IProps {
  prov: IClientContactPersonListState;
  getClientContactPersonList(cancelToken: CancelToken): void;
  getClientContactPersonListCount(cancelToken: CancelToken): void;
  changeClientContactPersonListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientContactPersonListPage(page: number): void;
  clientContactPersonListLoad(reload: boolean): void;
}

const ClientPerson: FC<IProps> = ({
  prov,
  getClientContactPersonList,
  getClientContactPersonListCount,
  changeClientContactPersonListOrder,
  changeClientContactPersonListPage,
  clientContactPersonListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    clientContactPersonListLoad(false);
  }, [clientContactPersonListLoad]);

  const handleRenderData = (item: IClientContactPerson): ReactElement => {
    return (
      <>
        <TableCol>{item.type.name}</TableCol>
        <TableCol>{formatters.fullName(item)}</TableCol>
        <TableCol>{item.mobilePhone}</TableCol>
        <TableCol>{item.phone}</TableCol>
        <TableCol>{item.email}</TableCol>
        <TableCol>
          <NavLink to={`/person/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IClientContactPerson) => {
    navigate(`/person/${item.id}`);
  };

  return (
    <>
      <h1>{t("client.person.pageTitle")}</h1>
      <Grid<IClientContactPerson>
        headers={[
          { captionStr: "client.person.type" },
          { captionStr: "client.person.name" },
          { captionStr: "client.person.mobilePhone" },
          { captionStr: "client.person.phone" },
          { captionStr: "client.person.email" },
          {
            captionEl: (
              <NavLink to={`/person/new`}>
                <GridIconAdd />
              </NavLink>
            ),
          },
        ]}
        renderData={handleRenderData}
        getData={getClientContactPersonList}
        getCount={getClientContactPersonListCount}
        changeOrder={changeClientContactPersonListOrder}
        changePage={changeClientContactPersonListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientContactPersonList(state),
  };
};

const mapDispachToProps = {
  getClientContactPersonList,
  getClientContactPersonListCount,
  changeClientContactPersonListOrder,
  changeClientContactPersonListPage,
  clientContactPersonListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientPerson);
