import { FC } from "react";
import styled from "styled-components";

export const TableColContainer = styled.div`
  display: flex;
`;

export const TableCol: FC = ({ children }) => {
  return (
    <td>
      <TableColContainer>{children}</TableColContainer>
    </td>
  );
};
