import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";

export function getClientFolder(folderId: string): AppThunkActionAsync {
  return async (dispatch, getState) => {
    dispatch(actions.clientFolderFetchRequest());
    try {
      const folder = await clientApi.getClientFolder(folderId);
      dispatch(actions.clientFolderFetchSuccess(folder.data));
    } catch {
      dispatch(actions.clientFolderFetchError());
    }
  };
}

export function getDefaultClientFolder(): AppThunkAction {
  return (dispatch) => {
    dispatch(actions.clientFolderFetchSuccess([]));
  };
}
