import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { rgba } from "polished";
import { InputContainer } from "../../../styles/form";
import { COLORS } from "../../../styles/colors";
import { SIZES } from "../../../styles/sizes";
import { textInput } from "../../../styles/form";

export const DatePickerWrapper = styled(InputContainer)`
  & .react-datepicker {
    border: 1px solid var(--panel-bg);
    border-radius: 0;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  }

  & .react-datepicker__triangle {
    border-bottom-color: var(--background) !important;

    &::before {
      border-bottom-color: var(--panel-bg) !important;
    }
  }

  & .react-datepicker__navigation--previous {
    border-right-color: var(--button-primary-bg);
  }

  & .react-datepicker__navigation--next {
    border-left-color: var(--button-primary-bg);
  }

  & .react-datepicker__header {
    background-color: var(--transparent);
    border-bottom: 1px solid var(--panel-bg);
  }

  & .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: var(--text-color);
  }

  & .react-datepicker__day-name {
    font-weight: bold;
    color: var(--text-color);
  }

  & .react-datepicker__day {
    border-radius: 0.3rem;
    color: var(--text-color);

    &:hover {
      border-radius: 50%;
      background-color: var(--panel-bg);
      transition: background-color 0.2s ease-in-out;
    }
  }

  & .react-datepicker__day--selected {
    border-radius: 0.3rem;
    background-color: var(--button-primary-bg);
    color: var(--background);

    &:hover {
      border-radius: 0.3rem;
      background-color: ${rgba(COLORS.buttonPrimaryBg, 0.85)};
    }
  }

  & .react-datepicker__day--disabled {
    background-color: var(--panel-bg);
    color: var(--disabled-color);
    outline: none;

    &:hover {
      border-radius: 0.3rem;
      background-color: var(--panel-bg);
    }
  }

  & .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: ${rgba(COLORS.buttonPrimaryBg, 0.7)};
    color: var(--background);

    &:hover {
      border-radius: 0.3rem;
      background-color: ${rgba(COLORS.buttonPrimaryBg, 0.85)};
    }
  }

  & .react-datepicker__time-container {
    border-left: 1px solid var(--panel-bg);
  }

  & .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);

    &:hover {
      background-color: var(--panel-bg);
      transition: background-color 0.2s ease-in-out;
    }
  }

  & .react-datepicker__time-list-item--selected {
    background-color: var(--button-primary-bg) !important;

    &:hover {
      background-color: ${rgba(COLORS.buttonPrimaryBg, 0.85)} !important;
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)<{
  $error?: boolean;
  width?: string;
}>`
  ${(props) => textInput(props)}

  @media screen and (min-width: ${SIZES.mobile}) {
    padding-left: 2.75rem;
  }
`;

export const DatePickerIconWrapper = styled.div`
  display: none;
  position: absolute;
  padding: 0 1rem;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: ${SIZES.mobile}) {
    display: block;
  }
`;
