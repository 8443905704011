export const PAGE_SIZE = 10;
export const SUGGESTION_SIZE = 10;
export const DEBOUNCE_TIMEOUT_MS = 500;
export const CACHE_MINUTES = 5;
export const DATE_FORMAT = "dd.MM.yyyy";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "dd.MM.yyyy HH:mm";

export const TOAST_AUTOCLOSE_DEFAULT = 3000;
export const TOAST_AUTOCLOSE_LONGER = 5000;
