import { FC } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MapComponent from "./MapComponent";
import { IMapPoint } from "../../../models/map";
import { ApiError } from "../../../styles/form";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../../styles/colors";

interface IProps {
  loading: boolean;
  error: boolean;
  points: IMapPoint[];
  markerClick(point: IMapPoint): void;
}

const MapWrapper: FC<IProps> = ({ loading, error, points, markerClick }) => {
  const { t } = useTranslation();

  const render = (status: Status) => {
    if (loading || status === Status.LOADING) {
      return <TailSpin color={COLORS.loaderColor} width={48} height={48} />;
    }

    if (error || status === Status.FAILURE) {
      return <ApiError>{t("errors.unknown")}</ApiError>;
    }

    return <MapComponent points={points} markerClick={markerClick} />;
  };

  return <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY!} render={render} />;
};

export default MapWrapper;
