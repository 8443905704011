import { createAction } from "@reduxjs/toolkit";
import { IClient } from "../../models/client";
import { ClientTypes } from "./types";

export const clientFetchRequest = createAction<
  void,
  ClientTypes.CLIENT_FETCH_REQUEST
>(ClientTypes.CLIENT_FETCH_REQUEST);

export const clientFetchSuccess = createAction<
  IClient,
  ClientTypes.CLIENT_FETCH_SUCCESS
>(ClientTypes.CLIENT_FETCH_SUCCESS);

export const clientFetchError = createAction<
  void,
  ClientTypes.CLIENT_FETCH_ERROR
>(ClientTypes.CLIENT_FETCH_ERROR);
