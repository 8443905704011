import styled from "styled-components";

export const PhoneInputWrapper = styled.div<{ error?: boolean }>`
  width: 100%;
  display: flex;

  input {
    height: 2.5rem !important;
    width: 100% !important;
    border: 1px solid
      ${({ error }) => (error ? "var(--border-error)" : "var(--border)")} !important;
    border-radius: 3px !important;
    outline: none !important;
    color: var(--text-color) !important;
    font-size: 1rem !important;
  }
`;
