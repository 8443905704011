import styled from "styled-components";

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const SearchInput = styled.input`
  height: 2.5rem;
  width: 100%;

  padding: 0.5rem 2.75rem 0.5rem 1rem;

  border: 1px solid var(--border);
  border-radius: 1.25rem;
  outline: none;
  color: var(--text-color);
  font-size: 1rem;

  transition: all 0.15s ease-in-out;

  &:focus {
    border-color: var(--border-focus);
    box-shadow: 0 0 3px 0 var(--border-focus-shadow);
  }

  &:disabled {
    background-color: var(--disabled-bg) !important;
    color: var(--disabled-color) !important;
  }
`;

export const SearchIconWrapper = styled.div`
  padding: 0 1rem;
  margin-left: -3rem;
  margin-top: 0.65rem;
`;
