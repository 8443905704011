import { createAction } from "@reduxjs/toolkit";
import { IFolderItem } from "../../models/document";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { IClientFolderListFilter, ClientFolderListTypes } from "./types";

export const clientFolderListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_FETCH_REQUEST
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_FETCH_REQUEST);

export const clientFolderListFetchSuccess = createAction<
  {
    data: IFolderItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientFolderListTypes.CLIENT_FOLDER_LIST_FETCH_SUCCESS
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_FETCH_SUCCESS);

export const clientFolderListFetchError = createAction<
  boolean,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_FETCH_ERROR
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_FETCH_ERROR);

export const clientFolderListCountRequest = createAction<
  void,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_COUNT_REQUEST
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_COUNT_REQUEST);

export const clientFolderListCountSuccess = createAction<
  number,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_COUNT_SUCCESS
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_COUNT_SUCCESS);

export const clientFolderListCountError = createAction<
  boolean,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_COUNT_ERROR
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_COUNT_ERROR);

export const clientFolderListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientFolderListTypes.CLIENT_FOLDER_LIST_CHANGE_ORDER
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_CHANGE_ORDER);

export const clientFolderListChangePage = createAction<
  number,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_CHANGE_PAGE
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_CHANGE_PAGE);

export const clientFolderListChangeFilter = createAction<
  IClientFolderListFilter,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_CHANGE_FILTER
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_CHANGE_FILTER);

export const clientFolderListReset = createAction<
  void,
  ClientFolderListTypes.CLIENT_FOLDER_LIST_RESET
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_RESET);

export const clientFolderListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientFolderListTypes.CLIENT_FOLDER_LIST_LOAD
>(ClientFolderListTypes.CLIENT_FOLDER_LIST_LOAD);
