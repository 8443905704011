import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const Button = styled.button<{
  ver?: "primary" | "secondary";
  $small?: boolean;
}>`
  padding: ${({ $small }) => ($small ? "0.5rem 0.75rem" : "8px 35px")};
  background-color: ${({ ver }) =>
    ver === "secondary"
      ? "var(--button-secondary-bg)"
      : "var(--button-primary-bg)"};
  color: ${({ ver }) =>
    ver === "secondary"
      ? "var(--button-secondary-color)"
      : "var(--button-primary-color)"} !important;

  outline: none;
  border: 1px solid
    ${({ ver }) =>
      ver === "secondary"
        ? "var(--button-secondary-border)"
        : "var(--button-primary-border)"};
  border-radius: 100px;

  line-height: 1.5;
  font-size: ${({ $small }) => ($small ? "0.8rem" : "0.9rem")};
  text-decoration: none;
  white-space: pre;
  text-transform: uppercase;

  svg {
    margin-right: 0.5rem;
  }

  &:not(:disabled) {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${({ ver }) =>
      ver === "secondary"
        ? "var(--button-secondary-bg-hover)"
        : "var(--button-primary-bg-hover)"};
    color: ${({ ver }) =>
      ver === "secondary"
        ? "var(--button-secondary-color-hover)"
        : "var(--button-primary-color-hover)"} !important;
    border: 1px solid
      ${({ ver }) =>
        ver === "secondary"
          ? "var(--button-secondary-border-hover)"
          : "var(--button-primary-border-hover)"} !important;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled,
  &:disabled &:focus,
  &:disabled &:active {
    background-color: var(--button-disabled-bg) !important;
    color: var(--button-disabled-color) !important;
    border: 1px solid var(--button-disabled-border) !important;

    &:hover {
      cursor: initial;
    }
  }
`;

export const FormButton = styled(Button)`
  margin-top: 1.5rem;
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.15rem;
`;

export const FontAwesomeButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
