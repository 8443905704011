import { IClientCare } from "../../models/client";
import { StoreState } from "../storeState";

export enum ClientCareTypes {
  CLIENT_CARE_FETCH_REQUEST = "@@clientCare/FETCH_REQUEST",
  CLIENT_CARE_FETCH_SUCCESS = "@@clientCare/FETCH_SUCCESS",
  CLIENT_CARE_FETCH_ERROR = "@@clientCare/FETCH_ERROR",
}

export interface IClientCareState {
  state: StoreState;
  data: IClientCare | null;
}
