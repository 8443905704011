import { IClientContactPerson } from "../../models/client";
import { StoreState } from "../storeState";

export enum ClientContactPersonTypes {
  CLIENT_CONTACT_PERSON_FETCH_REQUEST = "@@clientContactPerson/FETCH_REQUEST",
  CLIENT_CONTACT_PERSON_FETCH_SUCCESS = "@@clientContactPerson/FETCH_SUCCESS",
  CLIENT_CONTACT_PERSON_FETCH_ERROR = "@@clientContactPerson/FETCH_ERROR",
}

export interface IClientContactPersonState {
  state: StoreState;
  data: IClientContactPerson | null;
}
