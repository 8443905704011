import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const HeaderMenuContainer = styled.div<{
  $open: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: scroll;
  z-index: 100000;
  align-items: center;
  text-align: center;

  display: ${({ $open }) => ($open ? "grid" : "none")};

  svg {
    transition: all 0.15s ease-in-out;
    margin-right: 1rem;
  }
`;

export const HeaderMenuCloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
  background-color: var(--transparent);
  border: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  div {
    transform: rotate(45deg);
    display: inline-block;
    width: 2.5rem;
    height: 3px;
    background-color: var(--menu-color);
    border-radius: 3px;
  }

  div:after {
    content: "";
    display: block;
    transform: rotate(-90deg);
    width: 2.5rem;
    height: 3px;
    background-color: var(--menu-color);
    border-radius: 3px;
  }

  &:hover {
    div {
      background-color: var(--menu-color-hover);
    }

    div:after {
      background-color: var(--menu-color-hover);
    }
  }
`;

export const HeaderMenuLinks = styled.ul`
  display: block;

  a {
    text-decoration: none;
  }

  a: hover {
    color: var(--menu-color-hover);

    svg {
      color: var(--menu-color-hover);
    }
  }

  a.active {
    color: var(--menu-color-active);

    svg {
      color: var(--menu-color-active);
    }
  }
`;

export const HeaderMenuNavLink = styled(NavLink)`
  display: block;
  height: 100%;
  width: 100%;
  padding: 1rem 0;

  text-transform: uppercase;
  color: var(--menu-color);
  font-family: Fira Sans;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 1px;
  transition: all 0.15s ease-in-out;
`;
