import { FC } from "react";
import { ISidebarLink } from "../../models/common";
import { SetState } from "../../utils/types";
import {
  HeaderMenuNavLink,
  HeaderMenuLinks,
  HeaderMenuContainer,
  HeaderMenuCloseButton,
} from "./HeaderMenuStyles";

interface IProps {
  open: boolean;
  sidebarLinks: ISidebarLink[] | undefined;
  setOpen: SetState<boolean>;
}

const HeaderMenu: FC<IProps> = ({ open, sidebarLinks, setOpen }) => {
  return (
    <HeaderMenuContainer $open={open}>
      <HeaderMenuCloseButton onClick={() => setOpen(false)}>
        <div />
      </HeaderMenuCloseButton>
      <HeaderMenuLinks>
        {sidebarLinks?.map((l) => (
          <li key={l.key} onClick={() => setOpen(false)}>
            <HeaderMenuNavLink to={l.to}>{l.text}</HeaderMenuNavLink>
          </li>
        ))}
      </HeaderMenuLinks>
    </HeaderMenuContainer>
  );
};

export default HeaderMenu;
