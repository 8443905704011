import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  ClientHouseLivingType,
  ClientHouseOwnershipType,
  IClientHouse,
} from "../../models/client";
import { IApplicationState } from "../../store";
import { FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import * as Yup from "yup";
import { StoreState } from "../../store/storeState";
import Loader from "../common/Loader";
import {
  getClientHouse,
  selectClientHouse,
  selectClientHouseState,
} from "../../store/clientHouse";
import validations from "../../utils/validations";
import { H1WithMargin } from "../../styles/text";

interface IProps {
  clientHouseState: StoreState;
  clientHouse: IClientHouse | null;
  getClientHouse(): void;
}

const fields: Array<{ name: keyof IClientHouse; maxLen?: number }> = [
  { name: "floorNumber" },
  { name: "conditions", maxLen: 512 },
  { name: "barriers", maxLen: 256 },
  { name: "personsInHousehold" },
  { name: "livingLength" },
  { name: "costPerMonth" },
];

const numberFields: Array<keyof IClientHouse> = [
  "floorNumber",
  "personsInHousehold",
  "livingLength",
  "costPerMonth",
];

const ClientHouse: FC<IProps> = ({
  clientHouseState,
  clientHouse,
  getClientHouse,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getClientHouse();
    setLoading(false);
  }, [getClientHouse]);

  const handleSubmit = () => {};

  if (loading || clientHouseState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientHouseState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IClientHouse>
      initialValues={{
        ...clientHouse!,
        livingType: clientHouse!.livingType ?? "",
        ownershipType: clientHouse!.ownershipType ?? "",
        floorNumber: clientHouse!.floorNumber ?? 0,
        conditions: clientHouse!.conditions ?? "",
        barriers: clientHouse!.barriers ?? "",
        personsInHousehold: clientHouse!.personsInHousehold ?? 0,
        livingLength: clientHouse!.livingLength ?? 0,
        costPerMonth: clientHouse!.costPerMonth ?? 0,
        termination: clientHouse!.termination ?? false,
      }}
      validationSchema={Yup.object({
        floorNumber: validations.intOptionalMinMax(0, 99, t),
        personsInHousehold: validations.intOptionalMinMax(0, 99, t),
        livingLength: validations.intOptionalMinMax(0, 999999, t),
        costPerMonth: validations.numberOptionalMinMax(0, 999999, t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      <Form>
        <H1WithMargin>{t("client.house.detailTitle")}</H1WithMargin>
        <FormGroup>
          <Input
            key="livingType"
            name="livingType"
            label={t("client.house.livingType")}
            component="select"
            disabled={true}
          >
            <option value="">{t("client.house.livingTypes.null")}</option>
            {(
              Object.keys(ClientHouseLivingType) as Array<
                keyof typeof ClientHouseLivingType
              >
            ).map((key) => (
              <option key={key} value={ClientHouseLivingType[key]}>
                {t("client.house.livingTypes." + ClientHouseLivingType[key])}
              </option>
            ))}
          </Input>
          <Input
            key="ownershipType"
            name="ownershipType"
            label={t("client.house.ownershipType")}
            component="select"
            disabled={true}
          >
            <option value="">{t("client.house.ownershipTypes.null")}</option>
            {(
              Object.keys(ClientHouseOwnershipType) as Array<
                keyof typeof ClientHouseOwnershipType
              >
            ).map((key) => (
              <option key={key} value={ClientHouseOwnershipType[key]}>
                {t(
                  "client.house.ownershipTypes." + ClientHouseOwnershipType[key]
                )}
              </option>
            ))}
          </Input>
          {fields.map((f) => (
            <Input
              key={f.name}
              name={f.name}
              type={numberFields.find((x) => x === f.name) ? "number" : "text"}
              label={t("client.house." + f.name)}
              maxLength={f.maxLen}
              disabled={true}
            />
          ))}
          <Input
            key="termination"
            name="termination"
            type="checkbox"
            label={t("client.house.termination")}
            inputWidth="1.5rem"
            inputHeight="1.5rem"
            disabled={true}
          />
        </FormGroup>
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientHouseState: selectClientHouseState(state),
    clientHouse: selectClientHouse(state),
  };
};

const mapDispachToProps = {
  getClientHouse,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientHouse);
