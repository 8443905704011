import { IClientHouse } from "../../models/client";
import { StoreState } from "../storeState";

export enum ClientHouseTypes {
  CLIENT_HOUSE_FETCH_REQUEST = "@@clientHouse/FETCH_REQUEST",
  CLIENT_HOUSE_FETCH_SUCCESS = "@@clientHouse/FETCH_SUCCESS",
  CLIENT_HOUSE_FETCH_ERROR = "@@clientHouse/FETCH_ERROR",
  CLIENT_HOUSE_RESET = "@@clientHouse/RESET",
}

export interface IClientHouseState {
  state: StoreState;
  data: IClientHouse | null;
}
