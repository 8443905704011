import { FC, ReactNode } from "react";
import storageApi from "../../utils/storage";
import Login from "./Login";

interface IProps {
  children?: ReactNode;
}

const AuthRoute: FC<IProps> = ({ children }) => {
  const tokenIsValid = storageApi.getTokenIsValid();

  if (!tokenIsValid) {
    return <Login />;
  }

  return <>{children}</>;
};

export default AuthRoute;
