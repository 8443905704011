import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LoginCard, LoginContainer } from "./styles";
import { storageReset } from "../../store/storeReset";
import { SmallPage } from "../layout/LayoutStyles";
import LoginFirst from "./LoginFirst";
import LoginTwoFactor from "./LoginTwoFactor";

interface IProps {
  storageReset(): void;
}

const Login: FC<IProps> = ({ storageReset }) => {
  const { t } = useTranslation();
  const [twoFactorToken, setTwoFactorToken] = useState("");

  useEffect(() => {
    storageReset();
    localStorage.clear();

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  return (
    <SmallPage>
      <LoginContainer>
        <h1>{t("login.title")}</h1>
        <LoginCard>
          {twoFactorToken ? (
            <LoginTwoFactor
              twoFactorToken={twoFactorToken}
              setTwoFactorToken={setTwoFactorToken}
            />
          ) : (
            <LoginFirst setTwoFactorToken={setTwoFactorToken} />
          )}
        </LoginCard>
      </LoginContainer>
    </SmallPage>
  );
};

const mapDispachToProps = {
  storageReset,
};

export default connect(null, mapDispachToProps)(Login);
