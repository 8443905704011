import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export const selectClientFolderState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.clientFolder.state;

export const selectClientFolder: ReselectModule.Selector<
  IApplicationState,
  IItemIdName[]
> = (state) => state.clientFolder.data;
