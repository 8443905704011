import { IItemIdName } from "../../models/common";
import { StoreState } from "../storeState";

export enum ClientFolderTypes {
  CLIENT_FOLDER_FETCH_REQUEST = "@@clientFolder/FETCH_REQUEST",
  CLIENT_FOLDER_FETCH_SUCCESS = "@@clientFolder/FETCH_SUCCESS",
  CLIENT_FOLDER_FETCH_ERROR = "@@clientFolder/FETCH_ERROR",
}

export interface IClientFolderState {
  state: StoreState;
  data: IItemIdName[];
}
