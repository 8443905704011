import { FC } from "react";
import { LoaderContainer } from "./LoaderStyles";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";

const Loader: FC<{ size?: number }> = ({ size }) => {
  return (
    <LoaderContainer>
      <TailSpin
        color={COLORS.loaderColor}
        width={size || 48}
        height={size || 48}
      />
    </LoaderContainer>
  );
};

export default Loader;
