import { Form, Formik } from "formik";
import { FC } from "react";
import { FormGroup } from "../../styles/form";
import FilterPanelExtended from "../common/filter/FilterPanelExtended";
import Search from "../common/form/Search";
import {
  FilterContainer,
  FilterExtendedContainer,
} from "../common/filter/FilterPanelStyles";
import { IAssistanceAddressListFilter } from "../../store/assistanceAddressList";
import AssistanceAddressFilterExtended, {
  IAssistanceAddressFilterExtended,
} from "./AssistanceAddressFilterExtended";

interface IProps {
  name: string;
  filter: IAssistanceAddressListFilter;
  changeFilter(filter: IAssistanceAddressListFilter): void;
}

const AssistanceAddressFilterPanel: FC<IProps> = ({
  name,
  filter,
  changeFilter,
}) => {
  return (
    <FilterContainer>
      <Search
        name={name + "Search"}
        defaultValue={filter.search}
        onChanged={(value) => {
          changeFilter({ ...filter, search: value });
        }}
      />
      <FilterExtendedContainer>
        <Formik<IAssistanceAddressFilterExtended>
          initialValues={{
            categoryId: filter.categoryId,
            careTypeId: filter.careTypeId,
            targetGroupId: filter.targetGroupId,
          }}
          validateOnMount={true}
          onSubmit={() => undefined}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FilterPanelExtended
                values={values}
                isValid={isValid}
                onChange={() => {
                  changeFilter({
                    search: filter.search,
                    isOpen: filter.isOpen,
                    ...values,
                  });
                }}
              />
              <FormGroup>
                <AssistanceAddressFilterExtended
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
      </FilterExtendedContainer>
    </FilterContainer>
  );
};

export default AssistanceAddressFilterPanel;
