import { Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ClientCareStateType, IClientCare } from "../../models/client";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getClientCare,
  selectClientCare,
  selectClientCareState,
} from "../../store/clientCare";
import { FontAwesomeButton, FormButton } from "../../styles/button";
import { FormDesktop, FormGroup, FormTablet } from "../../styles/form";
import Input from "../common/form/Input";
import Loader from "../common/Loader";
import * as Yup from "yup";
import validations from "../../utils/validations";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import DatePicker from "../common/form/DatePicker";
import { handleLoadEmptySuggestions } from "../../utils/suggestions";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import AssistanceAddressDetailModal from "../assistance/AssistanceAddressDetailModal";
import { H1WithMargin } from "../../styles/text";
import FormGroupRow from "../common/form/FormGroupRow";

export interface IClientCareForm {
  id: number;
  assistanceId: number;
  assistanceName: string;
  assistanceAddressId: number;
  assistanceAddressName: string;
  assistanceCategoryListingId: number;
  assistanceCategoryListingName: string;
  careTypeId: number;
  careTypeName: string;
  from: Date;
  to?: Date | null;
  state: ClientCareStateType;
  note?: string | null;
}

interface IProps {
  clientCareState: StoreState;
  clientCare: IClientCare | null;
  getClientCare(clientCareId: number): void;
}

const ClientCareDetail: FC<IProps> = ({
  clientCareState,
  clientCare,
  getClientCare,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { careId } = useParams();
  const [loading, setLoading] = useState(true);
  const [assistanceAddressDetailIsOpen, setAssistanceAddressDetailIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    getClientCare(parseInt(careId!));
    setLoading(false);
  }, [careId, getClientCare]);

  const handleSubmit = () => {};

  const handleCancel = () => {
    navigate(`/care`);
  };

  const handleOpenAssistanceAddressDetail = () => {
    setAssistanceAddressDetailIsOpen(true);
  };

  const handleCloseAssistanceAddressDetail = () => {
    setAssistanceAddressDetailIsOpen(false);
  };

  if (loading || clientCareState === StoreState.Loading) {
    return <Loader />;
  }

  if (clientCareState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <>
      <AssistanceAddressDetailModal
        assistanceAddressId={clientCare!.assistanceAddress.id}
        isOpen={assistanceAddressDetailIsOpen}
        close={handleCloseAssistanceAddressDetail}
      />

      <Formik<IClientCareForm>
        initialValues={{
          ...clientCare!,
          assistanceId: clientCare!.assistance.id,
          assistanceName: clientCare!.assistance.name,
          assistanceAddressId: clientCare!.assistanceAddress.id,
          assistanceAddressName: clientCare!.assistanceAddress.name,
          assistanceCategoryListingId: clientCare!.assistanceCategoryListing.id,
          assistanceCategoryListingName:
            clientCare!.assistanceCategoryListing.name,
          careTypeId: clientCare!.careType.id,
          careTypeName: clientCare!.careType.name,
          note: clientCare!.note ?? "",
        }}
        validationSchema={Yup.object({
          assistanceId: validations.idRequired(t),
          assistanceAddressId: validations.idRequired(t),
          assistanceCategoryListingId: validations.idRequired(t),
          careTypeId: validations.idRequired(t),
          from: validations.dateRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <>
          <FormDesktop>
            <H1WithMargin>{t("client.care.detailTitle")}</H1WithMargin>
            <FormGroup>
              <FormGroupRow
                gridTemplateColumns="1fr 1fr"
                names={["assistanceCategoryListingId", "careTypeId"]}
                label={t("client.care.careType")}
              >
                <SuggestionFormik
                  nameId="assistanceCategoryListingId"
                  nameText="assistanceCategoryListingName"
                  loadSuggestions={handleLoadEmptySuggestions}
                  disabled={true}
                />
                <SuggestionFormik
                  nameId="careTypeId"
                  nameText="careTypeName"
                  loadSuggestions={handleLoadEmptySuggestions}
                  disabled={true}
                />
              </FormGroupRow>

              <FormGroupRow
                gridTemplateColumns="1fr 1fr"
                names={["assistanceId", "assistanceAddressId"]}
                label={t("client.care.assistanceAddress")}
              >
                <SuggestionFormik
                  nameId="assistanceId"
                  nameText="assistanceName"
                  loadSuggestions={handleLoadEmptySuggestions}
                  disabled={true}
                />
                <SuggestionFormik
                  nameId="assistanceAddressId"
                  nameText="assistanceAddressName"
                  loadSuggestions={handleLoadEmptySuggestions}
                  disabled={true}
                  textAfter={
                    <FontAwesomeButton
                      icon={faExternalLinkAlt}
                      onClick={handleOpenAssistanceAddressDetail}
                    />
                  }
                />
              </FormGroupRow>

              <DatePicker
                name="from"
                label={t("client.care.from")}
                disabled={true}
              />
              <DatePicker
                name="to"
                label={t("client.care.to")}
                disabled={true}
              />
              <Input
                name="state"
                label={t("client.care.state")}
                component="select"
                disabled={true}
              >
                {(
                  Object.keys(ClientCareStateType) as Array<
                    keyof typeof ClientCareStateType
                  >
                ).map((key) => (
                  <option key={key} value={ClientCareStateType[key]}>
                    {t("client.care.states." + ClientCareStateType[key])}
                  </option>
                ))}
              </Input>
              <Input
                name="note"
                label={t("client.care.note")}
                maxLength={512}
                disabled={true}
              />
            </FormGroup>
            <div>
              <FormButton ver="secondary" type="button" onClick={handleCancel}>
                {t("common.back")}
              </FormButton>
            </div>
          </FormDesktop>
          <FormTablet>
            <H1WithMargin>{t("client.care.detailTitle")}</H1WithMargin>
            <FormGroup>
              <SuggestionFormik
                nameId="assistanceCategoryListingId"
                nameText="assistanceCategoryListingName"
                label={t("client.care.careType")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
              />
              <SuggestionFormik
                nameId="careTypeId"
                nameText="careTypeName"
                label=" "
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
              />

              <SuggestionFormik
                nameId="assistanceId"
                nameText="assistanceName"
                label={t("client.care.assistanceAddress")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
              />
              <SuggestionFormik
                nameId="assistanceAddressId"
                nameText="assistanceAddressName"
                label=" "
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
                textAfter={
                  <FontAwesomeButton
                    icon={faExternalLinkAlt}
                    onClick={handleOpenAssistanceAddressDetail}
                  />
                }
              />

              <DatePicker
                name="from"
                label={t("client.care.from")}
                disabled={true}
              />
              <DatePicker
                name="to"
                label={t("client.care.to")}
                disabled={true}
              />
              <Input
                name="state"
                label={t("client.care.state")}
                component="select"
                disabled={true}
              >
                {(
                  Object.keys(ClientCareStateType) as Array<
                    keyof typeof ClientCareStateType
                  >
                ).map((key) => (
                  <option key={key} value={ClientCareStateType[key]}>
                    {t("client.care.states." + ClientCareStateType[key])}
                  </option>
                ))}
              </Input>
              <Input
                name="note"
                label={t("client.care.note")}
                maxLength={512}
                disabled={true}
              />
            </FormGroup>
            <div>
              <FormButton ver="secondary" type="button" onClick={handleCancel}>
                {t("common.back")}
              </FormButton>
            </div>
          </FormTablet>
        </>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientCareState: selectClientCareState(state),
    clientCare: selectClientCare(state),
  };
};

const mapDispachToProps = {
  getClientCare,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientCareDetail);
