import { IClient } from "../../models/client";
import { StoreState } from "../storeState";

export enum ClientTypes {
  CLIENT_FETCH_REQUEST = "@@client/FETCH_REQUEST",
  CLIENT_FETCH_SUCCESS = "@@client/FETCH_SUCCESS",
  CLIENT_FETCH_ERROR = "@@client/FETCH_ERROR",
}

export interface IClientState {
  state: StoreState;
  data: IClient | null;
}
