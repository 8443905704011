import { IAssistanceAddress } from "../../models/assistance";
import { StoreState } from "../storeState";

export enum AssistanceAddressTypes {
  ASSISTANCE_ADDRESS_FETCH_REQUEST = "@@assistanceAddress/FETCH_REQUEST",
  ASSISTANCE_ADDRESS_FETCH_SUCCESS = "@@assistanceAddress/FETCH_SUCCESS",
  ASSISTANCE_ADDRESS_FETCH_ERROR = "@@assistanceAddress/FETCH_ERROR",
}

export interface IAssistanceAddressState {
  state: StoreState;
  data: IAssistanceAddress | null;
}
