import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientCareItem } from "../../models/client";
import { IApplicationState } from "../../store";
import {
  changeClientCareListOrder,
  changeClientCareListPage,
  getClientCareList,
  getClientCareListCount,
  IClientCareListState,
  selectClientCareList,
  clientCareListLoad,
} from "../../store/clientCareList";
import Grid from "../common/grid/Grid";
import { GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/consts";

interface IProps {
  prov: IClientCareListState;
  getClientCareList(cancelToken: CancelToken): void;
  getClientCareListCount(cancelToken: CancelToken): void;
  changeClientCareListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientCareListPage(page: number): void;
  clientCareListLoad(reload: boolean): void;
}

const ClientCare: FC<IProps> = ({
  prov,
  getClientCareList,
  getClientCareListCount,
  changeClientCareListOrder,
  changeClientCareListPage,
  clientCareListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    clientCareListLoad(false);
  }, [clientCareListLoad]);

  const handleRenderData = (item: IClientCareItem): ReactElement => {
    return (
      <>
        <TableCol>{item.assistanceAddress.name}</TableCol>
        <TableCol>{item.careType.name}</TableCol>
        <TableCol>{format(item.from, DATE_FORMAT)}</TableCol>
        <TableCol>{item.to && format(item.to, DATE_FORMAT)}</TableCol>
        <TableCol>{t("client.care.states." + item.state)}</TableCol>
        <TableCol>
          <NavLink to={`/care/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IClientCareItem) => {
    navigate(`/care/${item.id}`);
  };

  return (
    <>
      <h1>{t("client.care.detailTitle")}</h1>
      <Grid<IClientCareItem>
        headers={[
          { captionStr: "client.care.assistanceAddress" },
          { captionStr: "client.care.careType" },
          { captionStr: "client.care.from" },
          { captionStr: "client.care.to" },
          { captionStr: "client.care.state" },
          {},
        ]}
        renderData={handleRenderData}
        getData={getClientCareList}
        getCount={getClientCareListCount}
        changeOrder={changeClientCareListOrder}
        changePage={changeClientCareListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientCareList(state),
  };
};

const mapDispachToProps = {
  getClientCareList,
  getClientCareListCount,
  changeClientCareListOrder,
  changeClientCareListPage,
  clientCareListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientCare);
