import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const HeaderIconButton = styled.button`
  background-color: var(--top-panel-bg);
  outline: none;
  border: none;
  cursor: pointer;
  height: auto;
  color: var(--header-color);
  transition: all 0.3s ease;
  margin-left: 1rem;

  @media screen and (min-width: ${SIZES.laptop}) {
    display: none;
  }

  &:hover {
    color: var(--header-color-hover);
  }

  &.active {
    color: var(--header-color-active);
  }
`;
