import { createReducer } from "@reduxjs/toolkit";
import { IClientFolderListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  filter: { search: undefined, isOpen: false, parentId: null },
} as IClientFolderListState;

export const clientFolderListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.clientFolderListFetchRequest, (state, action) => ({
        ...state,
        state: StoreState.Loading,
        countState: action.payload,
      }))
      .addCase(actions.clientFolderListFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload.data,
        countState: action.payload.newCountState,
        count: action.payload.newCount,
      }))
      .addCase(actions.clientFolderListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.clientFolderListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(actions.clientFolderListCountSuccess, (state, action) => ({
        ...state,
        countState: StoreStateDontLoad.Loaded,
        count: action.payload,
      }))
      .addCase(actions.clientFolderListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.clientFolderListChangeOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
      .addCase(actions.clientFolderListChangePage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(actions.clientFolderListChangeFilter, (state, action) => ({
        ...state,
        state: StoreState.None,
        countState: StoreStateDontLoad.DontLoad,
        filter: action.payload,
        page: 0,
      }))
      .addCase(actions.clientFolderListReset, (state) => ({
        ...initialState,
        filter: state.filter,
      }))
      .addCase(actions.clientFolderListLoadAction, (state, action) => ({
        ...state,
        state: action.payload.newState,
        countState: action.payload.newCountState,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
