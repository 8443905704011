import axios, { CancelToken, CancelTokenSource } from "axios";
import { FC, useEffect, useRef, useState } from "react";
import Autosuggest, {
  ChangeEvent,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../styles/colors";
import { DEBOUNCE_TIMEOUT_MS } from "../../../utils/consts";
import {
  SuggestionClearContainer,
  SuggestionContainer,
} from "./SuggestionStyles";
import { TailSpin } from "react-loader-spinner";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../../styles/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LOADING_ID = -1;
const NOT_FOUND_ID = -2;
const ERROR_ID = -3;
export const FAKE1 = -4;
export const FAKE2 = -5;

export interface ISuggestionValue {
  id: number;
  text: string;
}

interface IProps {
  id: string;
  selected?: ISuggestionValue;
  error?: boolean;
  showListAlways?: boolean;
  disabled?: boolean;
  disabledBySelected?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  loadSuggestions(
    text: string,
    cancelToken: CancelToken
  ): Promise<ISuggestionValue[]>;
  selectValue(value: ISuggestionValue | undefined): void;
  onBlur?(): void;
}

const Suggestion: FC<IProps> = ({
  id,
  selected = { id: 0, text: "" },
  error,
  showListAlways = true,
  disabled,
  disabledBySelected,
  placeholder,
  autoFocus = false,
  loadSuggestions,
  selectValue,
  onBlur,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(selected.text);
  const [suggestions, setSuggestions] = useState<ISuggestionValue[]>([]);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timeout.current);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputValue(selected.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.id]);

  const handleSuggestionsFetchRequested = (
    request: SuggestionsFetchRequestedParams
  ) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    cancelToken.current?.cancel();
    var text = request.value;
    setSuggestions([{ id: LOADING_ID, text: "" }]);

    timeout.current = setTimeout(
      () => handleSuggestionsFetchRequested2(text),
      DEBOUNCE_TIMEOUT_MS
    );
  };

  const handleSuggestionsFetchRequested2 = async (text: string) => {
    var token = axios.CancelToken.source();
    cancelToken.current = token;

    try {
      const list = await loadSuggestions(text, token.token);

      if (list.length === 0) {
        setSuggestions([{ id: NOT_FOUND_ID, text: "" }]);
      } else {
        setSuggestions(list);
      }
    } catch {
      if (!token.token.reason) {
        setSuggestions([{ id: ERROR_ID, text: "" }]);
      }
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleGetSuggestionValue = (suggestion: ISuggestionValue): string => {
    return suggestion.text;
  };

  const handleRenderSuggestion = (suggestion: ISuggestionValue) => {
    if (suggestion.id === LOADING_ID) {
      return <TailSpin color={COLORS.loaderColor} width={32} height={32} />;
    }

    if (suggestion.id === NOT_FOUND_ID) {
      return t("common.noData");
    }

    if (suggestion.id === ERROR_ID) {
      return t("errors.unknown");
    }

    return suggestion.text;
  };

  const handleChange = (
    event: React.FormEvent<HTMLElement>,
    params: ChangeEvent
  ) => {
    setInputValue(params.newValue);

    if (selected.id !== 0 && params.newValue.trim().length === 0) {
      selectValue(undefined);
    }
  };

  const handleSuggestionSelected = (
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<ISuggestionValue>
  ) => {
    if (data.suggestion.id > 0 && selected.id !== data.suggestion.id) {
      selectValue(data.suggestion);
    }
    setTimeout(() => inputRef.current?.blur(), 0);
  };

  const handleBlur = () => {
    setInputValue(selected.text);
    onBlur?.();
  };

  const handleShouldRenderSuggestions = (value: string) =>
    showListAlways || value.trim().length > 0;

  const handleClearClick = () => {
    setInputValue("");
    selectValue(undefined);

    //After state updated.
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const inputProps = {
    placeholder: placeholder ?? t("common.suggestionPlaceholder"),
    value: inputValue,
    disabled: disabled || !!disabledBySelected,
    ref: inputRef,
    autoFocus: autoFocus,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <SuggestionContainer $error={error}>
      <Autosuggest<ISuggestionValue>
        id={id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={handleGetSuggestionValue}
        renderSuggestion={handleRenderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={handleSuggestionSelected}
        shouldRenderSuggestions={handleShouldRenderSuggestions}
      />
      {inputValue && !disabled && (
        <SuggestionClearContainer>
          <IconButton onClick={handleClearClick}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </SuggestionClearContainer>
      )}
    </SuggestionContainer>
  );
};

export default Suggestion;
