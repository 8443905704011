import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DemandPriorityType, IDemandItem } from "../../models/demand";
import { IApplicationState } from "../../store";
import {
  changeClientDemandListOrder,
  changeClientDemandListPage,
  getClientDemandList,
  getClientDemandListCount,
  IClientDemandListState,
  selectClientDemandList,
  clientDemandListLoad,
} from "../../store/clientDemandList";
import Grid from "../common/grid/Grid";
import { GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

interface IProps {
  prov: IClientDemandListState;
  getClientDemandList(cancelToken: CancelToken): void;
  getClientDemandListCount(cancelToken: CancelToken): void;
  changeClientDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDemandListPage(page: number): void;
  clientDemandListLoad(reload: boolean): void;
}

const ClientDemand: FC<IProps> = ({
  prov,
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  clientDemandListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    clientDemandListLoad(false);
  }, [clientDemandListLoad]);

  const handleRenderData = (item: IDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{t("demand.states." + item.state)}</TableCol>
        <TableCol>
          <span
            className={
              item.priority === DemandPriorityType.High ? "error" : undefined
            }
          >
            {t("demand.priorities." + item.priority)}
          </span>
        </TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.assignedName}</TableCol>
        <TableCol>
          <NavLink to={`/demand/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IDemandItem) => {
    navigate(`/demand/${item.id}`);
  };

  return (
    <>
      <h1>{t("client.demand.detailTitle")}</h1>
      <Grid<IDemandItem>
        headers={[
          { captionStr: "client.demand.state" },
          { captionStr: "client.demand.priority" },
          { captionStr: "client.demand.name" },
          { captionStr: "client.demand.assignedName" },
          {},
        ]}
        renderData={handleRenderData}
        getData={getClientDemandList}
        getCount={getClientDemandListCount}
        changeOrder={changeClientDemandListOrder}
        changePage={changeClientDemandListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDemandList(state),
  };
};

const mapDispachToProps = {
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  clientDemandListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDemand);
