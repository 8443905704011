import { AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { selectClientHouseState } from ".";
import { StoreState } from "../storeState";

export function getClientHouse(): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientHouseState(getState());
    if (state === StoreState.Loading || state === StoreState.Loaded) {
      return;
    }

    dispatch(actions.clientHouseFetchRequest());
    try {
      const clientHouse = await clientApi.getClientHouse();
      dispatch(actions.clientHouseFetchSuccess(clientHouse.data));
    } catch {
      dispatch(actions.clientHouseFetchError());
    }
  };
}
