import { AxiosResponse } from "axios";
import {
  IChangePasswordData,
  IChangePasswordResult,
  IForgottenPasswordData,
  ILoginData,
  ILoginResponse,
  IResetPasswordData,
  ITwoFactorData,
} from "../models/auth";
import api from "../utils/api";

const login = async (
  data: ILoginData
): Promise<AxiosResponse<ILoginResponse>> =>
  api.post(`/api/Auth/Public/Login`, data);

const twoFactor = async (
  data: ITwoFactorData
): Promise<AxiosResponse<ILoginResponse>> =>
  api.post(`/api/Auth/Public/TwoFactor`, data);

const sendPasswordResetLink = (
  data: IForgottenPasswordData
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Auth/Public/ForgottenPassword`, data);

const resetPassword = (
  data: IResetPasswordData
): Promise<AxiosResponse<ILoginResponse>> =>
  api.post(`/api/Auth/Public/ForgottenPasswordChange`, data);

const changePassword = (
  data: IChangePasswordData
): Promise<AxiosResponse<IChangePasswordResult>> =>
  api.post(`/api/Auth/Public/ChangePassword`, data);

const renewToken = (): Promise<AxiosResponse<string>> =>
  api.get(`/api/Auth/Public/TokenRenew`);

const authApi = {
  login,
  twoFactor,
  sendPasswordResetLink,
  resetPassword,
  changePassword,
  renewToken,
};

export default authApi;
