import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";

export function getClientContactPerson(
  clientContactPersonId: number
): AppThunkActionAsync {
  return async (dispatch) => {
    dispatch(actions.clientContactPersonFetchRequest());
    try {
      const clientContactPerson = await clientApi.getClientContactPerson(
        clientContactPersonId
      );
      dispatch(
        actions.clientContactPersonFetchSuccess(clientContactPerson.data)
      );
    } catch {
      dispatch(actions.clientContactPersonFetchError());
    }
  };
}

export function getDefaultClientContactPerson(): AppThunkAction {
  return (dispatch) => {
    const data = {
      id: 0,
      type: { id: 0, name: "" },
      isActive: true,
      firstName: "",
      lastName: "",
    };

    dispatch(actions.clientContactPersonFetchSuccess(data));
  };
}
