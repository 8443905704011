import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IClientHouse } from "../../models/client";
import { StoreState } from "../storeState";

export const selectClientHouseState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.clientHouse.state;

export const selectClientHouse: ReselectModule.Selector<
  IApplicationState,
  IClientHouse | null
> = (state) => state.clientHouse.data;
