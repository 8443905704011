import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import * as mapActions from "../assistanceAddressMap/actions";
import assistanceApi from "../../api/assistance";
import {
  IAssistanceAddressListFilter,
  IAssistanceAddressListState,
  selectAssistanceAddressList,
} from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";

export function getAssistanceAddressList(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistanceAddressList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.assistanceAddressListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addAssistanceAddressParams(params, state);

      const addresses = await assistanceApi.getAssistanceAddressList({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, addresses);

      dispatch(
        actions.assistanceAddressListFetchSuccess({
          data: addresses.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(
        actions.assistanceAddressListFetchError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function getAssistanceAddressListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectAssistanceAddressList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.assistanceAddressListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addAssistanceAddressParams(params, state);

      const count = await assistanceApi.getAssistanceAddressCount({
        params,
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.assistanceAddressListCountSuccess(count.data));
    } catch {
      dispatch(
        actions.assistanceAddressListCountError(
          cancelToken.reason !== undefined
        )
      );
    }
  };
}

export function changeAssistanceAddressListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistanceAddressListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeAssistanceAddressListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistanceAddressListChangePage(page));
  };
}

export function changeAssistanceAddressListFilter(
  filter: IAssistanceAddressListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.assistanceAddressListChangeFilter(filter));
    dispatch(mapActions.assistanceAddressMapChangeFilter());
  };
}

export function assistanceAddressListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectAssistanceAddressList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.assistanceAddressListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.assistanceAddressListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addAssistanceAddressParams(
  params: URLSearchParams,
  state: IAssistanceAddressListState
) {
  if (state.filter!.categoryId > 0) {
    params.append("categoryId", state.filter!.categoryId.toString());
  }

  if (state.filter!.careTypeId > 0) {
    params.append("careTypeId", state.filter!.careTypeId.toString());
  }

  if (state.filter!.targetGroupId > 0) {
    params.append("targetGroupId", state.filter!.targetGroupId.toString());
  }
}
