import { FC, useEffect, useRef, useState } from "react";
import { IActionItem } from "../../models/calendar";
import CalendarDetailModal from "../calendar/CalendarDetailModal";
import { errorToast } from "../../utils/toasts";
import clientApi from "../../api/client";
import FullCalendar, { EventClickArg, EventInput } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import csLocale from "@fullcalendar/core/locales/cs";
import { CalendarContainer } from "../common/calendar/CalendarStyles";
import Loader from "../common/Loader";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { H1WithMargin } from "../../styles/text";

declare type EventSourceError = {
  message: string;
  response?: any;
  [otherProp: string]: any;
};

interface ICalendarRange {
  startStr: string;
  endStr: string;
}

interface ICalendarCache {
  range: ICalendarRange;
  data: EventInput[];
}

const ClientCalendar: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [cache, setCache] = useState<ICalendarCache | null>();
  const [detailIsOpen, setDetailIsOpen] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<IActionItem | null>(null);
  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async (
    range: ICalendarRange,
    cancelToken: CancelToken
  ): Promise<EventInput[]> => {
    const params = new URLSearchParams();
    params.append("from", range.startStr);
    params.append("to", range.endStr);

    const apiData = await clientApi.getClientActionList({
      params,
      cancelToken,
    });

    const data = apiData.data.map((x) => ({
      id: x.id.toString(),
      title: x.description || x.type.name,
      start: x.from,
      end: x.to,
      data: x,
    }));

    return data;
  };

  const handleEvents = (
    range: ICalendarRange,
    successCallback: (events: EventInput[]) => void,
    failureCallback: (error: EventSourceError) => void
  ) => {
    if (
      cache &&
      cache.range.startStr === range.startStr &&
      cache.range.endStr === range.endStr
    ) {
      successCallback(cache.data);
      return;
    }

    cancelToken.current?.cancel();
    var token = axios.CancelToken.source();
    cancelToken.current = token;

    setCache({ range, data: [] });
    setLoading(true);
    loadData(range, token.token)
      .then((data) => {
        setCache({ range, data });
        setLoading(false);
        successCallback(data);
      })
      .catch((err) => {
        if (token.token.reason) {
          return;
        }

        setCache({ range, data: [] });
        setLoading(false);
        errorToast();
        failureCallback(err);
      });
  };

  const handleEventClick = (arg: EventClickArg) => {
    const data = arg.event.extendedProps.data as IActionItem;
    setDetailData(data);
    setDetailIsOpen(true);
  };

  const handleDetailDataClose = (reload: boolean) => {
    setDetailIsOpen(false);

    if (reload) {
      setCache(null);
    }
  };

  return (
    <CalendarContainer $loading={loading}>
      <CalendarDetailModal
        isOpen={detailIsOpen}
        data={detailData}
        close={handleDetailDataClose}
      />

      <H1WithMargin>{t("client.calendar.detailTitle")}</H1WithMargin>
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay,listMonth",
        }}
        locale={csLocale}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        }}
        buttonIcons={{
          prev: "caseman-calendar-left",
          next: "caseman-calendar-right",
        }}
        navLinks={true}
        //Remove scrollbar on mobile:
        contentHeight="auto"
        //If true, then always show 6 weeeks, same height for every month.
        fixedWeekCount={false}
        showNonCurrentDates={false}
        //Display duration on month and week calendars:
        //displayEventEnd={true}
        events={handleEvents}
        eventClick={handleEventClick}
      />
      {loading && <Loader />}
    </CalendarContainer>
  );
};

export default ClientCalendar;
