import { AppThunkActionAsync } from "..";
import * as actions from "./actions";
import assistanceApi from "../../api/assistance";
import { StoreState } from "../storeState";
import { selectAssistanceAddressState, selectAssistanceAddress } from ".";

export function getAssistanceAddress(id: number): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = getState();
    const storeState = selectAssistanceAddressState(state);
    const storeAddress = selectAssistanceAddress(state);

    if (storeState === StoreState.Loaded && storeAddress?.id === id) {
      return;
    }

    dispatch(actions.assistanceAddressFetchRequest());
    try {
      const address = await assistanceApi.getAssistanceAddress(id);
      dispatch(actions.assistanceAddressFetchSuccess(address.data));
    } catch {
      dispatch(actions.assistanceAddressFetchError());
    }
  };
}
