import { createReducer } from "@reduxjs/toolkit";
import { IClientContactPersonListState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  filter: { search: undefined, isOpen: false },
} as IClientContactPersonListState;

export const clientContactPersonListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(
        actions.clientContactPersonListFetchRequest,
        (state, action) => ({
          ...state,
          state: StoreState.Loading,
          countState: action.payload,
        })
      )
      .addCase(
        actions.clientContactPersonListFetchSuccess,
        (state, action) => ({
          ...state,
          state: StoreState.Loaded,
          lastLoaded: new Date(),
          data: action.payload.data,
          countState: action.payload.newCountState,
          count: action.payload.newCount,
        })
      )
      .addCase(actions.clientContactPersonListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.clientContactPersonListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(
        actions.clientContactPersonListCountSuccess,
        (state, action) => ({
          ...state,
          countState: StoreStateDontLoad.Loaded,
          count: action.payload,
        })
      )
      .addCase(actions.clientContactPersonListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.clientContactPersonListChangeOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
      .addCase(actions.clientContactPersonListChangePage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(
        actions.clientContactPersonListChangeFilter,
        (state, action) => ({
          ...state,
          state: StoreState.None,
          countState: StoreStateDontLoad.DontLoad,
          filter: action.payload,
          page: 0,
        })
      )
      .addCase(actions.clientContactPersonListReset, (state) => ({
        ...initialState,
        filter: state.filter,
      }))
      .addCase(actions.clientContactPersonListLoadAction, (state, action) => ({
        ...state,
        state: action.payload.newState,
        countState: action.payload.newCountState,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
