export const fullName = (data: {
  firstName: string;
  lastName: string;
  title?: string | null;
  degree?: string | null;
}) =>
  `${data.title ? data.title + " " : ""}${data.firstName} ${data.lastName}${
    data.degree ? " " + data.degree : ""
  }`;

const formatters = {
  fullName,
};

export default formatters;
