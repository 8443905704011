import { FC, useRef, useEffect } from "react";
import { IMapPoint } from "../../../models/map";
import { MapContainer } from "./MapStyles";

interface IProps {
  points: IMapPoint[];
  markerClick(point: IMapPoint): void;
}

const MapComponent: FC<IProps> = ({ points, markerClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (
      containerRef.current === null ||
      mapRef.current !== null ||
      points.length === 0
    ) {
      return;
    }

    let bounds = new google.maps.LatLngBounds();

    const point = points[0];
    const opts = {
      center: { lat: point.lat, lng: point.lng },
      zoom: 15,
    };

    const map = new window.google.maps.Map(containerRef.current!, opts);
    mapRef.current = map;

    for (const point of points) {
      const marker = new window.google.maps.Marker({
        position: { lat: point.lat, lng: point.lng },
        map,
        title: point.name,
      });

      bounds.extend({ lat: point.lat, lng: point.lng });

      marker.addListener("click", () => markerClick(point));
    }

    if (points.length !== 1) {
      setTimeout(() => map.fitBounds(bounds), 0);
    }
  }, [containerRef, mapRef, points, markerClick]);

  if (points.length === 0) {
    return null;
  }

  return <MapContainer ref={containerRef} />;
};

export default MapComponent;
