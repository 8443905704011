import { FC, useState } from "react";
import { Button } from "../../../styles/button";
import CustomModal from "./CustomModal";

interface IProps {
  title: string;
  text: string;
  closeText: string;
  confirmText: string;
  isOpen: boolean;
  isFunction?: boolean;
  close(): void;
  confirm: () => Promise<void>;
}

const ModalTwoButtons: FC<IProps> = ({
  title,
  text,
  closeText,
  confirmText,
  isOpen,
  isFunction,
  close,
  confirm,
}) => {
  const [saving, setSaving] = useState(false);

  const handleConfirm = async () => {
    if (isFunction) {
      await confirm();
      return;
    }

    setSaving(true);
    await confirm();
    setSaving(false);
    close();
  };

  return (
    <CustomModal
      title={title}
      isOpen={isOpen}
      close={close}
      actions={
        <>
          <Button ver="secondary" disabled={saving} onClick={() => close()}>
            {closeText}
          </Button>
          <Button disabled={saving} onClick={handleConfirm}>
            {confirmText}
          </Button>
        </>
      }
    >
      {text}
    </CustomModal>
  );
};

export default ModalTwoButtons;
