import { FC } from "react";
import Loader from "../common/Loader";
import { SmallPage } from "./LayoutStyles";

const LoaderPage: FC = () => {
  return (
    <SmallPage>
      <Loader />
    </SmallPage>
  );
};

export default LoaderPage;
