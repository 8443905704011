import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IItemIdName } from "../models/common";
import api from "../utils/api";

const getSuggestionClientContactPersonList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Listing/ContactPersons/Suggested/List`, config);

const listingApi = {
  getSuggestionClientContactPersonList,
};

export default listingApi;
