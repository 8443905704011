import { createReducer } from "@reduxjs/toolkit";
import { IAssistanceAddressMapState } from "./types";
import * as actions from "./actions";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { STORAGE_RESET } from "../storeReset";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
} as IAssistanceAddressMapState;

export const assistanceAddressMapReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.assistanceAddressMapFetchRequest, (state) => ({
        ...state,
        state: StoreState.Loading,
      }))
      .addCase(actions.assistanceAddressMapFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload,
      }))
      .addCase(actions.assistanceAddressMapFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.assistanceAddressMapChangeFilter, (state, action) => ({
        ...state,
        state: StoreState.None,
      }))
      .addCase(actions.assistanceAddressMapReset, () => ({
        ...initialState,
      }))
      .addCase(actions.assistanceAddressMapLoadAction, (state, action) => ({
        ...state,
        state: action.payload,
      }))
      .addCase(STORAGE_RESET, () => initialState)
);
