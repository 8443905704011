import { TFunction } from "i18next";
import { SetState } from "./types";

export const errorSet = (
  setError: SetState<string | null>,
  err: any,
  t: TFunction
) => {
  if (err?.response?.data?.isCaseman) {
    if (err.response.data.messages.length === 1) {
      setError(err.response.data.messages[0]);
    } else {
      setError(
        err.response.data.messages.map((m: string) => `● ${m}`).join("\n")
      );
    }
  } else {
    setError(t("errors.unknown"));
  }
};
