import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IAssistanceAddress } from "../../models/assistance";
import { StoreState } from "../storeState";

export const selectAssistanceAddressState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.assistanceAddress.state;

export const selectAssistanceAddress: ReselectModule.Selector<
  IApplicationState,
  IAssistanceAddress | null
> = (state) => state.assistanceAddress.data;
