import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IDemand, IDemandItem } from "../models/demand";
import api from "../utils/api";

const getDemandList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IDemandItem[]>> =>
  api.get(`/api/Demand/Me/List`, config);

const getDemandCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Demand/Me/Count`, config);

const getDemand = async (demandId: number): Promise<AxiosResponse<IDemand>> =>
  api.get(`/api/Demand/Me/${demandId}`);

const demandApi = {
  getDemandList,
  getDemandCount,
  getDemand,
};

export default demandApi;
