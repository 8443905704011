import { createAction } from "@reduxjs/toolkit";
import { IClientCareItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientCareListTypes } from "./types";

export const clientCareListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientCareListTypes.CLIENT_CARE_LIST_FETCH_REQUEST
>(ClientCareListTypes.CLIENT_CARE_LIST_FETCH_REQUEST);

export const clientCareListFetchSuccess = createAction<
  {
    data: IClientCareItem[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientCareListTypes.CLIENT_CARE_LIST_FETCH_SUCCESS
>(ClientCareListTypes.CLIENT_CARE_LIST_FETCH_SUCCESS);

export const clientCareListFetchError = createAction<
  boolean,
  ClientCareListTypes.CLIENT_CARE_LIST_FETCH_ERROR
>(ClientCareListTypes.CLIENT_CARE_LIST_FETCH_ERROR);

export const clientCareListCountRequest = createAction<
  void,
  ClientCareListTypes.CLIENT_CARE_LIST_COUNT_REQUEST
>(ClientCareListTypes.CLIENT_CARE_LIST_COUNT_REQUEST);

export const clientCareListCountSuccess = createAction<
  number,
  ClientCareListTypes.CLIENT_CARE_LIST_COUNT_SUCCESS
>(ClientCareListTypes.CLIENT_CARE_LIST_COUNT_SUCCESS);

export const clientCareListCountError = createAction<
  boolean,
  ClientCareListTypes.CLIENT_CARE_LIST_COUNT_ERROR
>(ClientCareListTypes.CLIENT_CARE_LIST_COUNT_ERROR);

export const clientCareListChangeOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientCareListTypes.CLIENT_CARE_LIST_CHANGE_ORDER
>(ClientCareListTypes.CLIENT_CARE_LIST_CHANGE_ORDER);

export const clientCareListChangePage = createAction<
  number,
  ClientCareListTypes.CLIENT_CARE_LIST_CHANGE_PAGE
>(ClientCareListTypes.CLIENT_CARE_LIST_CHANGE_PAGE);

export const clientCareListChangeFilter = createAction<
  IFilterType,
  ClientCareListTypes.CLIENT_CARE_LIST_CHANGE_FILTER
>(ClientCareListTypes.CLIENT_CARE_LIST_CHANGE_FILTER);

export const clientCareListReset = createAction<
  void,
  ClientCareListTypes.CLIENT_CARE_LIST_RESET
>(ClientCareListTypes.CLIENT_CARE_LIST_RESET);

export const clientCareListLoadAction = createAction<
  { newState: StoreState; newCountState: StoreStateDontLoad },
  ClientCareListTypes.CLIENT_CARE_LIST_LOAD
>(ClientCareListTypes.CLIENT_CARE_LIST_LOAD);
