import { Formik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClientIdentityType, IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient, updateClient } from "../../store/client";
import { ApiError, FormGroup, FormRow } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import DatePicker from "../common/form/DatePicker";
import { handleLoadEmptySuggestions } from "../../utils/suggestions";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { FormDesktop, FormTablet } from "../../styles/form";
import FormGroupRow from "../common/form/FormGroupRow";
import { errorSet } from "../../utils/error";
import { H1WithMargin } from "../../styles/text";

interface IClientForm
  extends Omit<
    IClient,
    | "addressPermanentHouseNumber"
    | "addressPermanentLandRegistryNumber"
    | "addressPermanentPostCode"
    | "addressActualHouseNumber"
    | "addressActualLandRegistryNumber"
    | "addressActualPostCode"
    | "addressMailHouseNumber"
    | "addressMailLandRegistryNumber"
    | "addressMailPostCode"
  > {
  keyCaregiverId?: number;
  keyCaregiverName?: string;
  healthInsuranceId: number;
  healthInsuranceName: string;
  addressPermanentHouseNumber: string;
  addressPermanentLandRegistryNumber: string;
  addressPermanentPostCode: string;
  addressActualHouseNumber: string;
  addressActualLandRegistryNumber: string;
  addressActualPostCode: string;
  addressMailHouseNumber: string;
  addressMailLandRegistryNumber: string;
  addressMailPostCode: string;
}

interface IProps {
  client: IClient | null;
  updateClient(newData: IClient): void;
}

// used as 2 parts in FormDesktop using slice
const fields1: Array<{ name: keyof IClient; maxLen?: number }> = [
  { name: "title", maxLen: 100 },
  { name: "firstName", maxLen: 100 },
  { name: "lastName", maxLen: 100 },
  { name: "degree", maxLen: 100 },
];

// used as 2 parts in FormDesktop using slice
const addressPermanentFields: Array<{ name: keyof IClient; maxLen?: number }> =
  [
    { name: "addressPermanentStreet", maxLen: 150 },
    { name: "addressPermanentHouseNumber" },
    { name: "addressPermanentLandRegistryNumber" },
    { name: "addressPermanentCity", maxLen: 150 },
    { name: "addressPermanentPostCode" },
  ];

const numberFields: Array<keyof IClient> = [
  "addressPermanentHouseNumber",
  "addressPermanentLandRegistryNumber",
  "addressPermanentPostCode",
  "addressActualHouseNumber",
  "addressActualPostCode",
  "addressActualLandRegistryNumber",
  "addressMailHouseNumber",
  "addressMailPostCode",
  "addressMailLandRegistryNumber",
];

const addressActualFields: Array<{
  name: keyof IClient;
  maxLen?: number;
}> = [
  { name: "addressActualStreet", maxLen: 150 },
  { name: "addressActualHouseNumber" },
  { name: "addressActualLandRegistryNumber" },
  { name: "addressActualCity", maxLen: 150 },
  { name: "addressActualPostCode" },
];

const addressMailFields: Array<{
  name: keyof IClient;
  maxLen?: number;
}> = [
  { name: "addressMailStreet", maxLen: 150 },
  { name: "addressMailHouseNumber" },
  { name: "addressMailLandRegistryNumber" },
  { name: "addressMailCity", maxLen: 150 },
  { name: "addressMailPostCode" },
];

const ClientGeneral: FC<IProps> = ({ client, updateClient }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: IClientForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          addressPermanentHouseNumber: +data.addressPermanentHouseNumber,
          addressPermanentLandRegistryNumber:
            +data.addressPermanentLandRegistryNumber,
          addressPermanentPostCode: +data.addressPermanentPostCode,
          addressActualHouseNumber: +data.addressActualHouseNumber,
          addressActualLandRegistryNumber:
            +data.addressActualLandRegistryNumber,
          addressActualPostCode: +data.addressActualPostCode,
          addressMailHouseNumber: +data.addressMailHouseNumber,
          addressMailLandRegistryNumber: +data.addressMailLandRegistryNumber,
          addressMailPostCode: +data.addressMailPostCode,
        };

        const newData = await clientApi.updateClient(data2);
        updateClient(newData.data);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const ErrorAndButtons: FC = () => {
    return (
      <>
        {error && <ApiError>{error}</ApiError>}
        <div>
          <SubmitForm />
        </div>
      </>
    );
  };

  return (
    <Formik<IClientForm>
      initialValues={{
        ...client!,
        keyCaregiverId: client!.keyCaregiver ? 1 : 0,
        keyCaregiverName: client!.keyCaregiver ?? "",
        title: client!.title ?? "",
        degree: client!.degree ?? "",
        mobilePhone: client!.mobilePhone ?? "",
        email: client!.email ?? "",
        phone: client!.phone ?? "",
        dataBox: client!.dataBox ?? "",
        identityCode: client!.identityCode ?? "",
        addressPermanentStreet: client!.addressPermanentStreet ?? "",
        addressPermanentHouseNumber: client!.addressPermanentHouseNumber
          ? client!.addressPermanentHouseNumber.toString()
          : "",
        addressPermanentLandRegistryNumber: client!
          .addressPermanentLandRegistryNumber
          ? client!.addressPermanentLandRegistryNumber.toString()
          : "",
        addressPermanentCity: client!.addressPermanentCity ?? "",
        addressPermanentPostCode: client!.addressPermanentPostCode
          ? client!.addressPermanentPostCode.toString()
          : "",
        addressActualStreet: client!.addressActualStreet ?? "",
        addressActualHouseNumber: client!.addressActualHouseNumber
          ? client!.addressActualHouseNumber.toString()
          : "",
        addressActualLandRegistryNumber: client!.addressActualLandRegistryNumber
          ? client!.addressActualLandRegistryNumber.toString()
          : "",
        addressActualCity: client!.addressActualCity ?? "",
        addressActualPostCode: client!.addressActualPostCode
          ? client!.addressActualPostCode.toString()
          : "",
        addressMailStreet: client!.addressMailStreet ?? "",
        addressMailHouseNumber: client!.addressMailHouseNumber
          ? client!.addressMailHouseNumber.toString()
          : "",
        addressMailLandRegistryNumber: client!.addressMailLandRegistryNumber
          ? client!.addressMailLandRegistryNumber.toString()
          : "",
        addressMailCity: client!.addressMailCity ?? "",
        addressMailPostCode: client!.addressMailPostCode
          ? client!.addressMailPostCode.toString()
          : "",
        healthInsuranceId: client!.healthInsurance ? 1 : 0,
        healthInsuranceName: client!.healthInsurance ?? "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        firstName: validations.stringRequired(t),
        lastName: validations.stringRequired(t),
        mobilePhone: validations.phoneOptional(t),
        email: validations.emailRequired(t),
        phone: validations.phoneOptional(t),
        addressPermanentHouseNumber: validations.intOptionalMinMax(1, 9999, t),
        addressPermanentPostCode: validations.postCodeOptional(t),
        addressActualHouseNumber: validations
          .intOptional(t)
          .when(["addressActualIsSame"], {
            is: (addressActualIsSame: any) => !addressActualIsSame,
            then: validations.intOptionalMinMax(1, 9999, t),
          }),
        addressActualPostCode: validations
          .intOptional(t)
          .when(["addressActualIsSame"], {
            is: (addressActualIsSame: any) => !addressActualIsSame,
            then: validations.postCodeOptional(t),
          }),
        addressMailHouseNumber: validations
          .intOptional(t)
          .when(["addressMailIsSame"], {
            is: (addressMailIsSame: any) => !addressMailIsSame,
            then: validations.intOptionalMinMax(1, 9999, t),
          }),
        addressMailPostCode: validations
          .intOptional(t)
          .when(["addressMailIsSame"], {
            is: (addressMailIsSame: any) => !addressMailIsSame,
            then: validations.postCodeOptional(t),
          }),
        identificationNumber: validations.identificationNumberOptional(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values }) => (
        <>
          <H1WithMargin>{t("client.general")}</H1WithMargin>
          <FormDesktop>
            <FormRow gridTemplateColumns="1fr 2fr 2fr 1fr">
              {fields1.map((f) => (
                <Input
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                  disabled={true}
                />
              ))}
            </FormRow>

            <hr />

            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              <FormGroupRow
                gridTemplateColumns="2fr 1fr 1fr"
                names={[
                  addressPermanentFields[0].name,
                  addressPermanentFields[1].name,
                ]}
                label={t("client.streetAndHouseNumberAndLandRegistryNumber")}
              >
                {addressPermanentFields.slice(0, 3).map((f) => (
                  <Input
                    key={f.name}
                    name={f.name}
                    type={
                      numberFields.find((x) => x === f.name) ? "number" : "text"
                    }
                    error={touched[f.name] && !!errors[f.name]}
                    maxLength={f.maxLen}
                    disabled={true}
                  />
                ))}
              </FormGroupRow>

              <FormGroupRow
                gridTemplateColumns="2fr 1fr"
                names={[
                  addressPermanentFields[2].name,
                  addressPermanentFields[3].name,
                ]}
                label={t("client.cityAndPostCode")}
              >
                {addressPermanentFields.slice(3).map((f) => (
                  <Input
                    key={f.name}
                    name={f.name}
                    type={
                      numberFields.find((x) => x === f.name) ? "number" : "text"
                    }
                    error={touched[f.name] && !!errors[f.name]}
                    maxLength={f.maxLen}
                    disabled={true}
                  />
                ))}
              </FormGroupRow>

              <Input
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={true}
              />
              <Input
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={true}
              />
            </FormGroup>

            {!values.addressActualIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressActual")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr 1fr"
                    names={[
                      addressActualFields[0].name,
                      addressActualFields[1].name,
                      addressActualFields[2].name,
                    ]}
                    label={t(
                      "client.streetAndHouseNumberAndLandRegistryNumber"
                    )}
                  >
                    {addressActualFields.slice(0, 3).map((f) => (
                      <Input
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={true}
                      />
                    ))}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr"
                    names={[
                      addressActualFields[2].name,
                      addressActualFields[3].name,
                    ]}
                    label={t("client.cityAndPostCode")}
                  >
                    {addressActualFields.slice(3).map((f) => (
                      <Input
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={true}
                      />
                    ))}
                  </FormGroupRow>
                </FormGroup>
              </>
            )}

            {!values.addressMailIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressMail")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr 1fr"
                    names={[
                      addressMailFields[0].name,
                      addressMailFields[1].name,
                      addressMailFields[2].name,
                    ]}
                    label={t(
                      "client.streetAndHouseNumberAndLandRegistryNumber"
                    )}
                  >
                    {addressMailFields.slice(0, 3).map((f) => (
                      <Input
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={true}
                      />
                    ))}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr"
                    names={[
                      addressMailFields[2].name,
                      addressMailFields[3].name,
                    ]}
                    label={t("client.cityAndPostCode")}
                  >
                    {addressMailFields.slice(3).map((f) => (
                      <Input
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={true}
                      />
                    ))}
                  </FormGroupRow>
                </FormGroup>
              </>
            )}

            <hr />

            <FormGroup>
              <h2>{t("client.contact")}</h2>
              <PhoneInput
                name="mobilePhone"
                label={t("client.mobilePhone")}
                error={touched.mobilePhone && !!errors.mobilePhone}
              />
              <PhoneInput
                name="phone"
                label={t("client.phone")}
                error={touched.phone && !!errors.phone}
              />
              <Input
                name="email"
                label={t("client.email")}
                error={touched.email && !!errors.email}
                maxLength={255}
              />
              <Input
                name="dataBox"
                label={t("client.dataBox")}
                error={touched.dataBox && !!errors.dataBox}
                maxLength={100}
              />
            </FormGroup>

            <hr />

            <FormGroup>
              <h2>{t("client.identification")}</h2>
              <FormGroupRow
                gridTemplateColumns="1fr 1fr"
                names={["identityType", "identityCode"]}
                label={t("client.identification")}
              >
                <Input
                  key="identityType"
                  name="identityType"
                  component="select"
                  error={touched.identityType && !!errors.identityType}
                  disabled={true}
                >
                  <option value="">{t("client.identityTypes.null")}</option>
                  {(
                    Object.keys(ClientIdentityType) as Array<
                      keyof typeof ClientIdentityType
                    >
                  ).map((key) => (
                    <option key={key} value={ClientIdentityType[key]}>
                      {t("client.identityTypes." + ClientIdentityType[key])}
                    </option>
                  ))}
                </Input>
                {values.identityType &&
                  values.identityType !== ClientIdentityType.Unverified && (
                    <Input
                      key="identityCode"
                      name="identityCode"
                      error={touched.identityCode && !!errors.identityCode}
                      maxLength={100}
                      disabled={true}
                    />
                  )}
              </FormGroupRow>

              <FormGroupRow
                gridTemplateColumns="1fr 1fr"
                names={["birthDate", "identificationNumber"]}
                label={t("client.birdthDataAndIdentificationNumber")}
              >
                <DatePicker name="birthDate" disabled={true} />
                <Input
                  key="identificationNumber"
                  name="identificationNumber"
                  error={
                    touched.identificationNumber &&
                    !!errors.identificationNumber
                  }
                  disabled={true}
                />
              </FormGroupRow>

              <SuggestionFormik
                nameId="healthInsuranceId"
                nameText="healthInsuranceName"
                label={t("client.healthInsurance")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
                placeholder={t("common.suggestionNoSelected")}
              />

              <SuggestionFormik
                nameId="keyCaregiverId"
                nameText="keyCaregiverName"
                label={t("client.keyCaregiver")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
                placeholder={t("common.suggestionNoSelected")}
              />
            </FormGroup>
            <ErrorAndButtons />
          </FormDesktop>

          <FormTablet>
            <FormGroup>
              {fields1.map((f) => (
                <Input
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                  disabled={true}
                />
              ))}
            </FormGroup>

            <hr />

            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              {addressPermanentFields.map((f) => (
                <Input
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                  disabled={true}
                />
              ))}
              <Input
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={true}
              />
              <Input
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={true}
              />
            </FormGroup>

            {!values.addressActualIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressActual")}</h2>
                  {addressActualFields.map((f) => (
                    <Input
                      key={f.name}
                      name={f.name}
                      type={
                        numberFields.find((x) => x === f.name)
                          ? "number"
                          : "text"
                      }
                      label={t("client." + f.name)}
                      error={touched[f.name] && !!errors[f.name]}
                      maxLength={f.maxLen}
                      disabled={true}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            {!values.addressMailIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressMail")}</h2>
                  {addressMailFields.map((f) => (
                    <Input
                      key={f.name}
                      name={f.name}
                      type={
                        numberFields.find((x) => x === f.name)
                          ? "number"
                          : "text"
                      }
                      label={t("client." + f.name)}
                      error={touched[f.name] && !!errors[f.name]}
                      maxLength={f.maxLen}
                      disabled={true}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            <hr />

            <FormGroup>
              <h2>{t("client.contact")}</h2>
              <PhoneInput
                name="mobilePhone"
                label={t("client.mobilePhone")}
                error={touched.mobilePhone && !!errors.mobilePhone}
              />
              <PhoneInput
                name="phone"
                label={t("client.phone")}
                error={touched.phone && !!errors.phone}
              />
              <Input
                name="email"
                label={t("client.email")}
                error={touched.email && !!errors.email}
                maxLength={255}
              />
              <Input
                name="dataBox"
                label={t("client.dataBox")}
                error={touched.dataBox && !!errors.dataBox}
                maxLength={100}
              />
            </FormGroup>

            <hr />

            <FormGroup>
              <h2>{t("client.identification")}</h2>
              <Input
                key="identityType"
                name="identityType"
                component="select"
                label={t("client.identityType")}
                error={touched.identityType && !!errors.identityType}
                disabled={true}
              >
                <option value="">{t("client.identityTypes.null")}</option>
                {(
                  Object.keys(ClientIdentityType) as Array<
                    keyof typeof ClientIdentityType
                  >
                ).map((key) => (
                  <option key={key} value={ClientIdentityType[key]}>
                    {t("client.identityTypes." + ClientIdentityType[key])}
                  </option>
                ))}
              </Input>
              {values.identityType &&
                values.identityType !== ClientIdentityType.Unverified && (
                  <Input
                    key="identityCode"
                    name="identityCode"
                    label={t("client.identityCode")}
                    error={touched.identityCode && !!errors.identityCode}
                    maxLength={100}
                    disabled={true}
                  />
                )}
              <DatePicker
                name="birthDate"
                label={t("client.birthDate")}
                disabled={true}
              />
              <Input
                key="identificationNumber"
                name="identificationNumber"
                label={t("client.identificationNumber")}
                error={
                  touched.identificationNumber && !!errors.identificationNumber
                }
                disabled={true}
              />
              <SuggestionFormik
                nameId="healthInsuranceId"
                nameText="healthInsuranceName"
                label={t("client.healthInsurance")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
                placeholder={t("common.suggestionNoSelected")}
              />
              <SuggestionFormik
                nameId="keyCaregiverId"
                nameText="keyCaregiverName"
                label={t("client.keyCaregiver")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={true}
                placeholder={t("common.suggestionNoSelected")}
              />
            </FormGroup>
            <ErrorAndButtons />
          </FormTablet>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
  };
};

const mapDispachToProps = {
  updateClient,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientGeneral);
