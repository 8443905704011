import * as ReselectModule from "reselect";
import { IAssistanceAddressListFilter, IAssistanceAddressListState } from ".";
import { IApplicationState } from "..";

export const selectAssistanceAddressList: ReselectModule.Selector<
  IApplicationState,
  IAssistanceAddressListState
> = (state) => state.assistanceAddressList;

export const selectAssistanceAddressListFilter: ReselectModule.Selector<
  IApplicationState,
  IAssistanceAddressListFilter
> = (state) => state.assistanceAddressList.filter!;
