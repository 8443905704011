import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import {
  changeClientDocumentListFilter,
  IClientDocumentListFilterType,
  IClientDocumentListState,
  selectClientDocumentList,
} from "../../store/clientDocumentList";
import ClientDocumentFolder from "./ClientDocumentFolder";
import { VerticalSpace } from "../../styles/spaces";
import ClientDocumentDocument from "./ClientDocumentDocument";
import { useParams } from "react-router";
import {
  changeClientFolderListFilter,
  IClientFolderListFilter,
  IClientFolderListState,
  selectClientFolderList,
} from "../../store/clientFolderList";
import {
  getClientFolder,
  getDefaultClientFolder,
  selectClientFolder,
  selectClientFolderState,
} from "../../store/clientFolder";
import { StoreState } from "../../store/storeState";
import { IItemIdName } from "../../models/common";
import Loader from "../common/Loader";
import { TailSpin } from "react-loader-spinner";
import { SpanRed } from "../../styles/text";
import { COLORS } from "../../styles/colors";
import BreadCrumb from "../common/navigation/BreadCrumb";

interface IProps {
  clientFolderState: StoreState;
  clientFolder: IItemIdName[];
  clientFolderListState: IClientFolderListState;
  clientDocumentListState: IClientDocumentListState;
  getClientFolder(folderId: string): Promise<void>;
  getDefaultClientFolder(): void;
  changeClientFolderListFilter(filter: IClientFolderListFilter): void;
  changeClientDocumentListFilter(filter: IClientDocumentListFilterType): void;
}

const ClientDocument: FC<IProps> = ({
  clientFolderState,
  clientFolder,
  clientFolderListState,
  clientDocumentListState,
  getClientFolder,
  getDefaultClientFolder,
  changeClientFolderListFilter,
  changeClientDocumentListFilter,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { folderId } = useParams();

  useEffect(() => {
    if (folderId) {
      getClientFolder(folderId);
    } else {
      getDefaultClientFolder();
    }

    changeClientFolderListFilter({
      ...clientFolderListState.filter!,
      idFolder: folderId ? parseInt(folderId) : null,
    });
    changeClientDocumentListFilter({
      ...clientDocumentListState.filter!,
      idFolder: folderId ? parseInt(folderId) : null,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const breadCrumbItems = useMemo(() => {
    const items = [];
    items.push({
      label: t("module.document.root"),
      to: `/document/`,
    });
    items.push(
      ...clientFolder.map((x) => ({
        label: x.name,
        to: `/document/${x.id}`,
      }))
    );

    return items;
  }, [clientFolder, t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h1>{t("module.document.pageTitle")}</h1>
      <p>
        {clientFolderState === StoreState.Loading && (
          <TailSpin color={COLORS.loaderColor} width={16} height={16} />
        )}
        {clientFolderState === StoreState.Error && (
          <SpanRed>{t("errors.unknown")}</SpanRed>
        )}
        {clientFolderState === StoreState.Loaded &&
          breadCrumbItems.length > 1 && <BreadCrumb items={breadCrumbItems} />}
      </p>
      <ClientDocumentFolder />
      <VerticalSpace />
      <ClientDocumentDocument />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientFolderState: selectClientFolderState(state),
    clientFolder: selectClientFolder(state),
    clientFolderListState: selectClientFolderList(state),
    clientDocumentListState: selectClientDocumentList(state),
  };
};

const mapDispachToProps = {
  getClientFolder,
  getDefaultClientFolder,
  changeClientFolderListFilter,
  changeClientDocumentListFilter,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDocument);
