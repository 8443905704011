import { FC, useEffect, useMemo } from "react";
import {
  selectAssistanceAddressFilters,
  selectAssistanceAddressFiltersState,
} from "../../store/assistanceAddressFilters";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";
import { StoreState } from "../../store/storeState";
import { IAssistanceAddressFilters } from "../../models/assistance";
import { useTranslation } from "react-i18next";
import Input from "../common/form/Input";
import { TailSpin } from "react-loader-spinner";
import { COLORS } from "../../styles/colors";

export interface IAssistanceAddressFilterExtended {
  categoryId: number;
  careTypeId: number;
  targetGroupId: number;
}

interface IProps {
  values: IAssistanceAddressFilterExtended;
  assistanceAddressFiltersState: StoreState;
  assistanceAddressFilters: IAssistanceAddressFilters | null;
  setFieldValue(field: string, value: any, shouldValidate?: boolean): void;
}

const AssistanceAddressFilterExtended: FC<IProps> = ({
  values,
  assistanceAddressFiltersState,
  assistanceAddressFilters,
  setFieldValue,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (values.categoryId > 0) {
      setFieldValue("careTypeId", 0);
    }
  }, [values.categoryId, setFieldValue]);

  const categoryInput = useMemo(() => {
    if (assistanceAddressFiltersState !== StoreState.Loaded) {
      return null;
    }

    return (
      <Input
        name="categoryId"
        label={t("assistance.filter.category")}
        component="select"
      >
        <option value={0}>{t("assistance.filter.all")}</option>
        {assistanceAddressFilters!.categories.map((x) => (
          <option key={x.id} value={x.id}>
            {x.name}
          </option>
        ))}
      </Input>
    );
  }, [assistanceAddressFiltersState, assistanceAddressFilters, t]);

  const careTypeInput = useMemo(() => {
    if (assistanceAddressFiltersState !== StoreState.Loaded) {
      return null;
    }

    let list = assistanceAddressFilters!.careTypes;
    if (values.categoryId > 0) {
      const categoryId = parseInt(values.categoryId.toString());
      const selected = assistanceAddressFilters!.categories.find(
        (x) => x.id === categoryId
      );
      list = list.filter((x) => selected?.careTypes.find((y) => x.id === y));
    }

    return (
      <Input
        name="careTypeId"
        label={t("assistance.filter.careType")}
        component="select"
      >
        <option value={0}>{t("assistance.filter.all")}</option>
        {list.map((x) => (
          <option key={x.id} value={x.id}>
            {x.name}
          </option>
        ))}
      </Input>
    );
  }, [
    values.categoryId,
    assistanceAddressFiltersState,
    assistanceAddressFilters,
    t,
  ]);

  const targetGroupInput = useMemo(() => {
    if (assistanceAddressFiltersState !== StoreState.Loaded) {
      return null;
    }

    return (
      <Input
        name="targetGroupId"
        label={t("assistance.filter.targetGroup")}
        component="select"
      >
        <option value={0}>{t("assistance.filter.all")}</option>
        {assistanceAddressFilters!.targetGroups.map((x) => (
          <option key={x.id} value={x.id}>
            {x.name}
          </option>
        ))}
      </Input>
    );
  }, [assistanceAddressFiltersState, assistanceAddressFilters, t]);

  if (assistanceAddressFiltersState === StoreState.Loading) {
    return <TailSpin color={COLORS.loaderColor} width={48} height={48} />;
  }

  return (
    <>
      {categoryInput}
      {careTypeInput}
      {targetGroupInput}
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceAddressFiltersState: selectAssistanceAddressFiltersState(state),
    assistanceAddressFilters: selectAssistanceAddressFilters(state),
  };
};

export default connect(mapStateToProps)(AssistanceAddressFilterExtended);
