import { isAfter } from "date-fns";

const TOKEN_KEY = "token";
const IDLE_MINUTES_KEY = "idleMinutes";
const TOKEN_REFRESH_TIMEOUT_KEY = "tokenRefreshTimeout";
const TOKEN_TIMEOUT_KEY = "tokenTimeout";

export const logout = () => {
  localStorage.clear();
};

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getIdleMinutes = (): number | null => {
  const timeout = localStorage.getItem(IDLE_MINUTES_KEY);
  return timeout ? parseInt(timeout) : null;
};

export const setIdleMinutes = (timeout: number) => {
  localStorage.setItem(IDLE_MINUTES_KEY, timeout.toString());
};

export const getTokenRefreshTimeout = (): Date | null => {
  const timeout = localStorage.getItem(TOKEN_REFRESH_TIMEOUT_KEY);
  return timeout ? new Date(timeout) : null;
};

export const setTokenRefreshTimeout = (timeout: Date) => {
  localStorage.setItem(TOKEN_REFRESH_TIMEOUT_KEY, timeout.toISOString());
};

export const getTokenTimeout = (): Date | null => {
  const timeout = localStorage.getItem(TOKEN_TIMEOUT_KEY);
  return timeout ? new Date(timeout) : null;
};

export const setTokenTimeout = (timeout: Date) => {
  localStorage.setItem(TOKEN_TIMEOUT_KEY, timeout.toISOString());
};

export const getTokenIsValid = (): boolean => {
  const tokenRefreshTimeout = getTokenRefreshTimeout();
  const tokenTimeout = getTokenTimeout();
  const token = getToken();

  let res = false;
  if (token && tokenRefreshTimeout && tokenTimeout) {
    // logout if token expired
    res = !isAfter(new Date(), tokenTimeout);
  }

  return res;
};

const storageApi = {
  setToken,
  getToken,
  getIdleMinutes,
  setIdleMinutes,
  getTokenRefreshTimeout,
  setTokenRefreshTimeout,
  getTokenTimeout,
  setTokenTimeout,
  getTokenIsValid,
};

export default storageApi;
